// forms/html_element_helpers/error_popover.js
export const generate_random_error_popover_id = () =>
    `error-popover-${Math.random().toString(36).substring(2, 11)}`;

/**
 * Creates an error popover container to hold error notifications.
 * @param {HTMLElement} parent - The parent element to append the container to.
 * @returns {HTMLElement} The error popover container element.
 */
export const createErrorPopoverContainer = (parent = document.body) => {
    // Change to use parent.querySelector for Shadow DOM compatibility
    let container = parent.querySelector('.data-hub-error-popover-container');
    if (!container) {
        container = document.createElement('div');
        container.className = 'data-hub-error-popover-container data_hub_error_popover_zindex fixed top-4 right-4 flex flex-col items-end space-y-3 p-4';
        container.style.pointerEvents = 'none'; // Allow clicks to pass through container but not children
        parent.appendChild(container);
    }
    return container;
};

/**
 * Close an error popover.
 * @param {Event} event - The event that triggered the close.
 * @param {HTMLElement} this_element - The element to close.
 */
export const close_error_popover = (event = null, this_element = null) => {
    if (event) {
        event.preventDefault();
        // Find the closest error popover if the event target is a child element
        if (!this_element) {
            this_element = event.target.closest('.data_hub_error_popover');
        }
    }
    
    if (this_element) {

        // Add fade-out animation
        this_element.style.opacity = '0';
        this_element.style.transform = 'translateX(20px)';
        
        // Remove after animation completes
        setTimeout(() => {
            this_element.remove();
        }, 300);
    } else {
        // Find the nearest element with the error_popover class
        const error_popover = event.target.closest('.data_hub_error_popover');
        if (error_popover) {
            close_error_popover(null, error_popover);
        }

    }
};

export const find_or_create_error_popover_container = (
    error_container_selector = '.data-hub-error-popover-container',
    parent_container = document
) => {
    let error_popover_container = null;
    try {
        // Use parent_container for query within Shadow DOM / custom root
        if (error_container_selector.startsWith('.')) {
            error_popover_container = parent_container.querySelector(error_container_selector);
        }
        else if (error_container_selector.startsWith('#')) {
            // getElementById might not be available on ShadowRoot; fallback to querySelector
            if (parent_container.getElementById) {
                error_popover_container = parent_container.getElementById(error_container_selector.slice(1));
            } else {
                error_popover_container = parent_container.querySelector(error_container_selector);
            }
        }
        else if (error_container_selector instanceof HTMLElement) {
            error_popover_container = error_container_selector;
        }
        else if (error_container_selector.match(/^[a-z]+$/i)) {
            error_popover_container = parent_container.getElementsByTagName(error_container_selector)[0];
        }
    } catch (error) {
        console.error('Error finding return error container:', error);
    }

    // If error_popover_container is null create a new one
    if (!error_popover_container) {
        error_popover_container = createErrorPopoverContainer(parent_container);
    }

    return error_popover_container;
};

/**
 * Trigger a Toast-like error notification
 * @param {String} external_error_message to display to the user
 * @param {HTMLElement} parent_container of the target element to append the error popover
 * @param {String, HTMLElement, tagName} error_container_selector to find or create the error container defaults to '.data-hub-error-popover-container'
 * @returns {HTMLElement} error_popover
 */
export const show_error_popover = (
    external_error_message = "Something went wrong please try again later.",
    parent_container = document,
    error_container_selector = '.data-hub-error-popover-container'
) => {

    if (!external_error_message || external_error_message === '' || typeof external_error_message !== 'string') {
        return;
    }

    // Ensure the container exists
    const container = find_or_create_error_popover_container(error_container_selector, parent_container);

    // If no container still return an error
    if (!container || !(container instanceof HTMLElement)) {
        console.error('Error finding or creating error container:', error_container_selector);
        return alert(external_error_message || 'An error occurred.');
    }
    
    // Generate a unique ID for the new error popover
    const error_popover = generate_error_popover_div({
        parent_container: container,
        external_error_message: external_error_message,
        id: generate_random_error_popover_id()
    });
    
    return error_popover;
};

/**
 * Generate an error popover div
 * @param {HTMLElement} parent_container - The parent container to append the error popover to.
 * @param {String} id - The unique ID for the error popover.
 * @returns {HTMLElement} error_popover
 */
export const generate_error_popover_div = ({
    parent_container = null,
    external_error_message = "Something went wrong please try again later.",
    id = generate_random_error_popover_id()
} = {}) => {

    // Create the container if it doesn't exist
    if (!parent_container) {
        parent_container = find_or_create_error_popover_container();
    }

    const error_popover_div = document.createElement('div');
    error_popover_div.innerHTML = `
  <div id="${id}" class="data_hub_error_popover data_hub_error_popover_zindex mb-3 transition-all duration-300 ease-in-out"
       style="pointer-events: auto;">
    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-[300px] flex items-start"
         role="alert">
      <div class="flex-1">
        <p class="font-bold text-sm mb-1">Error!</p>
        <p class="text-sm data_hub_error_message">${external_error_message}</p>
      </div>
      <span class="cursor-pointer" aria-label="Close error popover">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke-width="1.5" stroke="currentColor" class="fill-current h-6 w-6 text-red-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </span>
    </div>
  </div>
  `;

    // Initial state for animation
    error_popover_div.style.opacity = '0';
    error_popover_div.style.transform = 'translateX(-20px)';
    error_popover_div.style.transition = 'opacity 300ms ease-in-out, transform 300ms ease-in-out';
    
    parent_container.appendChild(error_popover_div);

    // Attach click event to close the popover
    error_popover_div.addEventListener('click', (event) => close_error_popover(event, error_popover_div));

    // Animate in
    setTimeout(() => {
        error_popover_div.style.opacity = '1';
        error_popover_div.style.transform = 'translateX(0)';
    }, 10);

    // Auto-close after timeout; use parent_container.querySelector to check existence
    setTimeout(() => {
        close_error_popover(null, error_popover_div);
    }, 5000);

    return error_popover_div;
};
