// src/forms/form_functions.js
import { prefill_form } from '../shared_form_ux_functions';

import {
  actblue_trigger_donation,
  generate_refcode_url_params_from_refcodes,
  get_cached_actblue_token,
  create_url_params_for_actblue_from_user_data
} from '../../helpers/actblue_integration';

import {
  processHandlebarsTemplate,
  parse_base64_json
} from '../../helpers/index';

import {
  validate_iterable_action_object
} from '../../helpers/iterable_helpers';

import {
  render_custom_thank_you_html,
  create_html_amount_buttons
} from '../../forms/html_element_helpers/form_generators';

/**
 * Callback executed after initialization.
 * @param {Object} data_hub_instance - The data_hub instance.
 */
function callback_function_after_init(data_hub_instance) {
  // No operation callback
  return;
}

/**
 * Adds ActBlue buttons into the provided container.
 *
 * @param {HTMLElement} success_div_container - The container to append buttons.
 * @param {Object} [refcodes=window?.data_hub?.refcodes || {}] - Refcodes to include.
 * @param {Object} [user_data=window?.data_hub?.user_data || {}] - Donor's user data.
 * @param {string|null} [actblue_token=get_cached_actblue_token()] - The ActBlue token.
 * @param {string|Object} [success_settings=[5,10,20,50,100]] - Settings for button amounts.
 * @param {Function} [error_function=window?.data_hub?.error_function] - Error callback.
 */
const add_actblue_buttons = (
  success_div_container,
  refcodes = window?.data_hub?.refcodes || {},
  user_data = window?.data_hub?.user_data || {},
  actblue_token = get_cached_actblue_token(),
  success_settings = [5,10,20,50,100],
  error_function = window?.data_hub?.error_function || ((internal_message, external_message) => {
    console.error(internal_message, external_message);
  })
) => {
  // Generate buttons via create_html_amount_buttons
  let actblue_buttons_dom_element = create_html_amount_buttons({
    amounts: success_settings, // Use provided or default amounts
    refcodes: refcodes,
    donor_data: user_data,
    actblue_token: actblue_token,
    target_container: success_div_container,
    actblue_page_url: null,
  });
  if (!actblue_buttons_dom_element) {
    return error_function('Error generating ActBlue buttons', null);
  }
  success_div_container.appendChild(actblue_buttons_dom_element);
};

/**
 * Generates the action body (i.e. form settings object) using named parameters.
 *
 * This object defines API events, metadata merging, and the return callback.
 *
 * @param {Object} params - An object containing named parameters.
 * @param {HTMLElement} params.element_context - The custom element instance (usually "this").
 * @param {Object} params.context - Context data (submit_button, originalHeight, etc.).
 * @param {Object} [params.this_data_hub_instance=window?.data_hub] - Global data_hub instance.
 * @param {HTMLElement} params.most_parent_div - The main form container.
 * @param {HTMLElement} params.first_form_container - The container holding the form.
 * @param {HTMLElement} params.success_div_container - The container for success content.
 * @param {Function} [params.success_js_function=()=>{}] - Custom success JS callback.
 * @param {Function} [params.error_function=window?.data_hub?.error_function] - Error reporting callback.
 * @param {Object} [params.action_object_data={ source: 'data_hub_form' }] - Additional action object data.
 * @param {Object} [params.merged_metadata=window?.data_hub?.page_metadata || {}] - Merged global metadata.
 * @param {Object} [params.form_metadata={}] - Form-specific metadata.
 *
 * @returns {Object} The form settings object.
 */
const generate_action_body = ({
  element_context,
  context,
  this_data_hub_instance = window?.data_hub,
  most_parent_div,
  first_form_container,
  success_div_container,
  success_js_function = () => {},
  error_function = window?.data_hub?.error_function ||
    ((internal_message, external_message) => { console.error(internal_message, external_message); }),
  action_object_data = { source: "data_hub_form" },
  merged_metadata = window?.data_hub?.page_metadata || {},
  form_metadata = {}
}) => {
  if (!context) {
    error_function('Error in generate_action_body: context is required', null);
    return {};
  }

  try {
    // Define the form settings object to be used in form initialization.
    let form_settings = {
      form_container: most_parent_div,
      submit_button: context.submit_button,
      allow_prefill: context.allow_prefill,
      return_function: (form_container, response_data, data_hub_instance) => {
        this_return_function({
          first_form_container,
          success_div_container,
          originalHeight: context.originalHeight,
          success_settings: context.success_settings,
          actblue_donation_amount: context.actblue_donation_amount,
          refcodes: context.refcodes,
          actblue_token: context.actblue_token,
          form_container,
          response_data,
          data_hub_instance,
          merged_metadata,
          success_js_function,
          error_function
        });
      },
      form_metadata: form_metadata,
      returns: { user_data: '{{user_data}}' },
      events: [
        // 250325 TODO test this shuold be auto done via person_format dont dupe it here
        // {
        //   verb: 'i_api',
        //   data: {
        //     api_method: 'POST',
        //     api_endpoint: 'users/update',
        //     i_api_json: {
        //       email: '{{user_data.email}}',
        //       dataFields: '{{user_data}}'
        //     }
        //   }
        // },
        {
          verb: 'i_api',
          data: {
            api_method: 'POST',
            api_endpoint: 'events/track',
            i_api_json: {
              email: '{{user_data.email}}',
              eventName: 'action',
              dataFields: action_object_data,
              campaignId: this_data_hub_instance.iterableCampaignId || null,
              templateId: this_data_hub_instance.iterableTemplateId || null
            }
          }
        },
        {
          verb: 'data_hub_events',
          data: {
            event_name: 'action',
            source: 'data_hub_form',
            custom_data: action_object_data
          }
        }
      ]
    };

    // Process Iterable subscription parameters
    const iterable_subscription_action_object = process_iterable_user_list_message_types({
      subscribeListIds: context.iterable_subscribe_list_ids,
      unsubscribeListIds: context.iterable_unsubscribe_list_ids,
      unsubscribeMessageTypes: context.iterable_unsubscribe_message_types,
      subscribeMessageTypes: context.iterable_subscribe_message_types,
      unsubscribeChannelIds: context.iterable_unsubscribe_channel_ids,
      data_hub_instance: this_data_hub_instance
    });

    if (iterable_subscription_action_object && Array.isArray(iterable_subscription_action_object) && iterable_subscription_action_object.length > 0) {
      form_settings.events = form_settings.events.concat(iterable_subscription_action_object);
    }

    // Process additional action objects passed via the element's dataset.
    if (element_context.dataset.hubActionObjects) {
      const actionObjects = parse_base64_json(element_context.dataset.hubActionObjects);
      if (Array.isArray(actionObjects) && actionObjects.length > 0) {
        actionObjects.forEach(actionObject => {
          if (validate_iterable_action_object(actionObject)) {
            form_settings.events.push(actionObject);
          } else {
            error_function('Invalid action object: ' + JSON.stringify(actionObject), null);
          }
        });
      } else {
        if (validate_iterable_action_object(actionObjects)) {
          form_settings.events.push(actionObjects);
        } else {
          error_function('Error: data-hub-action-objects must be an array of action objects', null);
        }
      }
    }
    return form_settings;
  } catch (e) {
    error_function('Error in generate_action_body: ' + e, null);
    return {};
  }
};

/**
 * Processes Iterable subscription parameters and returns an array of action objects.
 *
 * @param {Object} params - Named parameters.
 * @param {Array} params.subscribeListIds - List IDs to subscribe.
 * @param {Array} params.unsubscribeListIds - List IDs to unsubscribe.
 * @param {Array} params.unsubscribeMessageTypes - Message type IDs to unsubscribe.
 * @param {Array} params.subscribeMessageTypes - Message type IDs to subscribe.
 * @param {Array} params.unsubscribeChannelIds - Channel IDs to unsubscribe.
 * @param {Object} params.data_hub_instance - The data_hub instance.
 *
 * @returns {Array<Object>} Array of action objects.
 */
function process_iterable_user_list_message_types({
  subscribeListIds,
  unsubscribeListIds,
  unsubscribeMessageTypes,
  subscribeMessageTypes,
  unsubscribeChannelIds,
  data_hub_instance
}) {
  let finalArray = [];

  if (subscribeListIds && subscribeListIds.length > 0) {
    subscribeListIds.forEach(singleListId => {
      finalArray.push({
        verb: 'i_api',
        data: {
          api_method: 'POST',
          api_endpoint: 'lists/subscribe',
          i_api_json: {
            listId: singleListId,
            subscribers: [
              {
                email: "{{user_data.email}}",
                mergeNestedObjects: true
              }
            ],
            updateExistingUsersOnly: false
          }
        }
      });
    });
  }

  if (unsubscribeListIds && unsubscribeListIds.length > 0) {
    unsubscribeListIds.forEach(singleListId => {
      finalArray.push({
        verb: 'i_api',
        data: {
          api_method: 'POST',
          api_endpoint: 'lists/unsubscribe',
          i_api_json: {
            listId: singleListId,
            campaignId: data_hub_instance.iterableCampaignId || null,
            subscribers: [
              { email: "{{user_data.email}}" }
            ],
            channelUnsubscribe: false
          }
        }
      });
    });
  }

  let atLeastOneMessageChannelType = false;
  let messageTypeBody = {
    email: "{{user_data.email}}",
    subscribedMessageTypeIds: [],
    unsubscribedChannelIds: [],
    unsubscribedMessageTypeIds: [],
    campaignId: data_hub_instance.iterableCampaignId || null,
    templateId: data_hub_instance.iterableTemplateId || null
  };
  if (unsubscribeMessageTypes && unsubscribeMessageTypes.length > 0) {
    atLeastOneMessageChannelType = true;
    messageTypeBody.unsubscribedMessageTypeIds = unsubscribeMessageTypes;
  }
  if (subscribeMessageTypes && subscribeMessageTypes.length > 0) {
    atLeastOneMessageChannelType = true;
    messageTypeBody.subscribedMessageTypeIds = subscribeMessageTypes;
  }
  if (unsubscribeChannelIds && unsubscribeChannelIds.length > 0) {
    atLeastOneMessageChannelType = true;
    messageTypeBody.unsubscribedChannelIds = unsubscribeChannelIds;
  }
  if (atLeastOneMessageChannelType) {
    console.warn("Channel and message type subscriptions overwrite existing values. Backend *should* merge with existing data.");
    finalArray.push({
      verb: 'i_api',
      data: {
        api_method: 'POST',
        api_endpoint: 'users/updateSubscriptions',
        i_api_json: messageTypeBody
      }
    });
  }
  return finalArray;
}

/**
 * Handles post-submit behavior based on success_settings.
 *
 * @param {Object} params - Named parameters.
 * @param {HTMLElement} params.first_form_container - The container of the form.
 * @param {HTMLElement} params.success_div_container - The container for success messages.
 * @param {number} params.originalHeight - Original height for resetting layout.
 * @param {string|Object} params.success_settings - Determines which success method to use.
 * @param {number|null} params.actblue_donation_amount - Donation amount if applicable.
 * @param {Object} params.refcodes - Refcodes object.
 * @param {string|null} params.actblue_token - ActBlue token.
 * @param {HTMLElement} params.form_container - The current form container.
 * @param {Object} params.response_data - Data returned from the API.
 * @param {Object} params.data_hub_instance - The data_hub instance.
 * @param {Object} params.merged_metadata - Merged metadata (page + form).
 * @param {Function} params.success_js_function - Custom success callback.
 * @param {Function} params.error_function - Error callback.
 */
const this_return_function = ({
  first_form_container,
  success_div_container,
  originalHeight,
  success_settings = 'actblue-popup',
  actblue_donation_amount = null,
  refcodes = window?.data_hub?.page_metadata?.refcodes || {},
  actblue_token = get_cached_actblue_token(),
  form_container,
  response_data,
  data_hub_instance = window?.data_hub,
  merged_metadata = window?.data_hub?.page_metadata || {},
  success_js_function = () => {},
  error_function = window?.data_hub?.error_function || ((msg, ext) => { console.error(msg, ext); })
}) => {
  // Execute custom success function, if provided.
  if (success_js_function) {
    (async () => {
      try {
        await success_js_function(response_data || {});
      } catch (e) {
        error_function('user provided success_js_function failed: ' + e, null);
      }
    })();
  }

  if (originalHeight) {
    success_div_container.style.minHeight = `${originalHeight}px`;
  }

  const success_method = what_type_of_success_method(success_settings, error_function);
  let user_data = (response_data?.returns?.user_data && Object.keys(response_data.returns.user_data).length > 0)
    ? response_data.returns.user_data
    : data_hub_instance.user_data || window?.data_hub?.user_data || {};

  // Handle success methods
  if (success_method.startsWith('actblue-popup')) {
    let amount = 10;
    if (success_method.includes('[')) {
      const match = success_method.match(/\[(\d+)\]/);
      if (match && match[1]) {
        try {
          amount = parseInt(match[1], 10);
        } catch (e) {
          error_function('Error parsing amount from success_settings: ' + e, null);
          amount = 10;
        }
      } else {
        amount = actblue_donation_amount || 1000;
      }
    } else {
      amount = actblue_donation_amount || 1000;
    }
    actblue_trigger_donation({
      amount: amount * 100,
      target_container_for_on_complete: success_div_container,
      actblue_token: actblue_token || get_cached_actblue_token(),
      error_function,
      data_hub_instance
    });
  } else if (success_method === 'actblue-buttons') {
    add_actblue_buttons(
      success_div_container,
      refcodes || data_hub_instance?.page_metadata?.refcodes || {},
      user_data,
      actblue_token || get_cached_actblue_token(),
      success_settings,
      error_function
    );
  } else if (success_method === 'redirect') {
    const processedUrl = processHandlebarsTemplate(success_settings, user_data);
    try {
      const urlObj = new URL(processedUrl, window.location.origin);
      window.top.location.href = urlObj.toString();
    } catch (error) {
      error_function('Invalid URL after processing template: ' + processedUrl, error);
    }
  } else if (success_method === 'actblue-redirect-url') {
    const actblue_default_page_url = data_hub_instance?.actblue_default_page_url || window?.data_hub?.actblue_default_page_url || null;
    const the_refcodes = data_hub_instance?.page_metadata?.refcodes || {};
    let refcode_string = generate_refcode_url_params_from_refcodes(the_refcodes);
    let user_params = create_url_params_for_actblue_from_user_data(user_data, error_function);

    let final_actblue_url_with_params = actblue_default_page_url + '?';
    if (refcode_string) {
      final_actblue_url_with_params += refcode_string + '&';
    }
    if (user_params) {
      final_actblue_url_with_params += user_params;
    }
    window.top.location.href = final_actblue_url_with_params;
  } else if (success_method === 'html') {
    render_custom_thank_you_html(
      success_settings,
      success_div_container,
      user_data,
      merged_metadata,
      error_function
    );
  } else {
    error_function('Unknown success_method: ' + success_method, null);
    add_actblue_buttons(
      success_div_container,
      refcodes || data_hub_instance?.page_metadata?.refcodes || {},
      user_data,
      actblue_token || get_cached_actblue_token(),
      success_settings,
      error_function
    );
  }

  first_form_container.classList.add('hidden');
  success_div_container.classList.remove('hidden');
};

/**
 * Determines the success method based on the success_settings string.
 *
 * @param {string|Object} success_settings - The success settings provided.
 * @param {Function} error_function - Callback for error reporting.
 * @returns {string} A string indicating the success method.
 */
const what_type_of_success_method = (success_settings, error_function = window?.data_hub?.error_function || ((msg, ext) => { console.error(msg, ext); })) => {
  if (success_settings) {
    if (typeof success_settings !== 'string') {
      return 'actblue-buttons';
    } else if (success_settings.startsWith('actblue-popup')) {
      return success_settings;
    } else if (typeof success_settings === 'string') {
      if (success_settings.startsWith('[')) {
        try {
          const amounts = JSON.parse(success_settings);
          if (Array.isArray(amounts) && amounts.length > 0 && amounts.every(val => typeof val === 'number')) {
            return 'actblue-buttons';
          }
        } catch (e) {
          error_function('Error parsing success_settings as JSON: ' + e, null);
        }
        return 'actblue-popup';
      } else if (success_settings === 'actblue-redirect-url') {
        return 'actblue-redirect-url';
      } else if (success_settings.startsWith('http')) {
        return 'redirect';
      } else if (success_settings.trim() !== '') {
        return 'html';
      }
    }
  }
  error_function('Unexpected success_settings; defaulting to actblue-popup. Received: ' + success_settings, null);
  return 'actblue-popup';
};

/**
 * Re-prefills the element's form fields based on user data.
 *
 * @param {Event} event - The event containing user data details.
 * @param {ShadowRoot} shadowRoot - The shadow DOM root for prefill.
 * @param {string} reset_button_selector - CSS selector for reset buttons.
 */
const re_prefill_element_form = (event, shadowRoot, reset_button_selector) => {
  const { user_data, prefill_input_selector, data_hub_instance } = event.detail;
  prefill_form(
    shadowRoot,
    user_data,
    prefill_input_selector,
    reset_button_selector,
    undefined,
    true,
    data_hub_instance
  );
};

/**
 * Observes a target element's size and toggles an 'is-mobile' class based on a target width.
 *
 * @param {HTMLElement} target - The element to observe.
 * @param {Set} observers - A set in which the created ResizeObserver is stored.
 * @param {number} [targetWidth=600] - The breakpoint width in pixels.
 */
const watch_container_size = (target, observers, targetWidth = 600) => {
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize) || 16;
  const targetPixels = Math.round((targetWidth / 16) * rootFontSize);
  let isMobile = false;
  const observer = new ResizeObserver(entries => {
    window.requestAnimationFrame(() => {
      for (let entry of entries) {
        // Handle both array and single object cases for contentBoxSize.
        const inlineSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]?.inlineSize || entry.contentBoxSize.inlineSize
          : entry.contentBoxSize.inlineSize;
        const newIsMobile = inlineSize < targetPixels;
        if (newIsMobile !== isMobile) {
          isMobile = newIsMobile;
          target.classList.toggle('is-mobile', isMobile);
        }
      }
    });
  });
  observer.observe(target);
  observers.add(observer);
};

export {
  watch_container_size,
  what_type_of_success_method,
  re_prefill_element_form,
  callback_function_after_init,
  generate_action_body,
  add_actblue_buttons
};