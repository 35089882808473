// src/forms/data_hub_html_element.js
import { default_theme } from '../helpers/static_vars';
import {
    create_form_elements_and_inputs,
    inject_custom_css
} from './html_element_helpers/form_generators';
import {
    watch_container_size,
    generate_action_body
} from './html_element_helpers/form_functions';
import { bind_all_tel_inputs_intl, set_listener_for_new_user_data } from '../tel/telephone_sms_inputs';
import {
    inject_tailwind_css,
    inject_html_element_css,
    assign_theming_variables,
    update_original_height,
    get_tailwind_size_class,
    addBorderClassesToContainer
} from './html_element_helpers/form_styling';
import {
    show_error_popover
} from './html_element_helpers/error_popover';
import { normalize_refcodes_from_object, getDefaultAbAmountsString } from '../helpers/actblue_integration';
import {
    inject_styles,
    get_most_parent_url_shadow_dom_safe,
    parse_custom_html,
    string_array_of_ints_to_array,
    processHandlebarsTemplate,
    parse_b64_or_array_like_data_attribute,
    normalize_metadata_from_object,
    remove_empty_values_from_object
} from '../helpers';
import defaultsDeep from 'lodash.defaultsdeep';
import { extract_array_values_from_url_parameter } from '../helpers/parse_url_params';
import { v4 as uuidv4 } from 'uuid';
import merge from 'lodash.merge';

class DataHubSignupForm extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.isInitialized = false; // Prevent double rendering
        this.observers = new Set();
        this.final_form_id = uuidv4();
    }

    async connectedCallback() {
        if (this.hasAttribute('data-hub-signup-form-initialized')) {
            return;
        }

        await this.initForm().catch(err => {
            console.error('Error initializing data-hub-signup-form:', err);
        })
        .finally(() => {
            this.originalHeight = update_original_height(this.shadowRoot);
        });
    }

    disconnectedCallback() {
        this.observers.forEach(observer => observer.disconnect());
    }

    async attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            console.log('Attribute changed:', name, oldValue, newValue);
            await this.renderForm();
            this.originalHeight = update_original_height(this.shadowRoot);
        }
    }

    async initForm() {

        this.isInitialized = true;
        
        // Set early so it sets listener before data_hub user_data returns
        set_listener_for_new_user_data();

        // If the global instance exists, use it.
        if (window.data_hub) {
            this.data_hub_instance = window.data_hub;
            await this.renderForm();
        } else if (window.DataHub && window.DataHub.data_hub_class) {
            let isLoading = false;
            try {
                isLoading = window.DataHub.data_hub_loading;
            } catch (error) {
                console.error('Debug 88 weird bug cant get loading val in initForm try block:', error);
            }
            // If a DataHub instance is currently loading...
            if (isLoading) {
                window.DataHub.wait_for_data_hub().then(async () => {
                    this.data_hub_instance = window.data_hub;
                    await this.renderForm();
                }).catch(err => {
                    console.error('Error waiting for DataHub:', err);
                });
            } else {
                // No instance exists and none is loading; trigger creation.
                window.DataHub.data_hub_loading = true;
                await this.initNewDataHub();
            }
        }
        return;
    }

    async initNewDataHub() {
        const initRefcodes = normalize_refcodes_from_object(
            parse_b64_or_array_like_data_attribute(this.dataset.hubRefcodes || this.dataset.hubActblueRefcodes) || {}
        ) || {};
        const initPageMetadata = normalize_metadata_from_object(
            parse_b64_or_array_like_data_attribute(this.dataset.hubPageMetadata || this.dataset.hubMetadata) || {}
        ) || {};
        this.data_hub_instance = new window.DataHub.data_hub_class(
            {
                error_function: this.handleError.bind(this),
                actblue_token: this.dataset.abToken || null,
                refcodes: initRefcodes,
                page_metadata: initPageMetadata,
            },
            async (instance) => {
                window.data_hub = instance;
                DataHub.data_hub_loading = false;
                this.data_hub_instance = instance;
                await this.renderForm();
            }
        );
    }


    async handleError(internalMsg = null, externalMsg = 'Error processing form data.') {
        show_error_popover(externalMsg, this.shadowRoot);
        console.error(internalMsg || externalMsg);
        const errorEvent = new CustomEvent('data-hub-error', {
            detail: { internal_error_message: internalMsg, external_error_message: externalMsg },
            bubbles: true,
            composed: true
        });
        this.dispatchEvent(errorEvent);
    }

    async renderForm() {

        // Set as rendered
        this.setAttribute('data-hub-signup-form-initialized', 'true');

        // Use provided form ID or keep the generated one.
        this.final_form_id = this.dataset.hubFormId || this.final_form_id;

        // Determine actblue token.
        this.actblue_token = this.dataset.abToken || this.data_hub_instance?.actblue_token || null;

        // Shared page_metadata.
        this.page_metadata = normalize_metadata_from_object(parse_b64_or_array_like_data_attribute(this.dataset.hubPageMetadata) || {}) || {};

        // Form specific metadata.
        this.form_metadata = normalize_metadata_from_object(
            parse_b64_or_array_like_data_attribute(this.dataset.hubFormMetadata || this.dataset.hubMetadata) || {}
        ) || {};
        this.form_metadata.form_id = this.final_form_id;

        // Parse actblue refcodes.
        this.refcodes = normalize_refcodes_from_object(
            parse_b64_or_array_like_data_attribute(this.dataset.hubRefcodes || this.dataset.hubActblueRefcodes) || {}
        ) || {};

        if (!this.data_hub_instance?.page_metadata) {
            this.data_hub_instance.page_metadata = {};
        }

        // If has this.page_metadata, defaultDeep merge it into data_hub_instance.page_metadata
        if (this.page_metadata) {
            if (typeof this.data_hub_instance.page_metadata !== 'object') {
                this.data_hub_instance.page_metadata = {};
            }
            this.data_hub_instance.page_metadata = defaultsDeep(
                {},
                this.data_hub_instance.page_metadata,
                this.page_metadata
            );
        }

        // If has refcodes, add any new ones into data_hub_instance.page_metadata.refcodes
        if (this.refcodes) {
            if (typeof this.data_hub_instance.page_metadata.refcodes === 'object') {
                this.data_hub_instance.page_metadata.refcodes = defaultsDeep(
                    this.data_hub_instance.page_metadata.refcodes,
                    this.refcodes
                );
            } else {
                this.data_hub_instance.page_metadata.refcodes = this.refcodes;
            }
        }

        // Set up the error function.
        this.error_function = this.handleError.bind(this);

        // Setup on success callback based on data-hub-return-function-name
        const returnFunctionName = this.dataset.hubReturnFunctionName;
        // Default return function that just logs the returns
        this.success_js_function = (returns) => {};

        // If a function name was provided, try to get the actual function
        if (returnFunctionName && typeof returnFunctionName === 'string') {
            if (typeof window[returnFunctionName] === 'function') {
                // Get the function from window object
                const userFunction = window[returnFunctionName];
                
                // Check if the function has the expected number of parameters
                if (userFunction.length < 1) {
                    console.warn(`Warning: ${returnFunctionName} has fewer than 1 parameters. Expected: (returns)`);
                }
                
                // Assign the function, ensuring it's bound to the window context
                this.success_js_function = function(returns) {
                    return userFunction.call(window, returns);
                };
            } else {
                this.error_function(`Error: passed return function name "${returnFunctionName}" not found on window object.`);
            }
        }

        // Build the Handlebars context.
        const current_user_data_context = defaultsDeep(
            this.data_hub_instance?.user_data || {},
            window?.data_hub?.user_data || {},
            window?.data_hub_user_data || {},
            {}
        );

        this.handlebars_context = {
            user_data: current_user_data_context,
            metadata: merge({}, this.page_metadata, this.form_metadata)
        };

        // Process template strings.
        this.form_title = processHandlebarsTemplate(this.dataset.hubFormTitle, this.handlebars_context) || null;
        this.form_description = processHandlebarsTemplate(this.dataset.hubFormDescription, this.handlebars_context) || null;
        this.image_url = processHandlebarsTemplate(this.dataset.hubImageUrl, this.handlebars_context) || null;
        this.form_submit_button_text = processHandlebarsTemplate(this.dataset.hubFormSubmitButtonText, this.handlebars_context) || 'Sign up';
        this.user_fields = this.dataset.hubFormUserFields || 'email:3/4,submit:1/4';
        this.form_border = this.dataset.hubFormBorder || 'true';

        // Process custom HTML.
        this.custom_html = null;
        const passed_custom_html = this.dataset.hubFormCustomHtml || this.dataset.hubCustomFormHtml;
        if (passed_custom_html && passed_custom_html !== 'undefined') {
            this.custom_html = processHandlebarsTemplate(parse_custom_html(passed_custom_html), this.handlebars_context);
        }

        this.success_settings = this.dataset.hubOnSuccessShow || getDefaultAbAmountsString();
        this.actblue_donation_amount = isNaN(this.dataset.hubActblueDonationAmount) ? null : this.dataset.hubActblueDonationAmount;

        // Process Iterable IDs.
        this.iterable_subscribe_list_ids = string_array_of_ints_to_array(
            extract_array_values_from_url_parameter(this.dataset.hubIterableSubscribeListIds || []),
            true
        );
        this.iterable_unsubscribe_list_ids = string_array_of_ints_to_array(
            extract_array_values_from_url_parameter(this.dataset.hubIterableUnsubscribeListIds || []),
            true
        );
        this.iterable_unsubscribe_message_types = string_array_of_ints_to_array(
            extract_array_values_from_url_parameter(this.dataset.hubIterableUnsubscribeMessageTypes || []),
            true
        );
        this.iterable_subscribe_message_types = string_array_of_ints_to_array(
            extract_array_values_from_url_parameter(this.dataset.hubIterableSubscribeMessageTypes || []),
            true
        );
        this.iterable_unsubscribe_channel_ids = string_array_of_ints_to_array(
            extract_array_values_from_url_parameter(this.dataset.hubIterableUnsubscribeChannelIds || []),
            true
        );

        this.allow_prefill = this.dataset.hubFormAllowPrefill ? (this.dataset.hubFormAllowPrefill === 'true') : true;

        // Theming.
        this.default_theme = default_theme;
        assign_theming_variables(this);

        this.align_form_content = this.dataset.hubFormAlignContent || '';

        // Build the main container.
        const most_parent_div = document.createElement('div');
        most_parent_div.classList.add('data_hub_most_parent_container');
        inject_tailwind_css(most_parent_div);
        inject_html_element_css(most_parent_div);
        inject_styles(this.theme, most_parent_div);

        const data_hub_form_element_container = document.createElement('div');
        data_hub_form_element_container.classList.add(
            'relative', 'data-hub-container', 'container',
            'max-w-full', 'mx-auto', 'mt-0', 'mb-0', 'p-4',
            'transition-all', 'duration-100', 'ease-in-out'
        );

        addBorderClassesToContainer(this.form_border, most_parent_div, this.error_function);

        data_hub_form_element_container.id = this.final_form_id;

        let primary_form_settings = {
            data_hub_form_element_container,
            form_title: this.form_title,
            form_description: this.form_description,
            user_fields: this.user_fields,
            allow_prefill: this.allow_prefill,
            form_submit_button_text: this.form_submit_button_text,
            image_url: this.image_url,
            success_settings: this.success_settings,
            actblue_donation_amount: this.actblue_donation_amount,
            refcodes: this.refcodes,
            custom_form_html: this.custom_html,
            page_metadata: this.page_metadata,
            form_metadata: this.form_metadata,
            target: this.shadowRoot,
            data_hub_instance: this.data_hub_instance,
        };

        if (this.align_form_content.includes(':')) {
            const [side, fraction] = this.align_form_content.split(':').map(s => s.trim());
            const textColumnWidthClass = get_tailwind_size_class(fraction);
            const [num, denom] = fraction.split('/').map(Number);
            const remainingFraction = `${denom - num}/${denom}`;
            const formColumnWidthClass = get_tailwind_size_class(remainingFraction);
            primary_form_settings.alignSettings = { side, textColumnWidthClass, formColumnWidthClass };
        }

        const { success_div_container, first_form_container, form, submit_button } =
            create_form_elements_and_inputs(primary_form_settings);
        this.submit_button = submit_button;

        data_hub_form_element_container.appendChild(success_div_container);
        data_hub_form_element_container.appendChild(first_form_container);
        most_parent_div.appendChild(data_hub_form_element_container);

        // The body that will go into iterable custom event
        let action_object_data = {
            "source": "data_hub_form",
            "form_id": this.final_form_id,
            "form_title": this.form_title,
            "form_description": this.form_description,
            "form_image_url": this.image_url,
            "success_settings": this.success_settings,
            "actblue_donation_amount": this.actblue_donation_amount,
            "metadata": "{{metadata}}",
            "page_url": get_most_parent_url_shadow_dom_safe() || null,
            "iterableCampaignId": this.data_hub_instance?.iterableCampaignId || null,
            "iterableTemplateId": this.data_hub_instance?.iterableTemplateId || null,
            "iterable_subscribe_list_ids": this.iterable_subscribe_list_ids || null,
            "iterable_unsubscribe_list_ids": this.iterable_unsubscribe_list_ids || null,
            "iterable_unsubscribe_message_types": this.iterable_unsubscribe_message_types || null,
            "iterable_subscribe_message_types": this.iterable_subscribe_message_types || null,
            "iterable_unsubscribe_channel_ids": this.iterable_unsubscribe_channel_ids || null,
        };

        // Remove empties
        action_object_data = remove_empty_values_from_object(action_object_data);

        const context = {
            iterable_subscribe_list_ids: this.iterable_subscribe_list_ids,
            iterable_unsubscribe_list_ids: this.iterable_unsubscribe_list_ids,
            iterable_unsubscribe_message_types: this.iterable_unsubscribe_message_types,
            iterable_unsubscribe_channel_ids: this.iterable_unsubscribe_channel_ids,
            iterable_subscribe_message_types: this.iterable_subscribe_message_types,
            submit_button: this.submit_button,
            allow_prefill: this.allow_prefill,
            originalHeight: this.originalHeight,
            success_settings: this.success_settings,
            actblue_donation_amount: this.actblue_donation_amount,
            refcodes: this.refcodes,
            actblue_token: this.actblue_token,
            dataset: this.dataset
        };

        const merged_metadata = merge({}, this.page_metadata, this.form_metadata);

        const event_settings = generate_action_body({
            element_context: this,
            context: context,
            this_data_hub_instance: this.data_hub_instance,
            most_parent_div: most_parent_div,
            first_form_container: first_form_container,
            success_div_container: success_div_container,
            success_js_function: this.success_js_function,
            error_function: this.error_function,
            action_object_data: action_object_data,
            merged_metadata: merged_metadata,
            form_metadata: this.form_metadata
        });


        if (!event_settings || Object.keys(event_settings).length === 0) {
            console.error('ERROR: event_settings is not loaded or is an empty object.');
            return;
        }

        // Initialize the DataHub form.
        const this_form_instance = new window.DataHub.data_hub_form({
            data_hub_instance: this.data_hub_instance,
            target: most_parent_div,
            error_function: this.error_function,
            return_function: this.return_function,
        });
        this_form_instance.init(event_settings);

        const loading_overlay = document.createElement('div');
        loading_overlay.classList.add(//'fixed', 'top-0', 'left-0', 
            'data-hub-loading-overlay', 'w-full', 'h-full',
            'bg-white', 'bg-opacity-50', 'z-50', 'hidden'
        );
        most_parent_div.appendChild(loading_overlay);

        this.shadowRoot.innerHTML = '';
        this.shadowRoot.appendChild(most_parent_div);

        // Init all telephone inputs. Await to init and inject css / bind to inputs
        this.iti_inputs = await bind_all_tel_inputs_intl(most_parent_div, this.data_hub_instance);

        if (this.dataset.hubCssUrl) {
            inject_custom_css(this.dataset.hubCssUrl, this.shadowRoot, this.error_function);
        }

        watch_container_size(most_parent_div, this.observers, 601);

    }
}

// Check if the element is already defined
if (!window.customElements.get('data-hub-signup-form')) {
    window.customElements.define('data-hub-signup-form', DataHubSignupForm);
    window.data_hub_signup_form = DataHubSignupForm;
}

// listen for data-hub-init event
// document.addEventListener('data-hub-init', () => {
//     // console.log('debug 427 is data hub init event');
//     //loopFindSignupFormCallConnectedCallback();
// });

// Wait for document DOM to be ready and call connectedCallback again
document.addEventListener('DOMContentLoaded', () => {
    loopFindSignupFormCallConnectedCallback();
});

function loopFindSignupFormCallConnectedCallback() {
    document.querySelectorAll('data-hub-signup-form').forEach(form => {
        if (typeof form.connectedCallback === 'function') {
            form.connectedCallback();
        } else {
            console.warn('Warning: connectedCallback is not a function on the form instance.');
        }
    });
}