// forms/html_elements_helpers/validation_ui_elements.js

import intlTelInput from "intl-tel-input/intlTelInputWithUtils";
import { phone_number_names, email_names } from "../../helpers/static_vars";
import { validateAgainstNewUserData } from "../../forms/html_element_helpers/user_data_validation";

/**
 * Generates a UI component to display phone number validation errors.
 * 
 * @param {Array[]} validationErrors - Array of phone errors, each is string definition like ['INVALID_NUMBER'].
 * @param {string} originalPhone - The original phone number.
 * @param {string} newPhone - The new phone number.
 * @param {string} phoneNumberKeyName - The key name of the phone number being validated.
 * @param {Document} form_container - The document to create elements with.
 * 
 * @returns {HTMLElement} phoneErrorContainer with phone error information.
 */
export const generatePhoneNumberValidationUI = (validationErrors, originalPhone, newPhone, phoneNumberKeyName, form_container = document) => {
    if (!validationErrors || validationErrors.length === 0) {
        return null;
    }

    // Ensure we're creating elements with the correct document context
    const doc = form_container.ownerDocument || form_container;

    const phoneErrorContainer = doc.createElement('div');
    phoneErrorContainer.classList.add('phone-error-container', 'flex', 'flex-col', 'items-start', 'justify-start', 
        'border', 'border-red-200', 'rounded-lg', 'bg-red-50', 'p-4', 'w-full', 'mb-4', 'shadow-sm', 'relative');

    // Add close button
    const closeButton = doc.createElement('button');
    closeButton.setAttribute('type', 'button');
    closeButton.setAttribute('aria-label', 'Close phone validation message');
    closeButton.classList.add(
        'absolute', 'top-2', 'right-2', 
        'text-gray-500', 'hover:text-gray-700', 'hover:bg-gray-100',
        'bg-white',
        'p-1.5', 'rounded-full', 'focus:outline-none', 'focus:ring-2', 'focus:ring-gray-300',
        'transition-colors', 'duration-200'
    );
    
    // Add X SVG icon
    closeButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
    `;

    // Add click handler to close button
    closeButton.addEventListener('click', () => {
        phoneErrorContainer.classList.add('fade-out');
        setTimeout(() => {
            if (phoneErrorContainer.parentNode) {
                phoneErrorContainer.parentNode.removeChild(phoneErrorContainer);
            }
        }, 300);
    });
    
    phoneErrorContainer.appendChild(closeButton);

    const errorHeader = doc.createElement('h3');
    errorHeader.classList.add('font-semibold', 'text-center', 'w-full', 'text-red-700', 'mb-3');
    errorHeader.textContent = `Phone Number Issue${validationErrors.length > 1 ? 's' : ''}`;
    phoneErrorContainer.appendChild(errorHeader);

    const errorsGridContainer = doc.createElement('div');
    errorsGridContainer.classList.add('grid', 'grid-cols-1', 'md:grid-cols-2', 'lg:grid-cols-3', 'gap-4', 'w-full');
    phoneErrorContainer.appendChild(errorsGridContainer);

    validationErrors.forEach(error => {
        const errorTextContainer = doc.createElement('div');
        errorTextContainer.classList.add('phone-error-item', 'text-red-600', 'text-sm', 'p-2');

        if (typeof error !== 'string') { error = 'Invalid Number'; }
        const errorInHumanReadable = error.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

        const errorText = doc.createElement('p');
        errorText.classList.add('mb-2', 'font-medium');
        errorText.textContent = errorInHumanReadable;
        errorTextContainer.appendChild(errorText);

        const compareNumbersContainer = doc.createElement('div');
        compareNumbersContainer.classList.add('mt-2', 'p-2', 'bg-white', 'rounded', 'border', 'border-red-100');

        // For phone numbers, showing before/after is usually the clearest approach
        const originalNumber = doc.createElement('p');
        originalNumber.classList.add('flex', 'items-center', 'gap-2', 'mb-1');
        originalNumber.innerHTML = `<span class="font-medium text-gray-600">${phoneNumberKeyName} (Original):</span> <span class='text-red-600'>${originalPhone}</span>`;
        compareNumbersContainer.appendChild(originalNumber);

        if (newPhone && originalPhone !== newPhone) {
            const newNumber = doc.createElement('p');
            newNumber.classList.add('flex', 'items-center', 'gap-2');
            newNumber.innerHTML = `<span class="font-medium text-gray-600">${phoneNumberKeyName} (Corrected):</span> <span class='text-emerald-600'>${newPhone}</span>`;
            compareNumbersContainer.appendChild(newNumber);
        }

        errorTextContainer.appendChild(compareNumbersContainer);
        errorsGridContainer.appendChild(errorTextContainer);
    });

    // Set auto-dismiss timer (10 seconds)
    const autoDismissTimer = setTimeout(() => {
        closeButton.click();
    }, 10000);
    
    // If user interacts with the container, cancel auto-dismiss
    phoneErrorContainer.addEventListener('mouseenter', () => {
        clearTimeout(autoDismissTimer);
    });
    
    return phoneErrorContainer;
}

/**
 * Renders phone number validation error html dom elements based on the twilio validation object.
 * @param {Object} new_user_data - The new returned with person_format user_data object
 * @param {Object} original_user_data - The original user provided (or cached) user_data object
 * @param {Object} twilio_validation_object - The twilio validation object returned from the twilio verify service
 * @param {Object} data_hub_instance - the data hub reference object.
 * @returns {HTMLElement} phoneErrorContainer with phone error information
 */
export const validateTwilioVerifyNumber = (
    new_user_data, original_user_data, twilio_validation_object, data_hub_instance = window?.data_hub, form_container = document
) => {
    try {
        // Remove unused example response to clean up the code
        
        let isValid = true;
        let validationErrors = [];
        let phoneNumberKeyName = null;
        let originalPhoneNumber = '';
        let newPhoneNumber = '';

        // Find which phone number key matches the original user_data e.g. cell_phone or phoneNumber
        const originalUserDataPhones = Object.keys(original_user_data).filter(key => phone_number_names.includes(key));

        // Fix: Iterate through the actual phone keys, not Object.keys of the array
        for (const key of originalUserDataPhones) {
            let e164PhoneNumber = '';
            try {
                e164PhoneNumber = intlTelInput?.utils?.formatNumber(original_user_data[key], 'E.164');
            } catch(e) {
                console.log('debug format using tel input utils error', e);
            }
            
            if (
                e164PhoneNumber === twilio_validation_object?.phone_number
                || e164PhoneNumber === twilio_validation_object?.national_format
                || original_user_data[key] === twilio_validation_object?.phone_number 
                || original_user_data[key] === twilio_validation_object?.national_format) {

                phoneNumberKeyName = key;
                originalPhoneNumber = original_user_data[key];
                newPhoneNumber = twilio_validation_object?.national_format || twilio_validation_object?.phone_number;
                break; // Exit once we've found a match
            }
        }

        // If no matching phone number key was found, use a default
        if (!phoneNumberKeyName && originalUserDataPhones.length > 0) {
            phoneNumberKeyName = originalUserDataPhones[0];
            originalPhoneNumber = original_user_data[phoneNumberKeyName];
            newPhoneNumber = twilio_validation_object?.national_format || twilio_validation_object?.phone_number;
        }

        // Check validation errors
        if (Array.isArray(twilio_validation_object?.validation_errors) && twilio_validation_object.validation_errors.length > 0) {
            isValid = false;
            validationErrors.push(...twilio_validation_object.validation_errors);
        } else if (twilio_validation_object?.line_status?.status?.toLowerCase() === 'unreachable' 
            || twilio_validation_object?.line_status?.status?.toLowerCase() === 'inactive') {
            isValid = false;
            validationErrors.push('UNREACHABLE_NUMBER');
        } else if (twilio_validation_object?.sms_pumping_risk) {
            if (twilio_validation_object.sms_pumping_risk.carrier_risk_category === 'high') {
                isValid = false;
                validationErrors.push('HIGH_RISK_NUMBER');
            } else if (twilio_validation_object.sms_pumping_risk.number_blocked === true) {
                isValid = false;
                validationErrors.push('BLOCKED_NUMBER');
            } else if (twilio_validation_object.sms_pumping_risk.sms_pumping_risk_score > 90) {
                isValid = false;
                validationErrors.push('HIGH_RISK_SCORE');
            }
        } else if (twilio_validation_object?.valid === false) { // FIX: Changed from true to false
            isValid = false;
            validationErrors.push('INVALID_NUMBER');
        }

        // Only generate UI if we have validation errors
        if (!isValid && validationErrors.length > 0) {
            return generatePhoneNumberValidationUI(validationErrors, originalPhoneNumber, newPhoneNumber, phoneNumberKeyName, form_container);
        }
        
        return null;
    } catch (error) {
        data_hub_instance?.error_function('Error validating Twilio Verify Number: ' + error);
        return null;
    }
};

/**
 * Generates a UI component to display email validation errors with highlighting of changes.
 * 
 * @param {Array} validationErrors - Array of email errors.
 * @param {string} originalEmail - The original email address.
 * @param {string} newEmail - The corrected email address.
 * @param {string} emailKeyName - The key name of the email being validated.
 * @param {Document} form_container - The document to create elements with.
 * 
 * @returns {HTMLElement} emailErrorContainer with email error information.
 */
export const generateEmailValidationUI = (validationErrors, originalEmail, newEmail, emailKeyName = 'email', form_container = document) => {
    if (!validationErrors || validationErrors.length === 0) {
        return null;
    }

    // Ensure we're creating elements with the correct document context
    const doc = form_container.ownerDocument || form_container;

    // Create outer wrapper with padding
    const outerWrapper = doc.createElement('div');
    outerWrapper.classList.add('email-validation-wrapper', 'p-4', 'w-full');

    // Create the actual error container with the yellow styling
    const emailErrorContainer = doc.createElement('div');
    emailErrorContainer.classList.add('email-error-container', 'flex', 'flex-col', 'items-start', 'justify-start', 
        'border', 'border-amber-200', 'rounded-lg', 'bg-amber-50', 'p-4', 'w-full', 'mb-2', 'shadow-sm', 'relative');

    // Add close button
    const closeButton = doc.createElement('button');
    closeButton.setAttribute('type', 'button');
    closeButton.setAttribute('aria-label', 'Close email validation message');
    closeButton.classList.add(
        'absolute', 'top-2', 'right-2', 
        'text-gray-500', 'hover:text-gray-700', 'hover:bg-gray-100',
        'bg-white',
        'p-1.5', 'rounded-full', 'focus:outline-none', 'focus:ring-2', 'focus:ring-gray-300',
        'transition-colors', 'duration-200'
    );
    
    // Add X SVG icon
    closeButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
    `;

    const fadeOutRemove = () => {
        // Check to make sure the outer wrapper still exists
        try {
            if (!outerWrapper) { return; }
            outerWrapper.classList.add('fade-out');
            setTimeout(() => {
                if (outerWrapper.parentNode) {
                    outerWrapper.parentNode.removeChild(outerWrapper);
                }
            }, 300);
        } catch (error) {
            console.error('Error removing email validation UI:', error);
        }
    };
    
    // Add click handler to close button that removes the outer wrapper
    closeButton.addEventListener('click', () => {
        fadeOutRemove();
    });

    // Set a timeout to get rid in 5 seconds
    
    setTimeout(() => {
        fadeOutRemove()
    }, 5000);
    
    emailErrorContainer.appendChild(closeButton);

    const innerEmailErrorContainer = doc.createElement('div');
    innerEmailErrorContainer.classList.add('flex', 'flex-col', 'items-center', 'justify-center', 'w-full');

    // Create the main header with email key name formatted nicely
    const displayKeyName = emailKeyName.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    const errorHeader = doc.createElement('h3');
    errorHeader.classList.add('font-semibold', 'text-center', 'w-full', 'text-amber-800', 'mb-2');
    errorHeader.textContent = `${displayKeyName} Updated`;
    innerEmailErrorContainer.appendChild(errorHeader);
    
    // Create subheading with list of changes made
    const changesSubheading = doc.createElement('p');
    changesSubheading.classList.add('text-amber-700', 'text-sm', 'text-center', 'mb-3', 'w-full');
    
    // Format the list of changes in a human-readable way
    const changeDescriptions = validationErrors.map(error => {

        // if is not a string return 'Formatting Error'
        if (typeof error !== 'string') {
            return 'Formatting Error';
        }

        // If the error is gmail_plus_track manually return 'Gmail Alias'
        if (error.toUpperCase() === 'GMAIL_PLUS_TRACK') {
            return 'Gmail Alias';
        }

        const errorText = error.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
        return errorText;
    });
    changesSubheading.textContent = changeDescriptions.join(', ');
    innerEmailErrorContainer.appendChild(changesSubheading);
    
    // Create before-and-after comparison container
    const comparisonContainer = doc.createElement('div');
    comparisonContainer.classList.add('bg-white', 'rounded-md', 'p-3', 'border', 'border-amber-100', 'w-full', 'flex', 'flex-wrap', 'items-center', 'justify-center', 'gap-2');
    
    if (originalEmail && newEmail) {
        // Determine which part changed (local or domain)
        const [originalLocal, originalDomain] = originalEmail.split('@');
        const [newLocal, newDomain] = newEmail.split('@');
        
        // Original Email
        const originalEmailDisplay = doc.createElement('div');
        originalEmailDisplay.classList.add('flex', 'items-center');
        
        if (originalLocal !== newLocal) {
            originalEmailDisplay.innerHTML = 
                `<span class="${originalLocal !== newLocal ? 'text-red-600' : ''}">${originalLocal}</span>` +
                `<span>@</span>` +
                `<span class="${originalDomain !== newDomain ? 'text-red-600' : ''}">${originalDomain}</span>`;
        } else {
            originalEmailDisplay.innerHTML = 
                `<span>${originalLocal}</span>` + 
                `<span>@</span>` +
                `<span class="text-red-600">${originalDomain}</span>`;
        }
        comparisonContainer.appendChild(originalEmailDisplay);
        
        // Arrow
        const arrowIcon = doc.createElement('div');
        arrowIcon.classList.add('mx-2', 'flex', 'items-center');
        arrowIcon.innerHTML = `<span class='text-amber-700'>&gt;</span>
        <svg width="24pt" height="24pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
            <path d="m442.56 914.88h314.88c69.273 0 125.95-56.68 125.95-125.95v-314.88c0-69.273-56.68-125.95-125.95-125.95h-94.465c-18.895 0-31.488 12.594-31.488 31.488 0 18.895 12.594 31.488 31.488 31.488h94.465c34.637 0 62.977 28.34 62.977 62.977v314.88c0 34.637-28.34 62.977-62.977 62.977h-314.88c-34.637 0-62.977-28.34-62.977-62.977v-94.465c0-18.895-12.594-31.488-31.488-31.488-18.895 0-31.488 12.594-31.488 31.488v94.465c0.003907 69.27 56.684 125.95 125.96 125.95z" fill="#007335"/>
            <path d="m543.32 694.46h88.168c18.895 0 31.488-12.594 31.488-31.488v-88.168c0-9.4453-3.1484-15.742-9.4453-22.043l-179.48-179.48c-6.2969-6.2969-12.594-9.4453-22.043-9.4453-9.4453 0-15.742 3.1484-22.043 9.4453l-88.168 88.168c-12.594 12.594-12.594 31.488 0 44.082l179.48 179.48c6.3008 6.2969 12.602 9.4453 22.047 9.4453zm56.68-62.977h-44.082l-147.99-147.99 44.082-44.082 147.99 147.99z" fill="#007335"/>
            <path d="m363.84 514.98c9.4453 0 15.742-3.1484 22.043-9.4453l88.168-88.168c12.594-12.594 12.594-31.488 0-44.082l-88.168-88.168c-12.594-12.594-31.488-12.594-44.082 0l-88.168 88.168c-12.594 12.594-12.594 31.488 0 44.082l88.168 88.168c6.2969 6.2969 12.594 9.4453 22.039 9.4453zm44.086-119.66-44.082 44.082-44.082-44.082 44.082-44.082z" fill="#007335"/>
        </svg><span class='text-emerald-600'>&gt;</span>`;
        comparisonContainer.appendChild(arrowIcon);
        
        // New Email
        const newEmailDisplay = doc.createElement('div');
        newEmailDisplay.classList.add('flex', 'items-center');
        
        if (originalLocal !== newLocal) {
            newEmailDisplay.innerHTML = 
                `<span class="${originalLocal !== newLocal ? 'text-emerald-600 font-medium' : ''}">${newLocal}</span>` +
                `<span>@</span>` +
                `<span class="${originalDomain !== newDomain ? 'text-emerald-600 font-medium' : ''}">${newDomain}</span>`;
        } else {
            newEmailDisplay.innerHTML = 
                `<span>${newLocal}</span>` + 
                `<span>@</span>` +
                `<span class="text-emerald-600 font-medium">${newDomain}</span>`;
        }
        comparisonContainer.appendChild(newEmailDisplay);
    } else {
        // If we only have one version
        const emailElement = doc.createElement('div');
        emailElement.textContent = originalEmail || newEmail;
        comparisonContainer.appendChild(emailElement);
    }
    
    innerEmailErrorContainer.appendChild(comparisonContainer);

    // Set auto-dismiss timer (10 seconds)
    const autoDismissTimer = setTimeout(() => {
        closeButton.click();
    }, 10000);
    
    // If user interacts with the container, cancel auto-dismiss
    outerWrapper.addEventListener('mouseenter', () => {
        clearTimeout(autoDismissTimer);
    });

    emailErrorContainer.appendChild(innerEmailErrorContainer);
    outerWrapper.appendChild(emailErrorContainer);

    return outerWrapper;
};

/**
 * Renders email validation error html dom elements based on the email validation object.
 * 
 * @param {Object} new_user_data - The new returned with person_format user_data object
 * @param {Object} original_user_data - The original user provided (or cached) user_data object
 * @param {Object} email_validation_object - The email validation object returned from the validation service
 * @param {Object} data_hub_instance - the data hub reference object.
 * @returns {HTMLElement} emailErrorContainer with email error information
 */
export const validateEmailDomElements = (
    new_user_data, original_user_data, email_validation_object, data_hub_instance = window?.data_hub, form_container = document
) => {
    try {
        // Email related fields that could be present in user data
        let validationErrors = [];
        let emailKeyName = null;
        let originalEmail = '';
        let newEmail = '';

        // Find which email field matches
        const originalUserDataEmails = Object.keys(original_user_data).filter(key => 
            email_names.includes(key.toLowerCase()));
            
        // Find matching email field
        for (const key of originalUserDataEmails) {
            const result = email_validation_object?.result;
            if (result && Array.isArray(result) && result.length > 0) {
                const firstResult = result[0];
                
                if (original_user_data[key]?.toLowerCase() === firstResult?.provided_email?.toLowerCase() ||
                    original_user_data[key]?.toLowerCase() === firstResult?.email?.toLowerCase()) {
                    
                    emailKeyName = key;
                    originalEmail = original_user_data[key];
                    // If there's a corrected email, use it
                    newEmail = firstResult?.email || original_user_data[key];
                    break;
                }
            }
        }
        
        // If no matching key was found but we have emails, use the first one
        if (!emailKeyName && originalUserDataEmails.length > 0) {
            emailKeyName = originalUserDataEmails[0];
            originalEmail = original_user_data[emailKeyName];
            
            // Try to get the corrected email from the result
            const firstResult = email_validation_object?.result?.[0];
            newEmail = firstResult?.email || originalEmail;
        }
        
        // Check for validation errors
        const results = email_validation_object?.result;
        if (results && Array.isArray(results) && results.length > 0) {
            results.forEach(single_result => {
                // Check for specific validation issues
                if (single_result?.errors && Array.isArray(single_result.errors) && single_result.errors.length > 0) {
                    validationErrors.push(...single_result.errors);
                }

                if (single_result?.alterations && Array.isArray(single_result.alterations) && single_result.alterations.length > 0) {
                    validationErrors.push(...single_result.alterations);
                }
                
                if (single_result?.domain?.dns_valid === false) {
                    validationErrors.push('INVALID_DOMAIN');
                }
                
                if (single_result?.email_mx_valid_check === false) {
                    validationErrors.push('INVALID_MX_RECORD');
                }
                
            });
            
            
        } else if (email_validation_object?.status_code !== 200) {
            validationErrors.push('EMAIL_VALIDATION_FAILED');
        }

        // Only generate UI if we have validation errors
        if (validationErrors.length > 0) {
            return generateEmailValidationUI(validationErrors, originalEmail, newEmail, emailKeyName, form_container);
        }
        
        return null;
    } catch (error) {
        data_hub_instance?.error_function('Error validating email: ' + error);
        return null;
    }
};

/**
 * Generate ux elements for person format modifications. E.g. email changed @gail.com to @gmail.com show a warning top right.
 * @param {Object} response_data data returned from the user_data form POST. should contain user_data and third_party_results
 * @param {Object} original_user_data 
 * @param {HTMLElement} form_container 
 * @param {Object} data_hub_instance 
 * @returns {void} 
 */
export const create_person_format_modifications_ux_elements = async (
    response_data, original_user_data, form_container, data_hub_instance = window?.data_hub
) => {
    try {
        const validationErrorsDomElements = await validateAgainstNewUserData(
            response_data?.returns?.user_data || {},
            original_user_data || {},
            response_data?.returns?.third_party_results || [],
            data_hub_instance,
            form_container.ownerDocument || document // Pass the correct document context
        );

        // Check if we got validation error elements back and they're in an array
        if (Array.isArray(validationErrorsDomElements) && validationErrorsDomElements.length > 0) {
            // Create a container for all validation messages if there are multiple
            const validationContainer = (form_container.ownerDocument || document).createElement('div');

            // Fix: Add classes individually instead of as a space-separated string
            const classNames = [
                'data-hub-validation-container',
                'data_hub_error_popover_zindex',
                'fixed',
                'w-xs',
                'top-4',
                'right-4',
                'flex',
                'flex-col',
                'items-end',
                'space-y-3',
                'p-4'
            ];

            classNames.forEach(className => {
                validationContainer.classList.add(className);
            });

            // Add each validation element to our container
            validationErrorsDomElements.forEach(element => {
                if (element && element.nodeType === Node.ELEMENT_NODE) {
                    validationContainer.appendChild(element);
                }
            });

            // Insert the container at the top of the form
            if (validationContainer.childNodes.length > 0) {
                form_container.insertBefore(validationContainer, form_container.firstChild);
            }
        }
    } catch (error) {
        console.warn('Error validating user data against new user data:', error);
    }
}