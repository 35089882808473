// helpers/index.js

import {
    default_forms_domain,
    white_list_incoming_person_fields
} from './static_vars';
import { cookie_utils } from './cookies';

export function get_boolean_query_param(param) {
    // If no param return null
    if (!param) { return null; }
    const value = new URLSearchParams(window.location.search).get(param);
    // if no value return null  
    if (!value) { return null; }
    return parse_boolean_falsy_types(value);
}

/**
 * Validates if a string is a valid URL
 * @param {string} url - The URL to validate
 * @returns {boolean} - Whether the URL is valid
 */
export function is_valid_url(url) {
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
}


// TODO work on this. need to scope the loading check somehow? trying to remove window.references
// basically dont want to allow user to nav out if form is still POSTing
// const default_unload_function = (event, error_function = null, is_submission_in_progress = null) => {
//     event.preventDefault();
//     //event.returnValue = '';
//     console.log('debug: could call shared amplitude conversion here is_submission_in_progress', is_submission_in_progress, 'error_function', error_function);

//     if (window.is_data_hub_form_submission_in_progress && window.is_data_hub_form_submission_in_progress === true) {
//         const errorFunc = error_function || todo removed window.data_hub_error_function || ((msg) => console.error(msg));
//         //event.returnValue = 'A form submission is in progress. Are you sure you want to leave?';
//         errorFunc('A form submission is in progress. Are you sure you want to leave?');
//     }
// };

// Quirks with url param. Allow parsing a literal quote string
export function parse_int_or_return_null(value) {

    //if value is a string and starts and ends with quote
    if (typeof value === 'string' && value.startsWith('"') && value.endsWith('"')) {
        // Check that there are only two quotes in the entire string
        if (value.match(/"/g).length === 2) {
            value = value.slice(1, -1);
        }
    }

    // do the same for single quote ''
    if (typeof value === 'string' && value.startsWith("'") && value.endsWith("'")) {
        // Check that there are only two quotes in the entire string
        if (value.match(/'/g).length === 2) {
            value = value.slice(1, -1);
        }
    }

    const parsed = parseInt(parseFloat(value));
    return isNaN(parsed) ? null : parsed;
}

export function parse_float_or_return_null(value) {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? null : parsed;

}

export function is_likely_matching_boolean_falsy_string(the_value) {
    const truthy_values = ['true', 't', '1', 'yes', 'on'];
    const falsy_values = ['false', 'f', '0', 'no', 'off'];

    if (typeof the_value === 'string') {
        if (truthy_values.includes(the_value.toLowerCase()) || falsy_values.includes(the_value.toLowerCase())) {
            return true;
        }
    }
    return false;

}

export function parse_boolean_falsy_types(the_value) {
    const truthy_values = ['true', 't', '1', 'yes', 'on'];
    const falsy_values = ['false', 'f', '0', 'no', 'off'];

    if (typeof the_value === 'string') {
        if (truthy_values.includes(the_value.toLowerCase())) {
            return true;
        } else if (falsy_values.includes(the_value.toLowerCase())) {
            return false;
        }
    } else if (typeof the_value === 'boolean') {
        return the_value;
    } else if (typeof the_value === 'number') {
        return the_value !== 0;
    } else {
        console.error('parse_boolean_falsy_types: value is not a string, boolean, or number', the_value);
        return null;
    }
}

export const set_iterable_campaign_template_temporary_cookies = (
    iterableCampaignId = window?.data_hub?.iterableCampaignId || null,
    iterableTemplateId = window?.data_hub?.iterableTemplateId || null
) => {
    try {
        const COOKIE_EXPIRY = 86400;
        if (iterableCampaignId) {
            cookie_utils.set_cookie('iterableCampaignId', iterableCampaignId, { max_age: COOKIE_EXPIRY, path: '/' });
        }
        if (iterableTemplateId) {
            cookie_utils.set_cookie('iterableTemplateId', iterableTemplateId, { max_age: COOKIE_EXPIRY, path: '/' });
        }
    } catch (error) {
        console.error('Error setting iterable cookies:', error);
    }
};

export async function fetch_with_timeout(resource, options = {}) {
    const { timeout = 2000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    // TODO should add 'X-Requested-With': 'XMLHttpRequest' into headers?
    try {
        const response = await fetch(resource, {
            ...options,
            signal: controller.signal
        });
        clearTimeout(id);

        return response;

    } catch (error) {
        clearTimeout(id);
        if (error.name === 'AbortError') {
            console.warn('Fetch error: Request was aborted due to timeout', resource, error);
        } else {
            throw error;
        }
    }
}

// New function, mainly used in checking existing actblue.js so can call setInterval a max n number of time / seconds
export const poll_with_retry = (checkFn, options = {}) => {
    const { interval = 100, timeout = 5000 } = options;
    const startTime = Date.now();
    let attempts = 0;
    const maxAttempts = Math.floor(timeout / interval);

    return new Promise((resolve, reject) => {
        const check = () => {
            attempts++;
            if (checkFn()) {
                resolve(true);
            } else if (Date.now() - startTime >= timeout || attempts >= maxAttempts) {
                reject(new Error(`Polling timed out after ${timeout}ms`));
            } else {
                setTimeout(check, interval);
            }
        };
        check();
    });
};

// 250427 added new skip empty arrays to allow over-write of message type subscriptions to unsub/send empties
export function remove_empty_values_from_object(data, depth = 0, max_depth = 10, empty_array_keys_to_skip = []) {
    if (!empty_array_keys_to_skip || typeof empty_array_keys_to_skip !== 'object') { empty_array_keys_to_skip = []; }
    if (depth > max_depth) {
        console.log(`Soft error: maximum recursion depth of ${max_depth} exceeded`);
        return data;
    }

    if (Array.isArray(data)) {
        return data
            .filter(item =>
                item !== undefined &&
                item !== null &&
                item !== '' &&
                !(Array.isArray(item) && item.length === 0) &&
                !(item.constructor === Object && Object.keys(item).length === 0)
            )
            .map(item => remove_empty_values_from_object(item, depth + 1, max_depth, empty_array_keys_to_skip));
    } else if (data !== null && typeof data === 'object') {
        return Object.entries(data).reduce((acc, [key, value]) => {
            if (
                value !== undefined &&
                value !== null &&
                value !== '' &&
                !(Array.isArray(value) && value.length === 0 && !empty_array_keys_to_skip.includes(key)) &&
                !(value.constructor === Object && Object.keys(value).length === 0)
            ) {
                acc[key] = remove_empty_values_from_object(value, depth + 1, max_depth, empty_array_keys_to_skip);
            }
            return acc;
        }, {});
    }
    return data;
}

// formerly return_valid_forms_domain
export function return_valid_or_default_forms_domain(forms_domain) {
    // Default domain is the shared pooled domain for caching user data
    if (!forms_domain) {
        forms_domain = default_forms_domain
    }
    // Remove trailing slash if present
    if (forms_domain.endsWith('/')) {
        forms_domain = forms_domain.slice(0, -1);
    }
    // Ensure domains start with 'https://'
    if (!forms_domain.startsWith('https://')) {
        console.error('ERROR: forms_domain must start with https://');
        return null;
    }
    return forms_domain;
}

export function simple_string_to_html_basic_sanitization(input) {
    try {
        // Define allowed tags and attributes
        const allowedTags = [
            'b', 'i', 'em', 'strong', 'a', 'p', 'div', 'span',
            'ul', 'ol', 'li', 'br', 'img', 'h1', 'h2', 'h3',
            'h4', 'h5', 'h6', 'blockquote', 'pre', 'code'
        ];

        const allowedAttributes = {
            'a': ['href', 'title', 'style', 'target', 'rel'],
            'img': ['src', 'alt', 'title', 'style', 'width', 'height'],
            '*': ['style', 'class']
        };

        // Create a temporary DOM element to parse the input
        const parser = new DOMParser();
        const doc = parser.parseFromString(input, 'text/html');

        // Function to recursively sanitize nodes
        function sanitizeNode(node) {
            const nodeName = node.nodeName.toLowerCase();

            if (node.nodeType === Node.TEXT_NODE) {
                return node.cloneNode();
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                if (allowedTags.includes(nodeName)) {
                    // Create a new element to avoid inheriting unwanted attributes
                    const newEl = document.createElement(nodeName);

                    // Copy allowed attributes
                    const allowedAttrs = allowedAttributes[nodeName] || allowedAttributes['*'] || [];
                    for (let attr of node.attributes) {
                        if (allowedAttrs.includes(attr.name.toLowerCase())) {
                            newEl.setAttribute(attr.name, attr.value);
                        }
                    }

                    // Recursively sanitize and append child nodes
                    for (let child of node.childNodes) {
                        const sanitizedChild = sanitizeNode(child);
                        if (sanitizedChild) {
                            newEl.appendChild(sanitizedChild);
                        }
                    }
                    return newEl;
                } else {
                    // If tag is not allowed, process its children but skip the tag itself
                    const fragment = document.createDocumentFragment();
                    for (let child of node.childNodes) {
                        const sanitizedChild = sanitizeNode(child);
                        if (sanitizedChild) {
                            fragment.appendChild(sanitizedChild);
                        }
                    }
                    return fragment;
                }
            } else {
                // For other node types (e.g., comments), skip
                return null;
            }
        }

        // Sanitize the body content
        const sanitizedFragment = document.createDocumentFragment();
        for (let child of doc.body.childNodes) {
            const sanitizedChild = sanitizeNode(child);
            if (sanitizedChild) {
                sanitizedFragment.appendChild(sanitizedChild);
            }
        }

        // Serialize the sanitized content back to a string
        const div = document.createElement('div');
        div.appendChild(sanitizedFragment);
        return div.innerHTML;
    } catch (e) {
        console.error('simpleSanitizeHTML error:', e);
        return '';
    }

}

/**
 * Replaces tokens in the form {{ key }} with their values from data,
 * but only if the key is allowed by the provided whiteList.
 *
 * If a token is not allowed, it is simply removed from the string.
 *
 * @param {string} str - The string containing placeholder tokens.
 * @param {object} data - The data object with replacement values.
 * @param {object|Array} whiteList - Either an array of allowed keys or an object
 *                                   whose own properties are the allowed keys.
 */
export const replaceWhiteListedPersonPlaceholders = (str, data, whiteList) => {
    try {
        return str.replace(/{{\s*(.*?)\s*}}/g, (match, key) => {
            key = key.trim();
            let isKeyAllowed = false;

            if (Array.isArray(whiteList)) {
                isKeyAllowed = whiteList.includes(key);
            } else if (whiteList && typeof whiteList === 'object') {
                isKeyAllowed = Object.prototype.hasOwnProperty.call(whiteList, key);
            }

            // If allowed, replace with data value (or empty string if undefined).
            // Otherwise, remove the token entirely.
            return isKeyAllowed ? (data[key] || '') : '';
        });
    } catch (e) {
        console.error(e);
        return str;
    }
};

/**
* A simple template processor that replaces tokens of the form
*   {{ allowed.token }}
* with values from the context object. Uses real Handlebars if available.
*
* Any token whose content is not purely alphanumeric (plus underscores and dots)
* is simply removed.
*
* @param {string} templateString - The template string containing {{ tokens }}.
* @param {object} context - An object containing keys/values to replace.
* @returns {string} - The processed string.
*/
export const processHandlebarsTemplate = (templateString, context = {}) => {
    // exit if not templateString or context is empty
    if (!templateString || typeof templateString !== 'string'
        || !context || typeof context !== 'object' || Object.keys(context).length === 0) {
        return templateString;
    }
    try {
        // Check if Handlebars is loaded and usable.
        if (
            typeof Handlebars !== 'undefined' &&
            typeof Handlebars.compile === 'function'
        ) {
            return Handlebars.compile(templateString)(context);
        }

        // Fallback simple template processor:
        // Replace any {{ key }} or {{ nested.key }} occurrences.
        return templateString.replace(/{{\s*([^}]+?)\s*}}/g, (match, tokenContent) => {
            // Trim the token content.
            const token = tokenContent.trim();

            // Allowed tokens: only letters, digits, underscores and dots.
            // For example: "user_data.first_name"
            if (/^[A-Za-z0-9_]+(?:\.[A-Za-z0-9_]+)*$/.test(token)) {
                // Use the dot-separated keys to retrieve the value from the context.
                const value = token.split('.').reduce((obj, key) => (obj ? obj[key] : undefined), context);
                return value !== undefined && value !== null ? value : '';
            }
            // For any token that doesn't match the allowed pattern (such as helpers, conditionals, etc.),
            // simply remove it.
            return '';
        });
    } catch (error) {
        console.error('Error processing Handlebars template:', error);
        return templateString;
    }
};

export const replace_white_listed_person_placeholders = (str, data) => {
    try {
        // look for handlebars {{}} and replace with data, but only if the key for data is in the object white_list_incoming_person_fields
        return str.replace(/{{(.*?)}}/g, (match, key) => {
            key = key.trim();

            let isKeyAllowed = false;

            if (Array.isArray(white_list_incoming_person_fields)) {
                isKeyAllowed = white_list_incoming_person_fields.includes(key);
            } else if (typeof white_list_incoming_person_fields === 'object' && white_list_incoming_person_fields !== null) {
                isKeyAllowed = white_list_incoming_person_fields.hasOwnProperty(key);
            }

            return isKeyAllowed ? data[key] || '' : match;

        });
    } catch (e) {
        console.error(e)
    }
    return str
}

export const get_most_parent_url_shadow_dom_safe = () => {
    try {
        // Check if the script is running in a Shadow DOM context
        if (document.currentScript?.ownerDocument?.defaultView?.top) {
            return document.currentScript.ownerDocument.defaultView.top.location.href.split('?')[0];
        }

        // Check if the script is running in an iframe
        if (window.self !== window.top) {
            try {
                return window.top.location.href.split('?')[0];
            } catch (e) {
                console.error("Cross-origin issue accessing iframe parent URL:", e);
                return document.referrer.split('?')[0]; // Fallback to referrer if available
            }
        }
    } catch (e) {
        console.error("Error determining parent URL:", e);
    }

    // Default to the current window's URL if all else fails
    return window.location.href.split('?')[0];
};

/**
 * Filters an array to include only numbers.
 * @param {Array} the_array - The array to filter.
 * @param {boolean} [ints_only=true] - If true, only integers are included. If false, both integers and floats are allowed.
 * @returns {Array<number>} - An array containing only numbers based on the `ints_only` flag.
 */
export const return_only_numbers_in_array = (the_array, ints_only = true) => {
    if (!Array.isArray(the_array)) {
        console.error('return_only_numbers_in_array: Expected an array, received:', the_array, typeof the_array);
        return [];
    }

    return the_array.reduce((accumulator, val) => {
        let num;

        if (ints_only) {
            num = parseInt(val, 10);
        } else {
            num = parseFloat(val);
        }

        if (!isNaN(num)) {
            accumulator.push(num);
        } else {
            console.warn(`return_only_numbers_in_array: Value "${val}" is not a valid number.`);
        }

        return accumulator;
    }, []);
};

/**
 * Converts a string representation of an array or a comma-separated string into an array of numbers.
 * @param {string|Array} input - The string or array to convert.
 * @param {boolean} [ints_only=false] - If true, only integers are included. If false, both integers and floats are allowed.
 * @returns {Array<number>} - An array of numbers.
 */
export const string_array_of_ints_to_array = (input, ints_only = false) => {
    let parsedArray = [];

    if (typeof input === 'string') {
        input = input.trim();

        // Check if the string starts with '[' and ends with ']', indicating a JSON array
        if (input.startsWith('[') && input.endsWith(']')) {
            try {
                parsedArray = JSON.parse(input);
                if (!Array.isArray(parsedArray)) {
                    console.error('string_array_of_ints_to_array: Parsed JSON is not an array:', parsedArray);
                    return [];
                }
            } catch (e) {
                console.error('string_array_of_ints_to_array: Error parsing JSON string:', e, input);
                return [];
            }
        } else {
            // Assume it's a comma-separated string
            parsedArray = input.split(',').map(val => val.trim());
        }
    } else if (Array.isArray(input)) {
        parsedArray = input;
    } else {
        console.error('string_array_of_ints_to_array: Input is neither a string nor an array:', input, typeof input);
        return [];
    }

    // Filter and convert to numbers
    const numbers_only = return_only_numbers_in_array(parsedArray, ints_only);
    return numbers_only;
};

/**
 * Converts a string (e.g. "metadata_keyName", "METADATAKEYNAME", "key-name") 
 * into camelCase. 
 * @param {string} str - The string to convert.
 * @returns {string} - The camelCase string.
 */
export const toCamelCase = (str) => {
    return str
        // Replace any separator (hyphen, underscore, or space) followed by a character with the uppercase character.
        .replace(/[-_\s]+(.)/g, (_, group1) => group1.toUpperCase())
        // Lowercase the first character.
        .replace(/^(.)/, (match, group1) => group1.toLowerCase());
};

/**
 * Normalizes a metadata object.
 * If a key starts with "metadata" (any case), that prefix is removed.
 * Then all keys are converted into camelCase.
 * This allows metadata keys to be passed in as either:
 *   metadata_keyname, metadataKeyName, metadatakeyname, or simply keyname.
 *
 * @param {object} metadataObj - The raw metadata object.
 * @param {function} [error_function] - Optional error callback.
 * @returns {object} - An object with normalized metadata keys.
 */
export const normalize_metadata_from_object = (metadataObj, error_function = window?.data_hub?.error_function) => {
    if (!metadataObj || typeof metadataObj !== 'object') return {};
    const normalized = {};
    for (let [key, value] of Object.entries(metadataObj)) {
        try {
            if (typeof key !== 'string') {
                error_function?.(`Metadata key is not a string: ${key}`);
                continue;
            }
            let normalizedKey;
            // Check if the key begins with "metadata" (ignoring case)
            if (key.toLowerCase().startsWith('metadata')) {
                // Remove the first 8 characters ("metadata") and any extra underscore/dash that might follow.
                let stripped = key.slice(8);
                // If the stripped key begins with a non-alphanumeric character, remove it.
                stripped = stripped.replace(/^[-_\s]+/, '');
                normalizedKey = toCamelCase(stripped);
            } else {
                normalizedKey = toCamelCase(key);
            }
            normalized[normalizedKey] = value;
        } catch (error) {
            error_function?.(`Error normalizing metadata key-value pair: ${key} = ${value}, ${error}`);
            continue;
        }
    }
    return normalized;
};


// WRapper to try base64 decoded json or simple 'array like string'
export const parse_b64_or_array_like_data_attribute = (inputString, error_function = window?.data_hub?.error_function) => {
    if (!inputString || typeof inputString !== 'string' || inputString.trim() === '') return {};
    inputString = inputString.trim();

    try {
        const decodedJson = atob(inputString);
        const parsedJson = JSON.parse(decodedJson);
        if (typeof parsedJson === 'object' && parsedJson !== null) {
            return parsedJson;
        }
    } catch (e) {
        // Failed base64 JSON decode; fall through.
    }

    const simpleParsed = parse_simple_key_value_string(inputString, error_function);
    if (simpleParsed && Object.keys(simpleParsed).length > 0) {
        return simpleParsed;
    }

    error_function(`Invalid data attribute format: ${inputString}`);
    return {};
};

export const parse_simple_key_value_string = (inputString, error_function = window?.data_hub?.error_function) => {
    // allow [key:value,keytwo:valuetwo]
    // and maybe key=value,key=two
    // as well as key:value
    // and key:value,keytwo:valuetwo
    if (!inputString || typeof inputString !== 'string') return {};
    inputString = inputString.trim();

    try {
        // Choose separator: if ':' exists use it; otherwise, '='.
        const separator = inputString.includes(':') ? ':' : '=';

        // Remove surrounding brackets if present.
        if (inputString.startsWith('[') && inputString.endsWith(']')) {
            inputString = inputString.slice(1, -1);
        }

        const keyValuePairs = inputString.split(',');
        const parsedObject = keyValuePairs.reduce((acc, pair) => {
            const [key, value] = pair.split(separator);
            if (key && value) {
                acc[key.trim()] = value.trim();
            } else {
                error_function("Key-value not in correct format: " + pair);
            }
            return acc;
        }, {});
        return parsedObject;
    } catch (e) {
        console.error('Error parsing simple key-value string:', e);
        return {};
    }
}

// Mostly for parsing htmlElement data attributes
export const parse_base64_json = (base64String) => {
    try {
        const jsonString = atob(base64String);
        return JSON.parse(jsonString);
    } catch (error) {
        console.error('Error parsing base64 JSON:', error);
        return null;
    }
}

export const parse_custom_html = (inputString) => {
    let decodedString = inputString;
    // Try Base64 decode
    try {
        decodedString = atob(inputString);
        return decodedString;
    } catch (error) {
        // Not Base64, try URL decode
        try {
            decodedString = decodeURIComponent(inputString);
            return decodedString;
        } catch (error) {
            return decodedString;
        }
    }

}

// COLORS and tailwind css generators
// So can pass a primary 600 value and generate full 100 -> 900 gradient
export function hex_to_hsl(hex) {
    if (!hex) {
        console.error("hex_to_hsl missing hex");
        return null;
    }
    if (typeof hex !== 'string') {
        console.error("hex_to_hsl hex must be a string");
        return null;
    }

    hex = hex.replace(/^#/, '');
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return [h * 360, s * 100, l * 100];
}

export function hsl_to_hex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    const to_hex = x => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${to_hex(r)}${to_hex(g)}${to_hex(b)}`;
}

export function generate_gradient_stops(main_hex_color) {
    if (!main_hex_color) {
        console.error("generate_gradient_stops missing main_hex_color");
        return {};
    }

    // Must be a valid color hex color code (3 or 6 digits)
    if (typeof main_hex_color !== 'string' || !main_hex_color.match(/^#([0-9A-F]{3}|[0-9A-F]{6})$/i)) {
        console.error("generate_gradient_stops main_hex_color must be a valid hex color code");
        return {};
    }

    // Normalize 3-digit hex codes to 6 digits
    if (main_hex_color.length === 4) {
        main_hex_color = '#' + main_hex_color.slice(1).split('').map(char => char + char).join('');
    }

    const hsl = hex_to_hsl(main_hex_color);
    const stops = {};

    for (let i = 1; i <= 9; i++) {
        const lightness = 100 - (i * 10); // Reverse the lightness
        stops[(i * 100).toString()] = hsl_to_hex(hsl[0], hsl[1], lightness);
    }

    // Number 600 should be the main_hex_color
    stops['600'] = main_hex_color;

    return stops;
}


export function find_closest_shade(colors, target) {
    if (!colors || !target) {
        console.error("find_closest_shade missing vars");
        return null;
    }
    const keys = Object.keys(colors).map(Number).sort((a, b) => a - b);
    return keys.reduce((prev, curr) => Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev).toString();
}

export function to_kebab_case(str) {
    if (!str) {
        return null;
    }
    if (typeof str !== 'string') {
        return null;
    }
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase().replace(/_/g, '-');
}

function get_simple_hash(text_content) {
    let hash = 0, i, chr;
    if (text_content.length === 0) return hash.toString();
    for (i = 0; i < text_content.length; i++) {
        chr = text_content.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
}

export function inject_styles(theme, target) {
    if (!target) {
        console.error('Error inject_styles target is null:', target);
        return;
    }
    if (!theme) {
        console.error('Error inject_styles theme is null:', theme);
        return;
    }

    // Generate a short hash of the theme JSON as a key
    const theme_key_hash = get_simple_hash(JSON.stringify(theme)).slice(0, 8);

    // Check if styles with the same hash have already been injected
    const existingStyles = target.querySelectorAll('style[data-theme-hash]');
    if (existingStyles.length) {
        for (let i = 0; i < existingStyles.length; i++) {
            if (existingStyles[i].dataset?.themeHash === theme_key_hash) {
                return; // Styles already injected, no need to proceed
            }
        }
    }

    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = generate_theme_css(theme);
    style.dataset.themeHash = theme_key_hash;

    const has_first_child = target.firstChild;
    if (has_first_child) {
        //add to the top of target
        target.insertBefore(style, target.firstChild);
    } else {
        target.appendChild(style);
    }
}

function generate_theme_css(theme) {

    let css = '';

    if (!theme) {
        console.error('Error generate_theme_css theme is null:', theme);
        return css;
    }

    Object.keys(theme).forEach(color_key => {
        const color = theme[color_key];
        let colors = color;
        const color_key_kebab = to_kebab_case(color_key);

        if (typeof color === 'string') {
            colors = generate_gradient_stops(color);
        } else if (typeof color === 'object' && Object.keys(color).length < 9) {
            const closest_shade = find_closest_shade(color, 600);
            colors = { ...generate_gradient_stops(color[closest_shade]), ...color };
        } else if (typeof color !== 'object' || Object.keys(color).length != 9) {
            console.error('Error generate_theme_css color is not a string or object with 9 keys:', color);
            return;
        }

        Object.keys(colors).forEach(shade_key => {
            if (!shade_key.toString().match(/^(100|200|300|400|500|600|700|800|900)$/)) {
                console.error('Error generate_theme_css shade_key is not a tailwind number:', shade_key);
                return;
            }

          

            const class_name = `${color_key_kebab}-${shade_key}`;
            css += `
                :root {
                    --${color_key_kebab}-${shade_key}: ${colors[shade_key]};
                }
                .text-${class_name} { color: ${colors[shade_key]}; }
                .bg-${class_name} { background-color: ${colors[shade_key]}; }
                .border-${class_name} { border-color: ${colors[shade_key]}; }
                .ring-${class_name} { ring-color: ${colors[shade_key]}; }
                .shadow-${class_name} { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                .placeholder-${class_name}::placeholder { color: ${colors[shade_key]}; }
                .divide-${class_name} > * + * { border-color: ${colors[shade_key]}; }
                .decoration-${class_name} { text-decoration-color: ${colors[shade_key]}; }
                .caret-${class_name} { caret-color: ${colors[shade_key]}; }
                .accent-${class_name} { accent-color: ${colors[shade_key]}; }
                .outline-${class_name} { outline-color: ${colors[shade_key]}; }
                .from-${class_name} { --tw-gradient-from: ${colors[shade_key]} var(--tw-gradient-from-position); }
                .ring-offset-${class_name} { --tw-ring-offset-color: ${colors[shade_key]}; }
                .shadow-${class_name} { --tw-shadow-color: ${colors[shade_key]}; }
                .fill-${class_name} { fill: ${colors[shade_key]}; }
                .stroke-${class_name} { stroke: ${colors[shade_key]}; }

                .hover\\:text-${class_name}:hover { color: ${colors[shade_key]}; }
                .hover\\:bg-${class_name}:hover { background-color: ${colors[shade_key]}; }
                .hover\\:border-${class_name}:hover { border-color: ${colors[shade_key]}; }
                .hover\\:ring-${class_name}:hover { ring-color: ${colors[shade_key]}; }
                .hover\\:shadow-${class_name}:hover { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                .hover\\:placeholder-${class_name}:hover::placeholder { color: ${colors[shade_key]}; }
                .hover\\:divide-${class_name}:hover > * + * { border-color: ${colors[shade_key]}; }

                .focus\\:text-${class_name}:focus { color: ${colors[shade_key]}; }
                .focus\\:bg-${class_name}:focus { background-color: ${colors[shade_key]}; }
                .focus\\:border-${class_name}:focus { border-color: ${colors[shade_key]}; }
                .focus\\:ring-${class_name}:focus { ring-color: ${colors[shade_key]}; }
                .focus\\:shadow-${class_name}:focus { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                .focus\\:placeholder-${class_name}:focus::placeholder { color: ${colors[shade_key]}; }
                .focus\\:divide-${class_name}:focus > * + * { border-color: ${colors[shade_key]}; }

                .active\\:text-${class_name}:active { color: ${colors[shade_key]}; }
                .active\\:bg-${class_name}:active { background-color: ${colors[shade_key]}; }
                .active\\:border-${class_name}:active { border-color: ${colors[shade_key]}; }
                .active\\:ring-${class_name}:active { ring-color: ${colors[shade_key]}; }
                .active\\:shadow-${class_name}:active { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                .active\\:placeholder-${class_name}:active::placeholder { color: ${colors[shade_key]}; }
                .active\\:divide-${class_name}:active > * + * { border-color: ${colors[shade_key]}; }
            `;
        });

        if (colors['600']) {
            css += `
                :root {
                    --${color_key_kebab}-600: ${colors['600']};
                }
                .text-${color_key_kebab} { color: ${colors['600']}; }
                .bg-${color_key_kebab} { background-color: ${colors['600']}; }
                .border-${color_key_kebab} { border-color: ${colors['600']}; }
                .ring-${color_key_kebab} { ring-color: ${colors['600']}; }
                .shadow-${color_key_kebab} { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                .placeholder-${color_key_kebab}::placeholder { color: ${colors['600']}; }
                .divide-${color_key_kebab} > * + * { border-color: ${colors['600']}; }
                .decoration-${color_key_kebab} { text-decoration-color: ${colors['600']}; }
                .caret-${color_key_kebab} { caret-color: ${colors['600']}; }
                .accent-${color_key_kebab} { accent-color: ${colors['600']}; }
                .outline-${color_key_kebab} { outline-color: ${colors['600']}; }
                .from-${color_key_kebab} { --tw-gradient-from: ${colors['600']} var(--tw-gradient-from-position); }
                .ring-offset-${color_key_kebab} { --tw-ring-offset-color: ${colors['600']}; }
                .shadow-${color_key_kebab} { --tw-shadow-color: ${colors['600']}; }
                .fill-${color_key_kebab} { fill: ${colors['600']}; }
                .stroke-${color_key_kebab} { stroke: ${colors['600']}; }

                .hover\\:text-${color_key_kebab}:hover { color: ${colors['600']}; }
                .hover\\:bg-${color_key_kebab}:hover { background-color: ${colors['600']}; }
                .hover\\:border-${color_key_kebab}:hover { border-color: ${colors['600']}; }
                .hover\\:ring-${color_key_kebab}:hover { ring-color: ${colors['600']}; }
                .hover\\:shadow-${color_key_kebab}:hover { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                .hover\\:placeholder-${color_key_kebab}:hover::placeholder { color: ${colors['600']}; }
                .hover\\:divide-${color_key_kebab}:hover > * + * { border-color: ${colors['600']}; }

                .focus\\:text-${color_key_kebab}:focus { color: ${colors['600']}; }
                .focus\\:bg-${color_key_kebab}:focus { background-color: ${colors['600']}; }
                .focus\\:border-${color_key_kebab}:focus { border-color: ${colors['600']}; }
                .focus\\:ring-${color_key_kebab}:focus { ring-color: ${colors['600']}; }
                .focus\\:shadow-${color_key_kebab}:focus { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                .focus\\:placeholder-${color_key_kebab}:focus::placeholder { color: ${colors['600']}; }
                .focus\\:divide-${color_key_kebab}:focus > * + * { border-color: ${colors['600']}; }

                .active\\:text-${color_key_kebab}:active { color: ${colors['600']}; }
                .active\\:bg-${color_key_kebab}:active { background-color: ${colors['600']}; }
                .active\\:border-${color_key_kebab}:active { border-color: ${colors['600']}; }
                .active\\:ring-${color_key_kebab}:active { ring-color: ${colors['600']}; }
                .active\\:shadow-${color_key_kebab}:active { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                .active\\:placeholder-${color_key_kebab}:active::placeholder { color: ${colors['600']}; }
                .active\\:divide-${color_key_kebab}:active > * + * { border-color: ${colors['600']}; }

                /* Loader Styles */
                .loader {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid ${colors['600']};
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    animation: spin 2s linear infinite;
                }

                /* Spinner for Submit Button */
                .spinner {
                    border: 2px solid #f3f3f3;
                    border-top: 2px solid ${colors['600']};
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    animation: spin 1s linear infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `;
        } else {
            console.error('Error generate_theme_css no 600 color_key:', color_key, 'color_key_kebab', color_key_kebab, 'colors:', colors);
        }
    });

    return css;
}