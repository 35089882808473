// src/forms/shared_form_ux_functions.js
import { form_input_types_string } from '../helpers/static_vars';
import { fetch_with_timeout, parse_boolean_falsy_types, is_valid_url } from '../helpers/index';
import { prefill_refill_tel_inputs } from '../tel/telephone_sms_inputs'

/**
 * 
 * @param {HTMLElement} form_container 
 * @param {string} submit_button_selector 
 * @returns 
 */
function add_submit_button_loading_state(
    form_container = document, submit_button_selector = '.data-hub-submit-form'
) {
    if (!form_container) {
        return;
    }

    const submit_button = form_container.querySelectorAll(submit_button_selector);
    if (!submit_button || !submit_button.length) {
        return;
    }

    // loop through all and add back .hidden classes
    submit_button.forEach(button => {
        if (button) {
            const button_text = button.querySelector('.button-text');
            if (!button_text) {
                return
            }

            const loader = button.querySelector('.loader-button, .loader');
            if (!loader) {
                return
            }

            button_text.classList.add('hidden');
            loader.classList.remove('hidden');

        }
    });
}

/**
 * 
 * @param {HTMLElement} form_container 
 * @param {string} submit_button_selector 
 */
function remove_submit_button_loading_state(
    form_container = document, submit_button_selector = '.data-hub-submit-form'
) {

    if (!form_container) {
        return;
    }

    const submit_button = form_container.querySelectorAll(submit_button_selector);
    if (!submit_button || !submit_button.length) {
        return;
    }

    // loop through all and add back .hidden classes
    submit_button.forEach(button => {
        if (button) {
            const button_text = button.querySelector('.button-text');
            if (!button_text) {
                return
            }

            const loader = button.querySelector('.loader-button, .loader');
            if (!loader) {
                return
            }

            button_text.classList.remove('hidden');
            loader.classList.add('hidden');
        }
    });
}

/**
 * Adds a loading overlay to the form container.
 * @param {HTMLElement|Document|ShadowRoot} form_container - The container element to add the loading overlay to
 * @param {string} loading_overlay_selector - CSS selector for existing overlay elements (default: '.data-hub-loading-overlay')
 * @param {Object} data_hub_instance - Data hub instance for error reporting
 * @returns {void}
 */
function add_form_loading_overlay(
    form_container = document,
    loading_overlay_selector = '.data-hub-loading-overlay',
    data_hub_instance = window?.data_hub
) {
    if (!form_container) { return data_hub_instance?.error_function?.('Missing form_container in add_form_loading_overlay'); }
    if (!loading_overlay_selector) { loading_overlay_selector = '.data-hub-loading-overlay'; }

    // Must be an html element, document, or shadow root
    if (form_container instanceof HTMLElement || form_container instanceof Document || form_container instanceof ShadowRoot) {
        const form_overlay_class_elements = form_container.querySelectorAll(loading_overlay_selector);
        if (form_overlay_class_elements.length) {
            form_overlay_class_elements.forEach(overlay => {
                overlay.classList.remove('hidden');
            });
        } else {
            const form_overlay = document.createElement('div');
            form_overlay.classList.add('data-hub-loading-overlay', 'fixed', 'inset-0', 'bg-white',
                'bg-opacity-50', 'flex', 'justify-center', 'items-center', 'z-50');
            form_overlay.innerHTML = '<div class="loader"></div>';

            // Determine the appropriate container to append to
            let targetContainer;
            if (form_container === document) {
                targetContainer = document.body;
            } else {
                targetContainer = form_container;
            }

            try {
                targetContainer.appendChild(form_overlay);
            } catch (error) {
                // If we hit a hierarchy error, try document.body as a fallback
                if (error.name === 'HierarchyRequestError') {
                    try {
                        document.body.appendChild(form_overlay);
                    } catch (secondError) {
                        data_hub_instance?.error_function?.(
                            'Failed to add overlay to document.body:' + secondError
                        );
                    }
                } else {
                    data_hub_instance?.error_function?.(
                        'Failed to add overlay to target container:' + error
                    );
                }
            }
        }
    } else {
        data_hub_instance?.error_function?.('Invalid form_container provided:', form_container);
    }
}

/**
 * Removes the loading overlay from the form container.
 * @param {HTMLElement|Document|ShadowRoot} form_container - The container element from which to remove the loading overlay
 * @param {string} loading_overlay_selector - CSS selector for overlay elements to hide (default: '.data-hub-loading-overlay')
 * @param {Object} data_hub_instance - Data hub instance for error reporting
 * @returns {void}
 */
function remove_form_loading_overlay(
    form_container = document,
    loading_overlay_selector = '.data-hub-loading-overlay',
    data_hub_instance = window?.data_hub
) {
    if (!loading_overlay_selector) { loading_overlay_selector = '.data-hub-loading-overlay'; }
    if (!form_container) { return data_hub_instance?.error_function?.('Missing form_container in remove_form_loading_overlay'); }
    // any overlay divs that are hidden
    if (form_container instanceof HTMLElement || form_container instanceof Document || form_container instanceof ShadowRoot) {
        const form_overlay_class_elements = form_container.querySelectorAll(loading_overlay_selector);
        if (form_overlay_class_elements.length) {
            form_overlay_class_elements.forEach(overlay => {
                overlay.classList.add('hidden');
            });
        }
    } else {
        data_hub_instance?.error_function?.('Invalid form_container provided:' + form_container);
    }
}

/**
 * Adds a loading state to form inputs elements
 * @param {HTMLElement} form_container the dom element to find the inputs
 * @param {string} prefill_input_selector the selector for the input elements to apply loading state
 * @param {Object} data_hub_instance the instance of the data hub
 * @returns 
 */
function add_form_input_loading_state(
    form_container = document,
    prefill_input_selector = form_input_types_string,
    data_hub_instance = window?.data_hub
) {
    if (!form_container || !prefill_input_selector) { return; }

    if (form_container instanceof HTMLElement || form_container instanceof Document || form_container instanceof ShadowRoot) {
        form_container.querySelectorAll(prefill_input_selector).forEach(field => {
            field.classList.add('input-loading');
            if (field.tagName === 'SELECT') {
                field.disabled = true;
            } else if (field.tagName === 'INPUT' || field.tagName === 'TEXTAREA') {
                field.readOnly = true;
                field.classList.add('input-loading-bkg-image');
            }
        });

    } else {
        data_hub_instance?.error_function?.('Invalid form_container provided:' + form_container);
    }
}

/**
 * Removes the loading state from form inputs elements
 * @param {HTMLElement} form_container the dom element to find the inputs
 * @param {string} prefill_input_selector the selector for the input elements to remove loading state
 * @param {Object} data_hub_instance the instance of the data hub
 * @returns
 */
function remove_form_input_loading_state(
    form_container = document,
    prefill_input_selector = form_input_types_string,
    data_hub_instance = window?.data_hub
) {
    //form_input_types_string
    if (!form_container || !prefill_input_selector) { return; }
    if (form_container instanceof HTMLElement || form_container instanceof Document || form_container instanceof ShadowRoot) {
        form_container.querySelectorAll(prefill_input_selector).forEach(field => {
            field.classList.remove('input-loading');
            // look at what type of field it is, and make them clickable / changeable
            if (field.tagName === 'SELECT') {
                field.disabled = false;
            } else if (field.tagName === 'INPUT' || field.tagName === 'TEXTAREA') {
                field.readOnly = false;
                field.classList.remove('input-loading-bkg-image');
            }
        });
    } else {
        data_hub_instance?.error_function?.('Invalid form_container provided:' + form_container);
    }
}

// TODO need to pass through cors iframe and integrate with the opt out saas
/**
 * Clears the JWT cookie and partitioned cookies.
 * Makes a POST request to endpoint
 * TODO will need to pass through cors iframe
 * @param {string} forms_domain - The domain to clear the cookies from.
 * @param {function} error_function - The function to call in case of an error.
 * @returns {Promise<void>}
 */
async function clear_jwt_cookie(forms_domain = window?.data_hub?.forms_domain, error_function = window?.data_hub.error_function) {
    try {
        if (!forms_domain) {
            return error_function('Missing forms_domain at clear_jwt_cookie');
        }
        const requestDomain = `${forms_domain}/api/get/clear_jwt_and_partitioned_cookies`;
        const response = await fetch_with_timeout(requestDomain, {
            method: 'GET',
            credentials: 'include',
        }, 5000)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response;
            })
            .catch((error) => { // Changed .error() to .catch()
                error_function('Error at clear_jwt_cookie: ' + error);
            });
    } catch (e) {
        error_function('Error at clear_jwt_cookie: ' + e);
    }
}

/**
 * Resets the form fields to their default state.
 * @param {Event} event - The event object.
 * @param {HTMLElement} form_container - The form container element.
 * @param {string} prefill_input_selector - The selector for the input elements to reset.
 * @param {string} reset_button_selector - The selector for the reset button.
 * @param {Object} data_hub_instance - The instance of the data hub.
 * @param {boolean} reset_data_hub_user_data - Whether to reset the data hub user data.
 * @returns {void}
 */
function reset_form(
    event,
    form_container,
    prefill_input_selector = '[data-allow-data-hub-prefill="true"]',
    reset_button_selector = '.data-hub-reset-form',
    data_hub_instance = window?.data_hub,
    reset_data_hub_user_data = true
) {
    event.preventDefault();

    const elements = form_container.querySelectorAll(prefill_input_selector);

    Array.from(elements).forEach(element => {
        if (['checkbox', 'radio'].includes(element.type)) {
            element.checked = false;
        } else if (['text', 'textarea', 'email', 'tel', 'password', 'url', 'hidden', 'number', 'date', 'datetime-local', 'month', 'time', 'week'].includes(element.type)) {
            element.value = '';
        } else if (element.tagName === 'SELECT') {
            element.value = element.options[0].value;
        }
    });

    const reset_button = form_container.querySelector(reset_button_selector);
    if (reset_button) {
        reset_button.classList.add('hidden');
    }

    if (reset_data_hub_user_data && typeof data_hub_instance !== 'undefined') {
        data_hub_instance.user_data = {};
    }
    window.data_hub_user_data = {};
}

/**
 * Checks if the form has user data and shows the reset button if it does.
 * @param {HTMLElement} form_container - The form container element.
 * @param {string} reset_button_selector - The selector for the reset button.
 * @param {string} prefill_input_selector - The selector for the input elements to reset.
 * @param {Object} data_hub_instance - The instance of the data hub.
 * @param {boolean} reset_data_hub_user_data - Whether to reset the data hub user data.
 * @returns {void}
 */
function if_has_user_data_show_reset_button(
    form_container,
    reset_button_selector = '.data-hub-reset-form',
    prefill_input_selector = '[data-allow-data-hub-prefill="true"]',
    data_hub_instance = window?.data_hub || {},
    reset_data_hub_user_data = true
) {
    const reset_button = form_container.querySelector(reset_button_selector);
    if (reset_button) {
        reset_button.onclick = async (event) => {
            event.preventDefault();
            reset_form(
                event,
                form_container,
                prefill_input_selector,
                reset_button_selector,
                data_hub_instance,
                reset_data_hub_user_data
            );
            await clear_jwt_cookie(data_hub_instance?.forms_domain, data_hub_instance?.error_function);
        };

        reset_button.classList.remove('hidden');
    }

}

/**
 * Helper to fill in form element with data based on element type
 * @param {HTMLElement} element - The form element to fill.
 * @param {string} key - The key to use for the value.
 * @param {string} value - The value to fill in.
 * @param {Object} data_hub_instance - The instance of the data hub.
 * @returns {void}
 */
function fill_element_with_data(element, key, value, data_hub_instance) {
    if (element.tagName === 'INPUT') {
        if (['checkbox', 'radio'].includes(element.type)) {
            element.checked = parse_boolean_falsy_types(value);
        } else if (['text', 'email', 'tel', 'password', 'hidden', 'date', 'datetime-local', 'month', 'time', 'week'].includes(element.type)) {
            element.value = value;
        } else if (element.type === 'url') {
            is_valid_url(value) ? element.value = value : data_hub_instance?.error_function?.('prefill_form invalid url: ' + key + ' ' + value, null);
        } else if (element.type === 'number') {
            element.value = parseFloat(value);
        } else {
            data_hub_instance?.error_function?.('prefill_form non-form element: ' + element.tagName + ' ' + key + ' ' + value, null);

        }
    } else if (element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
        element.value = value;
    } else {
        data_hub_instance?.error_function?.('prefill_form non-form element: ' + element.tagName + ' ' + key + ' ' + value, null);
    }
}

/**
 * Re pre-fills all form input, checkbox etc elements
 * @param {Object} user_data - The user data object used to prefill data.
 * @param {string} prefill_input_selector - The selector for the input elements to prefill.
 * @param {Object} data_hub_instance - The instance of the data hub.
 */
function re_prefill_all_forms(
    user_data = window?.data_hub?.user_data || {},
    prefill_input_selector = '[data-allow-data-hub-prefill="true"]',
    data_hub_instance = window?.data_hub
) {
    // Loop through forms prefilled by this instance
    if (data_hub_instance?.prefilled_forms) {
        data_hub_instance.prefilled_forms.forEach((form_container) => {
            prefill_form(form_container, user_data, prefill_input_selector, undefined, undefined, true, data_hub_instance);
        });
    }
}

/**
 * Prefills the form with user data.
 * @param {HTMLElement} form_container - The form container element.
 * @param {Object} user_data - The user data object used to prefill data.
 * @param {string} prefill_input_selector - The selector for the input elements to prefill.
 * @param {string} reset_button_selector - The selector for the reset button.
 * @param {string} form_id - The ID of the form.
 * @param {boolean} allow_re_prefill - Whether to allow re-prefilling of the form.
 * @param {Object} data_hub_instance - The instance of the data hub.
 * @returns {void}
 */
function prefill_form(
    form_container,
    user_data = window?.data_hub?.user_data || {},
    prefill_input_selector = '[data-allow-data-hub-prefill="true"]',
    reset_button_selector = '.data-hub-reset-form',
    form_id = null,
    allow_re_prefill = false,
    data_hub_instance = window?.data_hub // 👈 Instance parameter
) {
    // Check if the form has already been prefilled
    if (!allow_re_prefill && data_hub_instance?.prefilled_forms?.has(form_container)) {
        return;
    }

    // Select all prefillable elements in the form container
    const elements = form_container.querySelectorAll(prefill_input_selector);
    if (!elements.length) {
        return;
    }

    let found_at_least_one_prefill = false;

    // Prefill each element if data is available
    elements.forEach(element => {
        const key = element.id || element.name;

        if (user_data && user_data.hasOwnProperty(key)) {
            const value = user_data[key];
            if (value !== null && value !== '') {
                found_at_least_one_prefill = true;
                // Skip element type tel will bind with tel intl input functions
                if (element.type === 'tel') {
                    return;
                }
                fill_element_with_data(element, key, value, data_hub_instance);
            }
        }
    });

    // Mark the form as prefilled in the Set
    // defined in datahub as this.data_hub_prefilled_forms = new Set();
    if (data_hub_instance) {
        // check if  data_hub_instance.prefilled_forms exists and is a set. if not create one
        if (!data_hub_instance.prefilled_forms) {
            data_hub_instance.prefilled_forms = new Set();
        }
        data_hub_instance.prefilled_forms.add(form_container);
    }

    // Show reset button if at least one field was prefilled
    if (found_at_least_one_prefill) {
        if_has_user_data_show_reset_button(
            form_container,
            reset_button_selector,
            prefill_input_selector,
            data_hub_instance,
            true
        );
    }

    prefill_refill_tel_inputs(user_data, form_container, data_hub_instance?.error_function, data_hub_instance);

    // Remove loading states from form fields
    remove_form_input_loading_state(form_container, prefill_input_selector);
    remove_form_loading_overlay(form_container);

}

/**
 * Clears form loading states and returns an error.
 * @param {HTMLElement} form_container - The form container element.
 * @param {string} [internal_error_message] - Internal error message.
 * @param {string} [external_error_message] - Message to display to the user.
 * @param {Object} [data_hub_instance] - The instance of the data hub.
 * @returns {void}
 */
function return_error_clear_form_loading_state(
    form_container,
    internal_error_message = 'Error submitting form data',
    external_error_message = 'Error submitting your request. Refresh page to try again or contact support.',
    data_hub_instance = window?.data_hub,
) {
    remove_submit_button_loading_state(form_container, data_hub_instance?.submit_button_selector);
    remove_form_loading_overlay(form_container, data_hub_instance?.loading_overlay_selector);
    remove_form_input_loading_state(form_container);

    return data_hub_instance?.error_function?.(internal_error_message, external_error_message);
}

/**
 * Hides specified elements on form submission.
 * @param {string|HTMLElement|NodeList|Array} [selector=data_hub.hide_on_submit_selector] - Selector or element(s) to hide.
 * @param {HTMLElement|null} [form_container=null] - The form container element.
 */
function hide_on_submit(selector, form_container = null) {
    let hide_on_submit_elements = null;

    if (typeof selector === 'string') {
        if (form_container instanceof HTMLElement) {
            hide_on_submit_elements = form_container.querySelectorAll(selector);
        } else {
            hide_on_submit_elements = document.querySelectorAll(selector);
        }
    } else if (selector instanceof HTMLElement) {
        hide_on_submit_elements = [selector];
    } else if (selector instanceof NodeList || Array.isArray(selector)) {
        hide_on_submit_elements = selector;
    }

    if (hide_on_submit_elements && hide_on_submit_elements.length > 0) {
        hide_on_submit_elements.forEach(element => {
            element.classList.add('hidden');
        });
    }
}

export {

    add_form_input_loading_state,
    remove_form_input_loading_state,

    add_form_loading_overlay,
    remove_form_loading_overlay,

    add_submit_button_loading_state,
    remove_submit_button_loading_state,

    prefill_form,
    re_prefill_all_forms,

    return_error_clear_form_loading_state,

    hide_on_submit
}