import { subscriptions_preferences_form } from './forms/subscriptions_preferences_form';
import { data_hub_class } from './forms/data_hub';
import { data_hub_form } from './forms/data_hub_forms';
// Import the custom element so it registers automatically.
import './forms/data_hub_html_element';
import { poll_with_retry } from './helpers/index';
import { bind_all_tel_inputs_intl } from './tel/telephone_sms_inputs';
import { inject_tailwind_css } from './forms/html_element_helpers/form_styling';

const DataHub = {
  data_hub_class,
  data_hub_form,
  subscriptions_preferences_form,
  bind_all_tel_inputs_intl,
  inject_tailwind_css,
  _data_hub_loading: false,
  get data_hub_loading() {
    return this._data_hub_loading;
  },
  set data_hub_loading(value) {
    this._data_hub_loading = value;
  },
  // Wait until data_hub is ready: when DataHub.data_hub_loading is false and window.data_hub is defined.
  wait_for_data_hub: async (interval = 100, timeout = 5000) => {
    try {
      await poll_with_retry(
        () => !DataHub.data_hub_loading && !!window.data_hub,
        { interval, timeout }
      );
      return window.data_hub;
    } catch (error) {
      console.error(`DataHub instance did not load within ${timeout / 1000} seconds:`, error);
      throw error;
    }
  },
  version: 0.17
};

// Expose DataHub on window for global use.
if (typeof window !== 'undefined') {
  window.DataHub = DataHub;
}

// Example function to wait for DataHub to be ready. 5s timeout.
// DataHub.wait_for_data_hub()
//   .then(() => console.log('DataHub is ready main'))
//   .catch((error) => console.error('Error during DataHub initialization at main 45:', error));

export default DataHub;