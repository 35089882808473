// src/forms/html_element_helpers/form_generators.js
import DOMPurify from 'dompurify';

import {
    cell_phone_names,
    user_data_form_field_types,
    input_number_type_min_max_by_name_key,
    html_input_autocomplete_types_by_user_data_key_name,
    user_data_form_field_placeholders,
    at_least_one_required_form_identifier_fields
} from '../../helpers/static_vars';

import { fetch_with_timeout, processHandlebarsTemplate } from '../../helpers';

import { generate_sms_optin_intl_tel } from '../../tel/telephone_sms_inputs';

import {
    get_cached_actblue_token,
    actblue_trigger_donation,
    actblue_is_configured,
    create_actblue_link_p_element,
} from '../../helpers/actblue_integration';

import { get_tailwind_size_class, get_field_width_fraction } from './form_styling';

/**
 * Generate an input field based on the provided field object.
 * The field object should contain the following properties:
 * - name: The name of the field.
 * - size: The size class for the field (default is 'w-full').
 * - required: A boolean indicating if the field is required.
 * - type: The type of the field (default is 'text').
 * - placeholder: The placeholder text for the field.
 * - autocomplete: The autocomplete attribute for the field (default is '').
 * - min: The minimum value for number fields (optional).
 * - max: The maximum value for number fields (optional).
 * @param {Object} field - The field object containing properties for the input field.
 * @param {Boolean} allow_prefill 
 * @returns {HTMLDivElement} The div element containing the input field.
 */
function render_input_field(field, allow_prefill) {
    const input_div = document.createElement('div');
    input_div.classList.add('px-2', field.size, 'add-el-sm-w-full', 'add-el-sm-mb-4');

    const input = document.createElement('input');
    input.type = user_data_form_field_types[field.name] || 'text';


    // if type is number, check input_number_type_min_max_by_name_key[keyname] for min and max values
    if (input.type === 'number') {
        const { min, max } = input_number_type_min_max_by_name_key[field.name] || {};
        if (min) {
            input.min = min;
        }
        if (max) {
            input.max = max;
        }
    }

    input.name = field.name;
    if (allow_prefill) {
        input.dataset.allowDataHubPrefill = 'true';
    }
    input.autocomplete = html_input_autocomplete_types_by_user_data_key_name[field.name] || '';
    input.placeholder = user_data_form_field_placeholders[field.name] || '';
    input.classList.add(
        'p-2',
        'border',
        'border-grey-brand-color-400',
        'rounded-md',
        'w-full'
    );

    if (
        field.required === true ||
        (typeof field.required === 'string' &&
            field.required.toLowerCase() === 'true')
    ) {
        input.required = true;
    }
    input_div.appendChild(input);
    return input_div;
}


/**
 * Create form fields (except the submit button and SMS opt‐in block).
 * If SMS opt–in is enabled, we skip rendering any phone field here.
 *
 * @param {Array} fields - the parsed field objects. {name: string, size: string, required: boolean}
 * @param {boolean} allow_prefill - allow auto–prefill if available.
 * @param {Set<string>} set_of_form_inputs_added - tracks which fields have been added.
 * @param {boolean} show_sms_optin - if true, we’ll render the SMS opt–in block separately.
 * @param {string} form_submit_button_text - the text for the submit button.
 * @returns {Array<HTMLDivElement>} an array of div elements containing the form fields.
 */
function create_form_fields(
    fields,
    allow_prefill,
    set_of_form_inputs_added,
    show_sms_optin,
    form_submit_button_text = "Submit"
) {
    let this_submit_button = null;
    let rows = [];
    let current_row = document.createElement('div');
    current_row.classList.add('flex', 'flex-wrap', '-mx-2', 'mb-4', 'add-el-sm-mb-0');
    let current_row_width = 0;
    rows.push(current_row);

    fields.forEach((field) => {
        // Skip submit or sms_optin (handled separately).
        if (field.name === 'sms_optin') return;

        // If SMS opt‑in is enabled, and this field name is a phone, skip dont add
        if (show_sms_optin && cell_phone_names.includes(field.name)) return;

        // TODO filter out many phone fields here, especially if sms_optin? it would remove cell_phone & phoneNumber
        // but not primary_cell_phone phone_number

        set_of_form_inputs_added.add(field.name);
        const field_width_fraction = get_field_width_fraction(field.size);
        if (current_row_width + field_width_fraction > 1) {
            current_row = document.createElement('div');
            current_row.classList.add('flex', 'flex-wrap', '-mx-2', 'mb-4', 'add-el-sm-mb-0');
            rows.push(current_row);
            current_row_width = 0;
        }

        if (field.name === 'submit' || field.name === 'submit_button') {
            const submit_button = create_submit_button(form_submit_button_text, field.size);
            current_row.appendChild(submit_button);
            current_row_width += field_width_fraction;
            this_submit_button = submit_button;
            return;
        }

        const input_div = render_input_field(field, allow_prefill);
        current_row.appendChild(input_div);
        current_row_width += field_width_fraction;
    });

    return { rows, this_submit_button };
}

/**
 * Adds an image element to the form container.
 * @param {string} image_url - The URL of the image to be added.
 * @param {string} image_alt - The alt text for the image.
 * @param {HTMLElement} first_form_container - The container to which the image will be appended.
 * @param {function} error_function - Optional error handling function.
 * @returns {Promise<void>}
 */
async function add_image_element(image_url, image_alt, first_form_container, error_function = window?.data_hub?.error_function) {
    if (image_url && image_url.startsWith('https://')) {
        const image_element = document.createElement('img');
        image_element.src = image_url;
        image_element.alt = image_alt || 'Petition Image';
        image_element.classList.add('w-full', 'mb-4');
        first_form_container.appendChild(image_element);
    } else {
        error_function('Invalid image URL must start with https:' + image_url);
    }
}

/**
 * Parses a string of user input field names and returns an array of field objects.
 *
 * @param {string} user_fields - A comma-separated string of field definitions. Each field definition can include a name, 
 *                               and optionally 'required' or a size class.
 * @returns {Array<{name: string, size: string, required: boolean}>} An array of field objects, each containing:
 *          - name: The name of the field.
 *          - size: The size class for the field (default is 'w-full').
 *          - required: A boolean indicating if the field is required.
 */
function parse_user_input_field_names(user_fields) {
    const field_list = user_fields.split(',');
    if (!field_list) {
        return [{ 'name': 'email', 'size': 'w-full', 'required': true }];
    }
    return field_list.map(field => {
        const parts = field.split(':');
        const name = parts[0].trim();
        let required = false;
        let size = null;
        if (parts.length > 1) {
            for (let i = 1; i < parts.length; i++) {
                const part = parts[i].trim().toLowerCase();
                if (part === 'required' || part === 'true') {
                    required = true;
                } else if (i === 1 && part !== 'required') {
                    size = get_tailwind_size_class(parts[1].trim());
                }
            }
        }
        if (!size) {
            size = 'w-full';
        }
        return { name, size, required };
    });
}

/**
 * Creates a submit button element for the form.
 * @param {string} form_submit_button_text - The text to be displayed on the button.
 * @param {string} width - The width class for the button (default is 'w-full').
 * @returns {HTMLElement} The wrapper div containing the submit button.
 */
function create_submit_button(form_submit_button_text, width = 'w-full') {
    const unique_submit_button_id = Math.random().toString(36).substring(7);
    const wrap_div = document.createElement('div');
    wrap_div.classList.add(width, 'add-el-sm-w-full');//, 'add-el-sm-mb-4'
    const submit_button = document.createElement('button');
    submit_button.name = 'submit_button';
    submit_button.id = unique_submit_button_id;
    submit_button.classList.add(
        'w-full',
        'data-hub-submit-form', 'px-6', 'py-2',
        'bg-primary-brand-color', 'text-white', 'rounded-md',
        'hover:bg-primary-brand-color-700', 'focus:bg-primary-brand-color-700',
        'active:bg-primary-brand-color-700', 'transition-all', 'duration-100',
        'ease-in-out', 'text-center'
    );
    submit_button.innerHTML = `
    <span class="button-text">${form_submit_button_text}</span>
    <div class="loader-button hidden flex justify-center items-center text-center m-auto"></div>
  `;
    wrap_div.appendChild(submit_button);
    return wrap_div;
}

function create_form_title(first_form_container, form_title) {
    if (!form_title) return;
    const form_title_div = document.createElement('div');
    form_title_div.classList.add('data_hub_form_title');
    const form_title_h1 = document.createElement('h1');
    form_title_h1.classList.add('text-3xl', 'font-bold', 'text-left', 'text-primary-brand-color');
    form_title_h1.innerText = form_title;

    // Aira labelledby
    form_title_div.setAttribute('aria-labelledby', form_title);

    form_title_div.appendChild(form_title_h1);
    first_form_container.appendChild(form_title_div);
}

function create_form_description(first_form_container, form_description) {
    if (!form_description) return;
    const form_description_div = document.createElement('div');
    form_description_div.classList.add('data_hub_form_description', 'text-md', 'text-left', 'mb-4');

    // add aria for readers
    form_description_div.setAttribute('aria-describedby', form_description);

    form_description_div.innerHTML = form_description;
    first_form_container.appendChild(form_description_div);
}

// Covers entire form on submit
function create_loading_div() {
    const overlay_div = document.createElement('div');
    overlay_div.classList.add(
        'data-hub-loading-overlay',
        'absolute', 'inset-0', 'bg-white', 'bg-opacity-50',
        'flex', 'justify-center', 'items-center',
        'hidden', 'z-50', 'hide-on-return-function'
    );
    const loader_div = document.createElement('div');
    loader_div.classList.add('loader');
    overlay_div.appendChild(loader_div);
    return overlay_div
}

// -----------------------------------------------------------------------------
//  THANK-YOU / ERROR UI
// -----------------------------------------------------------------------------

export const render_custom_thank_you_html = (
    success_settings,
    success_div_container,
    user_data = window?.data_hub?.user_data || {},
    merged_metadata =  window?.data_hub?.page_metadata || {},
    error_function = window?.data_hub?.error_function,
) => {
    const custom_success_div_contents = document.createElement('div');
    custom_success_div_contents.classList.add(
        'custom_html_success_div', 'flex', 'flex-col', 'justify-center',
        'items-center', 'min-h-[200px]', 'text-center', 'p-4'
    );
    const handlebars_object = { user_data, merged_metadata };

    if (/^[a-zA-Z0-9 ]+$/.test(success_settings)) {
        const success_text_container = document.createElement('div');
        success_text_container.classList.add('text-lg', 'font-bold', 'text-center');
        const success_text = document.createElement('p');
        success_text.innerText = processHandlebarsTemplate(success_settings, handlebars_object);
        success_text_container.appendChild(success_text);
        custom_success_div_contents.appendChild(success_text_container);
    } else {
        const cleaned_html = DOMPurify.sanitize(
            processHandlebarsTemplate(success_settings, handlebars_object),
            { USE_PROFILES: { html: true } }
        );
        if (cleaned_html) {
            custom_success_div_contents.innerHTML = cleaned_html;
        } else {
            error_function('Error rendering custom HTML success message. Bad html?', null);
            custom_success_div_contents.innerHTML = '<h1>Thank you!</h1>';
        }
    }
    success_div_container.appendChild(custom_success_div_contents);
};



export const insert_actblue_on_failure_div = (
    message = null, target_div_container, error_function = window?.data_hub?.error_function, data_hub_instance = window?.data_hub
) => {
    if (!target_div_container) {
        return error_function('No target_div_container provided to insert ActBlue failure div', null);
    }
    target_div_container.dataset.donationSuccessful = 'false';
    if (message) {
        error_function('ActBlue error: ' + message, null);
    }

    const failure_div = document.createElement('div');
    failure_div.classList.add(
        'actblue-failure-div', 'show-on-actblue-failure',
        'flex', 'justify-center', 'items-center',
        'min-h-[200px]', 'text-center', 'p-4'
    );
    const inner_div = document.createElement('div');
    inner_div.classList.add('actblue-failure-div-inner', 'text-gray-600', 'font-semibold');

    const content_div = document.createElement('div');
    content_div.classList.add('actblue-failure-div-content', 'text-red-500');

    const text_div = document.createElement('div');
    text_div.classList.add('actblue-failure-div-text', 'text-center', 'text-lg', 'font-semibold');
    text_div.textContent = '😞 There was an error processing your donation.';
    inner_div.appendChild(content_div);
    failure_div.appendChild(inner_div);

    // Optionally re-inject donation buttons
    const existing_actblue_buttons = target_div_container.querySelectorAll('.actblue-amount-buttons-container');
    if (!existing_actblue_buttons || existing_actblue_buttons.length === 0) {
        const ab_buttons = create_html_amount_buttons({
            amounts: [],
            refcodes: window?.data_hub?.page_metadata?.refcodes || {}, // or some fallback
            donor_data: {}, // fallback
            actblue_token: get_cached_actblue_token(),
            target_container: failure_div,
            actblue_page_url: null,
            error_function,
            data_hub_instance: window?.data_hub
        });
        failure_div.appendChild(ab_buttons);
    }
    target_div_container.appendChild(failure_div);
};

export const create_append_thank_you_message = (contribution, target_container) => {
    const thank_you_container = document.createElement('div');
    thank_you_container.classList.add(
        'actblue-success-div', 'show-on-actblue-success',
        'flex', 'justify-center', 'items-center',
        'min-h-[200px]', 'text-center', 'p-4'
    );
    const thank_you_inner = document.createElement('div');
    thank_you_inner.classList.add(
        'actblue-success-div-inner', 'text-primary-brand-color',
        'font-bold', 'text-lg', 'actblue-success-div-text'
    );

    const firstname = contribution?.donor?.firstname || '';
    if (firstname && firstname !== '') {
        thank_you_inner.textContent = `👍 Thank you for your support, ${firstname}! 😊`;
    } else {
        thank_you_inner.textContent = '👍 Thank you for your support! 😊';
    }
    thank_you_container.appendChild(thank_you_inner);
    target_container.appendChild(thank_you_container);
};

export const donation_modal_in_progress_spinner = (success_div_container) => {
    const ab_in_progress_container = document.createElement('div');
    ab_in_progress_container.classList.add(
        'flex', 'justify-center', 'items-center', 'text-center',
        'p-2', 'hide-on-actblue-success', 'donation-in-progress'
    );

    const spinner = document.createElement('div');
    spinner.classList.add('spinner', 'spinner-border', 'text-primary');
    spinner.setAttribute('role', 'status');

    const status_text = document.createElement('span');
    status_text.innerHTML = '<span>Making your ActBlue donation...</span>';

    ab_in_progress_container.appendChild(spinner);
    ab_in_progress_container.appendChild(status_text);

    success_div_container.appendChild(ab_in_progress_container);
};

function create_success_div() {
    const success_div_container = document.createElement('div');
    success_div_container.classList.add('show-on-return-function', 'hidden', 'data_hub_form_success');
    return success_div_container;
}

/**
 * Create basic buttons that open actblue.js donation modal
 */
export const create_html_amount_buttons = ({
    amounts = [5, 10, 20, 50, 100],
    refcodes = window?.data_hub?.page_metadata?.refcodes || {},
    donor_data = {},
    actblue_token = get_cached_actblue_token(),
    target_container = null,
    actblue_page_url = null,
    title_text = 'Make a contribution{{#if user_data.first_name}} {{user_data.first_name}}{{/if}}:',
    error_function = window?.data_hub?.error_function,
    data_hub_instance = window?.data_hub,
    upsell_text,
}) => {
    // Create the main container element
    const container = document.createElement('div');
    container.classList.add(
        'actblue-amount-buttons-container',
        'hide-on-actblue-success',
        'flex', 'flex-wrap', 'gap-2', 'justify-center', 'items-center',
        'min-h-[150px]', 'p-4'
    );

    if (!upsell_text) {
        upsell_text = `Support progressive and independent news.
        We don't have billionaire tech monopolists or corporate backers telling us what to print.
        We rely on readers like you.`;
    }

    // Create upsell container and add title/upsell text
    const upsellContainer = document.createElement('div');

    const handlebarsContext = {
        user_data: { ...data_hub_instance?.user_data || {}, ...donor_data || {}, ... window?.data_hub_user_data || {} },
        metadata: data_hub_instance?.page_metadata || {}
    };

    const donation_ask_wrapper = document.createElement('div');
    donation_ask_wrapper.classList.add('flex', 'flex-wrap', 'justify-center', 'w-full', 'text-center');

    // If a title is provided, add a title block
    if (title_text) {
        const donation_title = document.createElement('div');
        donation_title.classList.add('text-primary-brand-color', 'text-2xl', 'font-bold');

        let title_text_processed = 'Make a contribution';
        try {
            title_text_processed = processHandlebarsTemplate(title_text, handlebarsContext);
        } catch (e) {
            error_function('Error processing Handlebars template for title:' + e);
            title_text_processed = 'Make a contribution';
        }

        donation_title.textContent = title_text_processed;
        donation_ask_wrapper.appendChild(donation_title);
        upsellContainer.appendChild(donation_ask_wrapper);
    }

    const text_and_logo_container = document.createElement('div');
    text_and_logo_container.classList.add('flex', 'flex-wrap', 'justify-center', 'items-center', 'w-full', 'p-2');

    // Add an ActBlue logo image
    const imageContainer = document.createElement('div');
    imageContainer.classList.add('flex', 'justify-center', 'items-center', 'w-full', 'sm:max-w-[150px]', 'mx-auto', 'p-4');
    const actblueLogo = document.createElement('img');
    actblueLogo.src = 'https://qtera.org/images/ActBlue_logo.svg';
    actblueLogo.alt = 'ActBlue Logo';
    actblueLogo.classList.add('w-full', 'h-auto');
    imageContainer.appendChild(actblueLogo);
    text_and_logo_container.appendChild(imageContainer);

    // If upsell text is provided, add it
    if (upsell_text) {
        const upsell_text_wrapper = document.createElement('div');
        upsell_text_wrapper.classList.add('flex', 'flex-wrap', 'justify-left', 'w-full', 'text-left', 'max-w-[720px]', 'mt-4');
        const upsell_text_div = document.createElement('div');
        upsell_text_div.classList.add('text-gray-600', 'text-md');

        let upsell_text_processed = upsell_text;
        try {
            upsell_text_processed = processHandlebarsTemplate(upsell_text, handlebarsContext);
        } catch (e) {
            error_function('Error processing Handlebars template for upsell text:' + e);
            upsell_text_processed = upsell_text;
        }

        upsell_text_div.textContent = upsell_text_processed;
        upsell_text_wrapper.appendChild(upsell_text_div);
        text_and_logo_container.appendChild(upsell_text_wrapper);
    }

    upsellContainer.appendChild(text_and_logo_container);
    container.appendChild(upsellContainer);

    // If the ActBlue library is not configured, return a fallback direct link
    if (!actblue_is_configured()) {
        const { actblue_link_p } = create_actblue_link_p_element(actblue_page_url);
        container.appendChild(actblue_link_p);
        return container;
    }

    // Validate the amounts array; if invalid, fall back to default_ab_amounts
    if (!Array.isArray(amounts) || !amounts.every((amt) => Number.isInteger(amt))) {
        amounts = [5, 10, 20, 50, 100];
    }

    const buttonsFlexDiv = document.createElement('div');
    buttonsFlexDiv.classList.add('flex', 'flex-wrap', 'justify-center', 'items-center', 'gap-2');

    // Build and append a button for each donation amount
    amounts.forEach(amount => {
        const button = document.createElement('button');
        button.classList.add(
            'px-6', 'py-2', 'bg-primary-brand-color', 'text-white', 'rounded-md',
            'hover:bg-primary-brand-color-700', 'focus:bg-primary-brand-color-700',
            'active:bg-primary-brand-color-700', 'transition-all', 'duration-100',
            'ease-in-out', 'text-center', 'cursor-pointer'
        );
        button.textContent = `Donate $${amount}`;
        button.addEventListener('click', () => {
            actblue_trigger_donation({
                amount: amount * 100,
                target_container_for_on_complete: target_container,
                actblue_token,
                error_function,
                data_hub_instance
            });
        });
        buttonsFlexDiv.appendChild(button);
    });

    // Create a block for custom (other) donation amounts
    const customAmountContainer = document.createElement('div');
    customAmountContainer.classList.add('relative', 'flex', 'items-center', 'justify-center');

    const customAmountInput = document.createElement('input');
    customAmountInput.type = 'number';
    customAmountInput.placeholder = 'Other';
    // Matching the preset buttons' vertical/horizontal size:
    customAmountInput.classList.add(
        //'h-[40px]',           // ensures consistent height
        'px-6', 'py-2',      // similar padding as the preset buttons
        'border', 'border-gray-300', 'rounded-md',
        'focus:outline-none', 'focus:ring-2', 'focus:ring-primary-brand-color',
        'appearance-none'     // helps remove default spinners in some browsers
    );

    const customButton = document.createElement('button');
    // Also match the height:
    customButton.classList.add(
        'ml-2', 'bg-primary-brand-color', 'text-white', 'rounded-md',
        'px-4', 'py-2',// 'h-[40px]',       // ensure it aligns with the input
        'hover:bg-primary-brand-color-700', 'focus:bg-primary-brand-color-700',
        'transition-all', 'duration-100', 'ease-in-out'
    );
    customButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
    `;
    // Keep the absolute positioning if you want the arrow overlapping the input:
    customButton.style.position = 'absolute';
    customButton.style.right = '5px';
    customButton.style.top = '50%';
    customButton.style.transform = 'translateY(-50%)';

    customButton.addEventListener('click', () => {
        const customAmount = parseFloat(customAmountInput.value);
        if (customAmount && !isNaN(customAmount) && customAmount > 0) {
            actblue_trigger_donation({
                amount: customAmount * 100,
                target_container_for_on_complete: target_container,
                actblue_token,
                error_function,
                data_hub_instance
            });
        } else {
            // Instead of defaulting to 10, show an error and stop
            error_function('Invalid custom donation amount: ' + customAmount, 'Please enter a valid donation amount.');
            return;
        }
    });

    customAmountContainer.appendChild(customAmountInput);
    customAmountContainer.appendChild(customButton);
    buttonsFlexDiv.appendChild(customAmountContainer);

    container.appendChild(buttonsFlexDiv);

    return container;
};

/**
 * Injects custom CSS into the provided element's shadow DOM.
 * @param {string} cssUrl - The URL of the CSS file to inject.
 * @param {DomElement} target - The DOM element to inject the CSS into. For htmlElement is shadowRoot.
 */
export const inject_custom_css = (cssUrl, target = element.shadowRoot, error_function = window?.data_hub?.error_function) => {
    try {
        if (!cssUrl) {
            return error_function('No CSS URL provided', null);
        }

        if (!cssUrl.endsWith('.css') || !cssUrl.startsWith('https://')) {
            return error_function('Invalid CSS URL provided', null);
        }
        fetch_with_timeout(cssUrl, { timeout: 3000 })
            .then((response) => {
                if (!response.ok || !response.headers.get('content-type')?.includes('text/css')) {
                    throw new Error('Invalid CSS response'); // 👈 Prevent non-CSS content
                }
                return response.text();
            })
            .then((cssContent) => {
                const styleElement = document.createElement('style');
                styleElement.setAttribute('data-hub-css', '');
                styleElement.textContent = cssContent;
                if (target) {
                    target.appendChild(styleElement);
                } else {
                    return error_function('No target provided for CSS injection', null);
                }
            })
            .catch((error) => {
                return error_function('Error fetching or injecting custom CSS', error);
            });
    } catch (error) {
        return error_function('Error fetching or injecting custom CSS', error);
    }
};

function buildTextContent({ form_title, image_url, form_description, custom_form_html }) {
    // Create a container to hold the text content.
    const container = document.createElement('div');

    // Add image if provided.
    if (image_url) {
        add_image_element(image_url, form_title, container);
    }
    // Add description.
    create_form_description(container, form_description);
    // Add custom HTML if provided.
    if (custom_form_html) {
        const custom_html_div = document.createElement('div');
        const cleaned_form_html = DOMPurify.sanitize(custom_form_html, {
            USE_PROFILES: { html: true },
        });
        if (cleaned_form_html) {
            custom_html_div.innerHTML = cleaned_form_html;
            container.appendChild(custom_html_div);
        }
    }
    return container;
}

function buildFormContent({ user_fields, allow_prefill, form_submit_button_text, data_hub_instance, data_hub_form_element_container }) {

    const form = document.createElement('form');
    form.classList.add('data_hub_form');
    form.setAttribute('role', 'form');

    let set_of_form_inputs_added = new Set();
    let fields = parse_user_input_field_names(user_fields);
    if (!fields || fields.length === 0) {
        fields = [{ name: 'email', size: 'full', required: true }];
    }

    // Enable SMS opt‑in if declared
    let bool_show_sms_optin = false;
    if (fields.some(field => field.name === 'sms_optin')) {
        bool_show_sms_optin = true;
        // Remove any phone fields if SMS opt‑in is enabled. They will be added in the sms optin generator
        fields = fields.filter(field => field.name !== 'sms_optin' && !cell_phone_names.includes(field.name));
    }

    // Convert any "phoneNumber" to "cell_phone"
    fields.forEach(field => {
        if (field.name === 'phoneNumber') {
            field.name = 'cell_phone';
        }
    });

    const { rows: dom_form_elements, this_submit_button } = create_form_fields(
        fields,
        allow_prefill,
        set_of_form_inputs_added,
        bool_show_sms_optin,
        form_submit_button_text
    );

    // Append SMS opt‑in block if needed
    if (bool_show_sms_optin) {
        const sms_optin_block = generate_sms_optin_intl_tel(
            allow_prefill,
            set_of_form_inputs_added,
            form,
            data_hub_instance
        );
        if (sms_optin_block) {
            fields.push({ name: 'sms_optin', size: 'full' });
            form.appendChild(sms_optin_block);
        }
    }

    // Ensure at least one required identifier exists
    if (!fields.some(field => at_least_one_required_form_identifier_fields.includes(field.name))) {
        fields.push({ name: 'email', size: '3/4', required: true }, { name: 'submit', size: '1/4' });
    }

    // Look through input elements, ensure that at least one of our required user ID fields is marked required.
    let hasAtLeastOneRequiredField = false;
    dom_form_elements.forEach(row => {
        row.querySelectorAll('input').forEach(input => {
            if (at_least_one_required_form_identifier_fields.includes(input.name || input.id) && input.value.trim()) {
                hasAtLeastOneRequiredField = true;
            }
        });
    });

    // If there are none, set them all to required... Could go in order e.g. email first then exit. but that's double looping?
    if (!hasAtLeastOneRequiredField) {
        dom_form_elements.forEach(row => {
            row.querySelectorAll('input').forEach(input => {
                if (at_least_one_required_form_identifier_fields.includes(input.name || input.id)) {
                    input.required = true;
                }
            });
        });
    }

    // Append all form field rows
    dom_form_elements.forEach(row => form.appendChild(row));

    // If no submit button was added, add one
    let new_submit_button = null;
    if (!set_of_form_inputs_added.has('submit') && !set_of_form_inputs_added.has('submit_button')) {
        new_submit_button = create_submit_button(form_submit_button_text);
        form.appendChild(new_submit_button);
    }

    // Append a "Not you?" reset text
    const not_you_p = document.createElement('p');
    not_you_p.classList.add(
        'data-hub-reset-form',
        'text-sm',
        'text-center',
        'text-gray-700',
        'cursor-pointer',
        'hover:text-primary-brand-color',
        'hover:underline',
        'mt-2'
    );
    not_you_p.innerText = 'Not you? Click to reset.';
    form.appendChild(not_you_p);

    const submit_button_to_return = this_submit_button || new_submit_button || form.querySelector('button[name="submit_button"]') || null;

    // Wrap the form inside its container so it can be moved as a single unit
    return { form, submit_button: submit_button_to_return };
}

/**
 * Primary first parent function to create form + success container
 * Returns { success_div_container, first_form_container, form, submit_button }
 */
export const create_form_elements_and_inputs = (primary_form_settings) => {
    const {
      data_hub_form_element_container,
      form_title,
      form_description,
      user_fields,
      allow_prefill,
      form_submit_button_text,
      image_url,
      custom_form_html,
      data_hub_instance = window?.data_hub,
      alignSettings, // Optional: { side, textColumnWidthClass, formColumnWidthClass }
    } = primary_form_settings;
  
    // Main container for all content (title, text, form)
    const first_form_container = document.createElement('div');
    first_form_container.classList.add('hide-on-return-function');
  
    // Render the title (if provided) at full width.
    create_form_title(first_form_container, form_title);
  
    // Build the text content (image, description, custom HTML)
    const textDiv = buildTextContent({ form_title, image_url, form_description, custom_form_html });
    
    // Build the form
    const { form, submit_button } = buildFormContent({
      user_fields,
      allow_prefill,
      form_submit_button_text,
      data_hub_instance,
      data_hub_form_element_container
    });
  
    if (alignSettings) {
      // Create a flex container for two columns and add our responsive class.
      const wrapOuterFlexDiv = document.createElement('div');
      wrapOuterFlexDiv.classList.add('flex', 'flex-nowrap', 'w-full', 'gap-x-4', 'responsive-flex');
      
      // If textDiv has content, build two columns.
      if (textDiv.innerHTML.trim() !== "") {
        const leftDiv = document.createElement('div');
        leftDiv.classList.add('add-el-sm-w-full');
        const rightDiv = document.createElement('div');
        rightDiv.classList.add('add-el-sm-w-full');
  
        // Order based on alignSettings.side:
        if (alignSettings.side === 'left') {
          leftDiv.classList.add(alignSettings.textColumnWidthClass);
          leftDiv.appendChild(textDiv);
          
          leftDiv.classList.add('mt-4');
          rightDiv.classList.add(alignSettings.formColumnWidthClass, 'el-sm-mb-0');
          rightDiv.appendChild(form);
        } else {
          leftDiv.classList.add(alignSettings.formColumnWidthClass);
          leftDiv.appendChild(form);
          rightDiv.classList.add(alignSettings.textColumnWidthClass, 'el-sm-mb-0');
          rightDiv.appendChild(textDiv);
          rightDiv.classList.add('mt-4');
        }
        wrapOuterFlexDiv.appendChild(leftDiv);
        wrapOuterFlexDiv.appendChild(rightDiv);
      } else {
        // If there is no text content, show the form full width.
        const fullDiv = document.createElement('div');
        fullDiv.classList.add('w-full', 'px-0');
        fullDiv.appendChild(form);
        wrapOuterFlexDiv.appendChild(fullDiv);
      }
      first_form_container.appendChild(wrapOuterFlexDiv);
    } else {
      // For a stacked layout (no alignSettings), simply append text and then form.
      first_form_container.appendChild(textDiv);
      first_form_container.appendChild(form);
    }

    // TODO remove margin on the Not you? Click to reset.
  
    // Create the success and loading containers.
    const success_div_container = create_success_div();
    const overlay_div = create_loading_div(data_hub_form_element_container);
    first_form_container.appendChild(overlay_div);
  
    return {
      success_div_container,
      first_form_container,
      form,
      submit_button,
    };
  };  