// helpers/static_vars.js

// TODO can we move this into a settings file and build based on client in webpack.config.js?
const default_forms_domain = "https://signup.dailykos.com";//"https://action-two-dot-daily-kos-beta.uc.r.appspot.com"

const default_theme = {
    primary_brand_color: {
        100: "#fcf1e6",
        200: "#f8d6b4",
        300: "#f4bb82",
        400: "#f0a050",
        500: "#ec851e",
        600: "#ea7806",
        700: "#b55904",
        800: "#6c3502",
        900: "#241100",
    },
    contrast_brand_color: "#0678ea",
    highlight_brand_color: {
        100: "#fcfae6",
        600: "#ead306",
        900: "#fcfae6"
    },
    grey_brand_color: {
        200: "#F5F5F5",
        600: "#302E2E",
        800: "#131313"
    }
}

const default_selectors = {
    form_container_selector: '.data-hub-container',
    submit_button_selector: '.data-hub-submit-form',
    loading_overlay_selector: '.data-hub-loading-overlay',
    reset_button_selector: '.data-hub-reset-form',
    hide_on_submit_selector: '.hide-on-return-function',
    metadata_form_field_selector: '[data-hub-form-metadata-field="true"]',
}

const default_ab_amounts = [5, 10, 20, 50, 100];
const default_ab_amounts_string = "[5,10,20,50,100]";

const at_least_one_required_identifier_fields = [
    'email', 'work_email', 'cell_phone', 'phone_number', 'phoneNumber', 'iterable_user_uuid',
    'global_uuid', 'uuids', 'unknown_uuid'
];

const at_least_one_required_form_identifier_fields = [
    'email', 'work_email', 'cell_phone', 'phone_number', 'phoneNumber', 'work_phone_number', 'primary_cell_phone', 'primary_phone_number', 'landline',
];

const form_input_types_string = 'input, select, textarea, tel, email, url, password, date, datetime-local, month, time, week, number, checkbox, radio';

const allowed_iterable_h_hash_key_names = [
    'h_iterable_iterable_user_id',
    'h_iterable_email',
    'h_iterable_cell_phone',
    'h_iterable_phone_number',
    'h_iterable_phoneNumber',
    'h_iterable_global_uuid'
];

const allow_boolean_only = ['sms_optin', 'email_unsubscribe', 'is_eligible_for_express_lane', 'spanish'];

const empty_array_keys_to_skip = ['subscribedMessageTypeIds', 'userListIds', 'unsubscribedChannelIds', 'unsubscribedMessageTypeIds', 'emailListIds'];

// Must match action_two white_list_incoming_person_fields
const white_list_outgoing_person_fields = [
    'global_uuid', 'unknown_uuid',
    'uuids',
    'prefix', 'title', 'suffix',
    'sms_optin', 'email_unsubscribe',
    'email', 'work_email', 'emails',
    'first_name', 'last_name', 'full_name', 'first_initial', 'last_initial', 'middle_name', 'middle_initial',
    'phone_numbers',
    'cell_phone', 'phone_number', 'phoneNumber', 'primary_cell_phone', 'primary_phone_number', 'work_phone_number', 'landline',
    'address', 'address_2', 'city', 'state', 'state_code', 'zip', 'zip_4', 'county', 'country', 'country_code', 

    'occupation', 'employer', 'job_title',
    'employer_address', 'employer_address_2', 'employer_formatted_address', 'employer_city', 'employer_state', 'employer_state_code',
    'employer_zip', 'employer_zip_4', 'employer_country', 'employer_country_code',
    'gender',
    'birth_year', 'birth_month', 'birth_day', 'birth_date', 'locality', 'timezone',
    'subscribedMessageTypeIds', 'userListIds', 'unsubscribedChannelIds', 'unsubscribedMessageTypeIds', 'emailListIds',
    'spanish',
    'is_eligible_for_express_lane',
    'devices', 'auth_providers', 'social_profiles',
    'address_nlp', 'gpt_name',

    'acquisition_source',

]

const email_names = ['email', 'work_email'];
const cell_phone_names = ['cell_phone', 'phone_number', 'phoneNumber', 'primary_cell_phone']
const phone_number_names = ['cell_phone', 'phone_number', 'phoneNumber', 'primary_cell_phone', 'primary_phone_number', 'work_phone_number', 'landline']

const user_data_form_field_placeholders = {
    'prefix': 'Prefix',
    'title': 'Title',
    'suffix': 'Suffix',

    'email_unsubscribe': 'Unsubscribe from emails',
    'sms_optin': 'Opt in to SMS',
    'email': 'Email',
    'work_email': 'Work Email',
    'first_name': 'First Name',
    'last_name': 'Last Name',
    'full_name': 'Full Name',
    'first_initial': 'First Initial',

    'last_initial': 'Last Initial',
    'middle_name': 'Middle Name',
    'middle_initial': 'Middle Initial',
    'cell_phone': 'Cell Phone',
    'phone_number': 'Phone Number',
    'phoneNumber': 'Cell Phone',
    'primary_cell_phone': 'Primary Cell Phone',
    'primary_phone_number': 'Primary Phone Number',
    'work_phone_number': 'Work Phone Number',
    'landline': 'Landline',
    'address': 'Address',
    'address_2': 'Address 2',
    'city': 'City',
    'state': 'State',
    'state_code': 'State Code',
    'zip': 'Zip',
    'zip_4': 'Zip 4',
    'county': 'County',
    'country': 'Country',
    'country_code': 'Country Code',
    'route': 'Route',
    'street_number': 'Street Number',
    'neighborhood': 'Neighborhood',

    'administrative_area_level_2': 'Administrative Area Level 2',
    'administrative_area_level_1': 'Administrative Area Level 1',
    'occupation': 'Occupation',
    'employer': 'Employer',
    'job_title': 'Job Title',
    'employer_address': 'Employer Address',
    'employer_address_2': 'Employer Address 2',
    'employer_formatted_address': 'Employer Formatted Address',
    'employer_city': 'Employer City',
    'employer_state': 'Employer State',
    'employer_state_code': 'Employer State Code',
    'employer_zip': 'Employer Zip',
    'employer_zip_4': 'Employer Zip 4',
    'employer_country': 'Employer Country',
    'employer_country_code': 'Employer Country Code',
    'gender': 'Gender',
    'birth_year': 'Birth Year',
    'birth_month': 'Birth Month',
    'birth_day': 'Birth Day Of Month',
    'birth_date': 'Birth Date',
    'spanish': 'I Speak Spanish',
}

const html_input_autocomplete_types_by_user_data_key_name = {
    'first_name': 'given-name',
    'middle_name': 'additional-name',
    'last_name': 'family-name',
    'full_name': 'name',
    'prefix': 'honorific-prefix',
    'suffix': 'honorific-suffix',
    'cell_phone': 'tel',
    'phone_number': 'tel',
    'phoneNumber': 'tel',
    'primary_cell_phone': 'tel',
    'primary_phone_number': 'tel',
    'work_phone_number': 'tel',
    'landline': 'tel',
    'address': 'street-address',
    'address_2': 'address-line2',
    'city': 'address-level2',
    'state': 'address-level1',
    'zip': 'postal-code',
    'country': 'country-name',
    'country_code': 'country',
    'email': 'email',
    'work_email':'email',
    'occupation': 'organization-title',
    'employer': 'organization',
    'birth_year': 'bday-year',
    'birth_month': 'bday-month',
    'birth_day': 'bday-day',
    'birth_date': 'bday',
    'gender': 'sex',
};


// These are slightly different from the whitelist. these are rendered signup forms so like no unsub list arrays etc
// OPENAI make sure that we have complete overlap with user_data_form_field_placeholders
const user_data_form_field_types = {
    'prefix': 'text',
    'title': 'text',
    'suffix': 'text',
    'sms_optin': 'checkbox',
    'email_unsubscribe': 'checkbox',
    'email': 'email',
    'work_email': 'email',
    'first_name': 'text',
    'last_name': 'text',
    'full_name': 'text',
    'first_initial': 'text',
    'last_initial': 'text',
    'middle_name': 'text',
    'middle_initial': 'text',
    'cell_phone': 'tel',
    'phone_number': 'tel',
    'phoneNumber': 'tel',
    'primary_cell_phone': 'tel',
    'primary_phone_number': 'tel',
    'work_phone_number': 'tel',
    'landline': 'tel',
    'address': 'text',
    'address_2': 'text',
    'city': 'text',
    'state': 'text',
    'state_code': 'text',
    'zip': 'text',
    'zip_4': 'text',
    'county': 'text',
    'country': 'text',
    'country_code': 'text',
    'route': 'text',
    'street_number': 'text',
    'neighborhood': 'text',
    'administrative_area_level_2': 'text',
    'administrative_area_level_1': 'text',
    'occupation': 'text',
    'employer': 'text',
    'job_title': 'text',
    'employer_address': 'text',
    'employer_address_2': 'text',
    'employer_formatted_address': 'text',
    'employer_city': 'text',
    'employer_state': 'text',
    'employer_state_code': 'text',
    'employer_zip': 'text',
    'employer_zip_4': 'text',
    'employer_country': 'text',
    'employer_country_code': 'text',
    'gender': 'text',
    'birth_year': 'number',
    'birth_month': 'number',
    'birth_day': 'number',
    'birth_date': 'date',
    'spanish': 'checkbox',
    'formatted_address': 'text',
}

const this_year_minus_18 = new Date().getFullYear() - 18;
const input_number_type_min_max_by_name_key = {
    'birth_year': { 'min': 1900, 'max': this_year_minus_18 },
    'birth_month': { 'min': 1, 'max': 12 },
    'birth_day': { 'min': 1, 'max': 31 },
}

const white_list_incoming_person_fields = [
    'global_uuid', 'unknown_uuid', 'uuids', 'userId',
    'devices', 'auth_providers', 'social_profiles',
    'returned_email_transformations',
    'prefix', 'title', 'suffix',
    'sms_optin', 'email_unsubscribe',
    'email', 'provided_email', 'work_email', 'emails', 'email_lower_md5', 'email_upper_md5', 'email_lower_sha256', 'email_upper_sha256',
    'first_name', 'last_name', 'full_name', 'first_initial', 'last_initial', 'middle_name', 'middle_initial',
    'phone_numbers',
    'cell_phone', 'phone_number', 'phoneNumber', 'primary_cell_phone', 'primary_phone_number', 'work_phone_number', 'landline',
    'address', 'address_2', 'city', 'state', 'state_code', 'zip', 'zip_4', 'county', 'country', 'country_code', 'formatted_address',
    'route', 'street_number', 'neighborhood', 'google_place_id', 'administrative_area_level_2', 'administrative_area_level_1',
    'occupation', 'provided_occupation', 'provided_employer', 'employer', 'job_title',
    'employer_address', 'employer_address_2', 'employer_formatted_address', 'employer_city', 'employer_state', 'employer_state_code',
    'employer_zip', 'employer_zip_4', 'employer_country', 'employer_country_code', 'employer_address_google_place_id',
    'gender', 'gender_modeled',
    'birth_year', 'birth_month', 'birth_day', 'birth_date', 'locality', 'address_timezone', 'address_utc_offset',
    'subscribedMessageTypeIds', 'userListIds', 'unsubscribedChannelIds', 'unsubscribedMessageTypeIds', 'emailListIds',
    'spanish',
    'lifetime_donation_amount', 'last_donation_amount', 'last_donation_date', 'first_donation_date',
    'average_donation_amount', 'is_eligible_for_express_lane', 'total_donation_count', 'double_last_donation'
]

const user_data_to_actblue_key_names = {
    'first_name': 'firstname',
    'last_name': 'lastname',
    'email': 'email',
    'cell_phone': 'phone',
    'phoneNumber': 'phone',
    'phone_number': 'phone',
    'zip': 'zip',
    'address': 'address',
    'city': 'city',
    'state': 'state',
    'employer': 'employer',
    'occupation': 'occupation',
}

const actblue_key_names_user_data_key_names = {
    'firstname': 'first_name',
    'lastname': 'last_name',
    'email': 'email',
    'phone': 'cell_phone',
    'zip': 'zip',
    'address': 'address',
    'city': 'city',
    'state': 'state',
    'employer': 'employer',
    'occupation': 'occupation',
}

const array_like_url_params = ['userListIds', 'subscribedMessageTypeIds', 'unsubscribedChannelIds', 'unsubscribedMessageTypeIds', 'emailListIds']

const endpoints_that_require_user_or_email_under_user_object = ["commerce/trackPurchase", "commerce/updateCart", "users/byUserId", "users/getByEmail"]

const endpoints_that_require_phoneNumber = ["verify/sms/begin", "verify/sms/check"]

const endpoints_that_require_recipientEmail_or_recipientUserId = ["email/target", "inApp/target", "push/target", "sms/target", "webPush/target"]

const endpoints_narrow_allow_list = [
    "users/update", "users/updateSubscriptions",
    "commerce/trackPurchase", "auth/jwts/invalidate",
    "events/trackWebPushClick", "events/track",
    "users/registerDeviceToken", "users/registerBrowserToken"]

const endpoints_that_require_user_or_email = ["users/update", "users/updateSubscriptions", "users/forget", "users/unforget", "auth/jwts/invalidate",
    "events/trackWebPushClick", "events/trackPushOpen", "events/track", "embedded-messaging/events/session", "embedded-messaging/events/click", "embedded-messaging/events/received",
    "events/inAppConsume", "events/trackInAppClick", "events/trackInAppClose", "events/trackInAppDelivery", "events/trackInAppOpen",
    "email/cancel", "inApp/cancel", "push/cancel", "sms/cancel", "webPush/cancel", "workflows/triggerWorkflow",
    "users/disableDevice", "users/registerDeviceToken", "users/registerBrowserToken",
]

const personFieldBasicTypes = {
    prefix: "string",
    title: "string",
    suffix: "string",

    sms_optin: "boolean",
    email_unsubscribe: "boolean",
    email: "string",
    work_email: "string",

    first_name: "string",
    last_name: "string",
    full_name: "string",
    first_initial: "string",
    last_initial: "string",
    middle_name: "string",
    middle_initial: "string",

    cell_phone: "string",
    phoneNumber: "string",
    phone_number: "string",
    primary_cell_phone: "string",
    primary_phone_number: "string",
    work_phone_number: "string",
    landline: "string",

    address: "string",
    address_2: "string",
    city: "string",
    state: "string",
    state_code: "string",
    zip: "string",
    zip_4: "string",
    county: "string",
    country: "string",
    country_code: "string",
    formatted_address: "string",

    route: "string",
    street_number: "string",
    neighborhood: "string",
    administrative_area_level_2: "string",
    administrative_area_level_1: "string",

    occupation: "string",
    employer: "string",
    job_title: "string",
    employer_address: "string",
    employer_address_2: "string",
    employer_formatted_address: "string",
    employer_city: "string",
    employer_state: "string",
    employer_state_code: "string",
    employer_zip: "string",
    employer_zip_4: "string",
    employer_country: "string",
    employer_country_code: "string",

    gender: "string",
    is_eligible_for_express_lane: "boolean",

    birth_year: "int",
    birth_month: "int",
    birth_day: "int",
    birth_date: "string",

    locality: "string",
    address_timezone: "string",
    address_utc_offset: "float",

    subscribedMessageTypeIds: "int[]",
    userListIds: "int[]",
    unsubscribedChannelIds: "int[]",
    unsubscribedMessageTypeIds: "int[]",
    emailListIds: "int[]",

    spanish: "boolean"
};

export {
    default_ab_amounts,
    default_ab_amounts_string,
    default_forms_domain,
    default_theme,
    at_least_one_required_identifier_fields,
    allowed_iterable_h_hash_key_names,
    white_list_incoming_person_fields,
    array_like_url_params,
    white_list_outgoing_person_fields,
    allow_boolean_only,
    endpoints_that_require_user_or_email_under_user_object,
    endpoints_that_require_phoneNumber,
    endpoints_that_require_recipientEmail_or_recipientUserId,
    endpoints_narrow_allow_list,
    endpoints_that_require_user_or_email,
    form_input_types_string,
    user_data_form_field_types,
    user_data_form_field_placeholders,
    html_input_autocomplete_types_by_user_data_key_name,
    actblue_key_names_user_data_key_names,
    user_data_to_actblue_key_names,
    phone_number_names,
    at_least_one_required_form_identifier_fields,
    cell_phone_names,
    input_number_type_min_max_by_name_key,
    personFieldBasicTypes,
    empty_array_keys_to_skip,
    email_names,
    default_selectors
}