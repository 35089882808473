// src/forms/data_hub.js
import {
    return_valid_or_default_forms_domain,
    inject_styles,
    fetch_with_timeout
} from '../helpers/index';

import { addDataHubMessageListener } from '../forms/cors_iframe';

import { bind_all_tel_inputs_intl } from '../tel/telephone_sms_inputs';

import defaultsDeep from 'lodash.defaultsdeep';

import { load_user_data_from_jwt_url_params } from '../helpers/get_process_user_data';

import {
    extract_actblue_refcodes,
    get_metadata_url_params,
    get_most_parent_url_params
} from '../helpers/parse_url_params';

import {
    default_forms_domain,
    default_theme,
    white_list_incoming_person_fields
} from '../helpers/static_vars';

import { show_error_popover } from './html_element_helpers/error_popover';
import { run_theme_json_to_base64 } from './html_element_helpers/form_styling';

import {
    add_form_input_loading_state,
    remove_form_input_loading_state,
    add_form_loading_overlay,
    remove_form_loading_overlay,
    prefill_form,
    re_prefill_all_forms
} from '../forms/shared_form_ux_functions';

import { updateActblueConfigWithDatahub, init_actblue } from '../helpers/actblue_integration';

import { v4 as uuidv4 } from 'uuid';

import {
    get_iterable_ids,
    set_iterable_campaign_template_temporary_cookies,
} from '../helpers/iterable_helpers';

export class data_hub_class {
    constructor(config = {}, callback_function_after_init = () => { }) {
        const default_error_function = (internal_error_message, external_error_message) => {
            console.error(internal_error_message, external_error_message);
            show_error_popover(external_error_message, this.target || document);
        };
        this.error_function = config.error_function || default_error_function;

        // Set iframe cors listener passing the reference
        addDataHubMessageListener(this);

        // Start ActBlue integration (load its configuration)
        this.has_init_actblue_merged_configuration = false;
        init_actblue(this.error_function)
            .catch((error) => {
                this.error_function('Error initializing ActBlue in data_hub.js: ' + error, null);
            });

        this.client_metadata = {};

        this.forms_domain = return_valid_or_default_forms_domain(config.forms_domain || default_forms_domain);
        this.url_params = get_most_parent_url_params(this.error_function);
        this.iterableCampaignId = null;
        this.iterableTemplateId = null;

        // Local set for prefilled forms
        this.prefilled_forms = new Set();

        // ID to compare uniqueness of data_hub inits
        this.data_hub_init_id = uuidv4();

        // Global shared user_data object
        this.user_data = (config.user_data && typeof config.user_data === 'object') ? config.user_data : {};
        this.jwt_cookie = null;

        this.actblue_token = config.actblue_token || null;
       
        this.actblue_amounts = config.actblue_amounts || [5,10,20,50,100];
        this.actblue_default_page_url = config.actblue_default_page_url || null;
        this.actblue_preview = config.actblue_preview || false;
        this.actblue_css_url = config.actblue_css_url || 'https://qtera.org/css/actblue_modal_600_max.css';

        this.bind_tel_inputs_intl = config.bind_tel_inputs_intl || false;

        // Setup promise to signal when user data is loaded
        this.user_data_loaded = false;
        this.user_data_loaded_promise = new Promise((resolve, reject) => {
            this.resolve_user_data_loaded = () => {
                this.user_data_loaded = true;
                return resolve();
            };
            this.reject_user_data_loaded = () => {
                this.user_data_loaded = false;
                return reject();
            };
        }).then(() => {
            // TODO let's just re write everything to react? this stupid user_data sync is driving me INSANE
            this.user_data = defaultsDeep(this.user_data, window.data_hub_user_data);

            updateActblueConfigWithDatahub(this);

            const event_detail_data = {
                user_data: this.user_data || {},
                prefill_input_selector: this.prefill_input_selector || null,
                data_hub_instance: this,
                target: this.target || document
            };

            window.dispatchEvent(new CustomEvent('data-hub-new-user-data', {
                detail: event_detail_data,
                bubbles: true,
                composed: true
            }));

            if (this.bind_tel_inputs_intl) {
                bind_all_tel_inputs_intl(
                    this.target || document,
                    this
                );
            }

        })
            .catch((error) => {
                this.error_function('Error in user_data_loaded_promise: ' + error, null);
            })

        // Create page metadata and load Iterable IDs
        this.set_page_metadata_defaults(config);

        // Prefill settings
        this.target = config.target || null;
        this.prefill_input_selector = '[data-allow-data-hub-prefill="true"]';
        this.reset_button_selector = '.data-hub-reset-form';
        this.prefill_all_forms = config.prefill_all_forms ?? true;
        this.block_page_on_user_data_load = config.block_page_on_user_data_load ?? true;
        this.prefill_timeout = config.prefill_timeout || 2000;
        this.has_hit_prefill_timeout = false;
        this.get_iterable_user_data_via_hmac_key = config.get_iterable_user_data_via_hmac_key ?? true;
        this.callback_function_after_init = callback_function_after_init;

        this.is_data_hub_form_submission_in_progress = false;

        // Inject theme styles if provided
        config.theme = config.theme || {};
        const theme = defaultsDeep({}, config.theme, default_theme);
        if (config.style_target) {
            inject_styles(theme, config.style_target);
        }

        // Begin initialization
        this.start_data_hub_init();
    }

    callDataHubDispatchEventThenCallbackInit() {
        document.dispatchEvent(new CustomEvent('data-hub-init', {
            detail: {
                data_hub_instance: this
            },
            bubbles: true,
            composed: true
        }));
        this.callback_function_after_init(this);
    }

    async start_data_hub_init() {
        if (this.prefill_all_forms) {
            add_form_input_loading_state(this.target || document, this.prefill_input_selector);
            add_form_loading_overlay(this.target || document);
        }

        if (this.prefill_timeout) {
            setTimeout(() => {
                this.has_hit_prefill_timeout = true;
                remove_form_input_loading_state(this.target || document, this.prefill_input_selector);
                remove_form_loading_overlay(this.target || document);
            }, this.prefill_timeout);
        }

        // Load client metadata asynchronously.
        await this.load_client_public_metadata().then((metadata) => {
            if (metadata && typeof metadata === 'object') {
                this.client_metadata = metadata;
            } else {
                try {
                    this.client_metadata = JSON.parse(metadata);
                } catch (error) {
                    this.error_function('Error parsing client metadata: ' + error, null);
                    this.client_metadata = {};
                }
            }
            if (this.client_metadata?.actblue_default_token) {
                this.actblue_token = this.client_metadata.actblue_default_token;
            }
            if (this.client_metadata?.actblue_default_page_url) {
                this.actblue_default_page_url = this.client_metadata.actblue_default_page_url;
            }

            if (this.client_metadata?.forms_domain) {
                this.forms_domain = return_valid_or_default_forms_domain(this.client_metadata.forms_domain);
            }
        });

        // Wait to try and get the updated forms_domain from client_metadata
        try {
            // Chain the two data sources and merge them.
            await load_user_data_from_jwt_url_params(this);
            this.start_prefill_forms(this.has_hit_prefill_timeout);
            this.resolve_user_data_loaded();
            this.callDataHubDispatchEventThenCallbackInit();
        } catch (error) {
            this.reject_user_data_loaded(error);
            this.callDataHubDispatchEventThenCallbackInit();
        }
    }

    // Static helper to convert theme JSON to base64
    static theme_json_to_base64(theme) {
        return run_theme_json_to_base64(theme);
    }

    start_prefill_forms(has_hit_prefill_timeout) {
        if (this.prefill_all_forms && !has_hit_prefill_timeout) {
            prefill_form(
                document,
                this.user_data,
                this.prefill_input_selector,
                this.reset_button_selector,
                null,
                false,
                this
            );
        }
    }

    // Sets page metadata and Iterable IDs.
    async set_page_metadata_defaults(config) {
        if (!config.page_metadata || typeof config.page_metadata !== 'object') {
            config.page_metadata = {};
        }

        if (!this.page_metadata) {
            this.page_metadata = {};
        } else {
            this.page_metadata = defaultsDeep(this.page_metadata, config.page_metadata || {});
        }

        // Get ActBlue refcodes.
        this.page_metadata.refcodes = extract_actblue_refcodes(this.url_params);

        // If passed in this.refcodes use as priority defaultDeep
        if (config?.refcodes) {
            this.page_metadata.refcodes = defaultsDeep(this.page_metadata.refcodes, config.refcodes);
        }

        // Get additional metadata from URL parameters.
        get_metadata_url_params(this.url_params, this);

        // Set Iterable IDs.
        const iterable_ids = get_iterable_ids(config, this.url_params);
        this.iterableCampaignId = iterable_ids.iterableCampaignId;
        this.iterableTemplateId = iterable_ids.iterableTemplateId;

        set_iterable_campaign_template_temporary_cookies(this.iterableCampaignId, this.iterableTemplateId);

        if (document.referrer) {
            this.page_metadata.referer = document.referrer;
        }
        if (document.title) {
            this.page_metadata.page_title = document.title;
        }
        if (window.location.href) {
            this.page_metadata.page_url = window.location.href.split('?')[0];
        }

        this.page_metadata.pageview_uuid = uuidv4();
    }

    async load_client_public_metadata() {
        if (!this.forms_domain) {
            this.error_function('Error: no forms_domain', null);
            return {};
        }
        const endpoint = `${this.forms_domain}/api/get/client_metadata`;
        try {
            const response = await fetch_with_timeout(endpoint, {
                method: 'GET',
                credentials: 'include',
            }, 5000);
            if (response.ok) {
                const data = await response.json();
                if (data && typeof data === 'object') {
                    return data;
                } else {
                    this.error_function('Client metadata is not an object: ' + typeof data, null);
                }
            } else {
                this.error_function('Request to get client metadata failed: ' + response.status + response.statusText, null);
            }
        } catch (error) {
            this.error_function('Error fetching client metadata: ' + error);
        }
        return {};
    }

    got_cors_user_data_cache(payload) {
        if (!payload) return;

        Object.keys(payload).forEach((key) => {
            if (!white_list_incoming_person_fields.includes(key)) {
                delete payload[key];
            }
        });

        // Merge the new data into the instance’s user_data.
        const newMergedUserData = defaultsDeep(this.user_data, window?.data_hub_user_data || {}, payload);
        this.user_data = newMergedUserData;
        window.data_hub_user_data = newMergedUserData;

        // Trigger any necessary callbacks.
        if (typeof this.resolve_user_data_loaded === 'function') {
            this.resolve_user_data_loaded();
        }

        // TODO ask openai does user_data_loaded_promise call the new data-hub-new-user-data user data event?

        // Optionally re-fill forms.
        re_prefill_all_forms(newMergedUserData, this.prefill_input_selector, this);

        updateActblueConfigWithDatahub(this);

    }
}