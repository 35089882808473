/**
 * Cookie utility for reading, setting, and removing cookies with options for handling
 * expiration dates, secure attributes, and encoding.
 */
const cookie_utils = {
    /**
     * Set a cookie with optional attributes.
     * @param {string} name - The name of the cookie.
     * @param {string} value - The value of the cookie.
     * @param {Object} [attributes={}] - Optional attributes for cookie settings.
     */
    set_cookie: function (name, value, attributes = {}) {
      if (typeof document === 'undefined') return;
  
      // Default attributes, e.g., path to root
      attributes = { path: '/', ...attributes };
  
      // Expiration date (in days)
      if (typeof attributes.expires === 'number') {
        attributes.expires = new Date(Date.now() + attributes.expires * 864e5).toUTCString();
      } else if (attributes.expires === -1) {
        attributes.expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
      } // else if null set to 24 hours
      else if (!attributes.expires) {
        attributes.expires = new Date(Date.now() + 864e5).toUTCString();
      }
  
      // Encode cookie name and value
      const encoded_name = encodeURIComponent(name);
      const encoded_value = encodeURIComponent(value);
  
      // Build cookie string with attributes
      let cookie_string = `${encoded_name}=${encoded_value}`;
      for (const [key, val] of Object.entries(attributes)) {
        cookie_string += `; ${key}`;
        if (val !== true) {
          cookie_string += `=${val}`;
        }
      }

      // Set the cookie
      document.cookie = cookie_string;
    },
  
    /**
     * Get the value of a specified cookie by name.
     * @param {string} name - The name of the cookie.
     * @returns {string|undefined} - The cookie value, or undefined if not found.
     */
    get_cookie: function (name) {
      if (typeof document === 'undefined') return;
  
      // Cookie parsing logic
      const cookies = document.cookie ? document.cookie.split('; ') : [];
      for (let cookie of cookies) {
        let [key, ...vals] = cookie.split('=');
        key = decodeURIComponent(key);
        if (key === name) {
          return decodeURIComponent(vals.join('='));
        }
      }
      return undefined;
    },
  
    /**
     * Get all cookies as an object.
     * @returns {Object} - All cookies in key-value pairs.
     */
    get_all_cookies: function () {
      if (typeof document === 'undefined') return {};
  
      const cookies = document.cookie ? document.cookie.split('; ') : [];
      const cookie_jar = {};
      for (let cookie of cookies) {
        let [key, ...vals] = cookie.split('=');
        key = decodeURIComponent(key);
        cookie_jar[key] = decodeURIComponent(vals.join('='));
      }
      return cookie_jar;
    },
  
    /**
     * Remove a specified cookie by name with optional attributes.
     * @param {string} name - The name of the cookie.
     * @param {Object} [attributes={}] - Optional attributes to set during removal.
     */
    remove_cookie: function (name, attributes = {}) {
      this.set_cookie(name, '', { ...attributes, expires: -1 });
    }
  };

  export { cookie_utils };