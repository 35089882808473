// actblue_integration.js
import { user_data_to_actblue_key_names } from './static_vars';
import { poll_with_retry } from './index';
import defaultsDeep from 'lodash.defaultsdeep';
import { v4 as uuidv4 } from 'uuid';
import {
  create_append_thank_you_message,
  create_html_amount_buttons,
  insert_actblue_on_failure_div,
  donation_modal_in_progress_spinner
} from '../forms/html_element_helpers/form_generators';

let is_actblue_loading_data_hub = false;

const ACTBLUE_SCRIPT_URL = 'https://secure.actblue.com/cf/assets/actblue.js';
const ACTBLUE_LOAD_TIMEOUT = 5000; // 5 seconds

export async function init_actblue(error_function = window?.data_hub?.error_function) {
  try {
    if (is_actblue_loading_data_hub) {
      return;
    }
    await load_actblue_library();
  } catch (error) {
    error_function('Error loading ActBlue: ' + error, null);
  }
}

export const load_actblue_library = () => {
  return new Promise((resolve, reject) => {
    if (is_actblue_loading_data_hub === true) {
      return;
    }
    is_actblue_loading_data_hub = true;

    if (actblue_is_configured()) {
      is_actblue_loading_data_hub = false;
      return resolve(true);
    }

    if (window.actblue && typeof window.actblue?.requestContribution === 'function') {
      is_actblue_loading_data_hub = false;
      return resolve(true);
    }

    const existing_script = document.querySelector(`script[src="${ACTBLUE_SCRIPT_URL}"]`);
    if (existing_script) {
      // If the script is already on the page, give it a moment to initialize
      setTimeout(() => {
        if (window.actblue && typeof window.actblue.requestContribution === 'function') {
          is_actblue_loading_data_hub = false;
          resolve(true);
        } else {
          is_actblue_loading_data_hub = false;
          reject(new Error('AB script already loaded, but function doesnt exist after 3s'));
        }
      }, 3000);
      return;
    }

    // Not loaded yet - create a script tag
    const script = document.createElement('script');
    script.src = ACTBLUE_SCRIPT_URL;
    script.async = true;

    script.onload = () => {
      if (window.actblue && typeof window.actblue.requestContribution === 'function') {
        is_actblue_loading_data_hub = false;
        resolve(true);
      } else {
        is_actblue_loading_data_hub = false;
        reject(new Error('Failed to initialize ActBlue after loading.'));
      }
    };

    script.onerror = () => {
      is_actblue_loading_data_hub = false;
      reject(new Error('Failed to load ActBlue script.'));
    };

    document.head.appendChild(script);

    setTimeout(() => {
      if (window.actblue && typeof window.actblue.requestContribution === 'function') {
        is_actblue_loading_data_hub = false;
        resolve(true);
      } else {
        is_actblue_loading_data_hub = false;
        reject(new Error('ActBlue library load timed out.'));
      }
    }, ACTBLUE_LOAD_TIMEOUT);

    // TODO maybe we can check __configuration for donor data and if we dont have it in data_hub.user_data add it
  });
};

export const actblue_is_configured = () => {
  return window.actblue && typeof window.actblue.requestContribution === 'function';
};

export const add_primary_refcodes = (this_data_hub_instance = window?.data_hub) => {
  try {
    let primary_refcodes = {};
    
    if (this_data_hub_instance?.iterableCampaignId) {
      primary_refcodes['refcodeIterableCampaignId'] = this_data_hub_instance.iterableCampaignId;
    }

    if (this_data_hub_instance?.iterableTemplateId) {
      primary_refcodes['refcodeIterableTemplateId'] = this_data_hub_instance.iterableTemplateId;
    }

    primary_refcodes['refcodeDataHub'] = true;
    primary_refcodes['refcodePageviewUuid'] = this_data_hub_instance?.page_metadata?.pageview_uuid || uuidv4();

    return primary_refcodes;
  } catch(e) {
    if (error_function) {
      return error_function("error adding primary refcodes" + e);
    }
    console.error("error adding primary refcodes", e);
  }
};

export const generate_actblue_donor_data_from_data_hub = async (this_data_hub_instance = window?.data_hub) => {
 
  const user_data = defaultsDeep(this_data_hub_instance?.user_data || {}, this_data_hub_instance?.client_metadata?.user_data || {}) || {};

  const person_to_actblue_key_names = {
    'first_name': 'firstname',
    'last_name': 'lastname',
    'email': 'email',
    'phone_number': 'phone',
    'phoneNumber': 'phone',
    'cell_phone': 'phone',
    'zip': 'zip'
  };

  let donor_data = {};
  for (const [dataKey, abKey] of Object.entries(person_to_actblue_key_names)) {
    if (user_data[dataKey]) {
      donor_data[abKey] = user_data[dataKey];
    }
  }
  return donor_data;
};

export async function updateActblueConfigWithDatahub(
  this_data_hub_instance = window?.data_hub,
  error_function = window?.data_hub?.error_function || ((internal_message, external_message) => console.error(internal_message, external_message))
) {

  if (!this_data_hub_instance) {
    return error_function('No DataHub instance found', null);
  }

  try {
    await Promise.all([
      poll_with_retry(() => this_data_hub_instance?.user_data_loaded === true, { timeout: 5000 }),
      poll_with_retry(() => typeof window?.actblue?.configure === 'function', { timeout: 5000 })
    ])
      .catch(error => {
        return error_function('Error waiting for user data or ActBlue library', error);
      });

    const primaryRefcodes = add_primary_refcodes(this_data_hub_instance);
    const newUserData = await generate_actblue_donor_data_from_data_hub(this_data_hub_instance);

    // Also merge back with data_hub.page_metadata.refcodes defaultsDeep
    if (!this_data_hub_instance?.page_metadata?.refcodes) {
      this_data_hub_instance.page_metadata.refcodes = {};
    }

    const mergedRefcodes = defaultsDeep(primaryRefcodes, this_data_hub_instance.page_metadata.refcodes);

    this_data_hub_instance.page_metadata.refcodes = mergedRefcodes;

    console.log('debug original ab configuration', window?.actblue?.__configuration);

    const mergedConfig = defaultsDeep(
      {
        //token: this_data_hub_instance?.actblue_token,
        donor: newUserData || {},
        refcodes: mergedRefcodes || {},
        amounts: this_data_hub_instance?.actblue_amounts || [5,10,20,50,100],
        styleSheetHref: this_data_hub_instance?.actblue_css_url || 'https://qtera.org/css/actblue_modal_600_max.css'
      },
      window?.actblue?.__configuration || {}
    );

    // specific token check, if one exists console.warn do not overwrite
    if (window?.actblue?.__configuration?.token) {
      console.warn('Actblue token already exists, not overwriting:',
        window.actblue.__configuration.token, 'with', this_data_hub_instance?.actblue_token);
    } else if (this_data_hub_instance?.actblue_token) {
      console.warn('Using actblue token from data hub:', this_data_hub_instance.actblue_token);
      mergedConfig.token = this_data_hub_instance.actblue_token;
    }

    window.actblue.configure(mergedConfig);

    console.log('new ab configuration', window?.actblue?.__configuration);
  } catch (error) {
    error_function('Error updating ActBlue configuration' + error, null);
  }
}

export const get_cached_actblue_token = () => {
  const actblue_config_token = window.actblue?.__configuration?.token || null;
  if (actblue_config_token) {
    return actblue_config_token;
  }
  const other_cached_token =
    window?.data_hub?.actblue_token ||
    window?.data_hub?.client_metadata?.actblue_default_token ||
    null;

  if (other_cached_token) {
    return other_cached_token;
  }
};

// To append to actblue URLs. Ex: ?refcodeDataHub=true&refcodePageviewUuid=1234
export const generate_refcode_url_params_from_refcodes = (refcodes = window?.data_hub?.page_metadata?.refcodes || {}) => {
  if (!refcodes || typeof refcodes !== 'object') return '';
  let url_params = '';
  for (const [key, value] of Object.entries(refcodes)) {
    try {
      if (typeof key !== 'string') {
        console.warn('Refcode key is not a string:', key);
        continue;
      }

      // Urldecode again just in case
      key = decodeURIComponent(key);
      value = decodeURIComponent(value);

      if (key.startsWith('refcode')) {

        // if the key is refcode_ we want to remove the _ 
        let reformat_refcode_string = ''
        if (key.startsWith('refcode_')) {
          reformat_refcode_string = key.slice(8);
        } else {
          reformat_refcode_string = key.slice(7);
        }

        // Make sure the second part after refcode is Upper so we have refcodeCamelCase
        reformat_refcode_string = "refcode" + reformat_refcode_string.charAt(0).toUpperCase() + reformat_refcode_string.slice(1);
        url_params += `${reformat_refcode_string}=${encodeURIComponent(value)}&`;
      } else {
        // The first character should be alpha only
        if (!key.charAt(0).match(/[a-z]/i)) {
          console.warn('Refcode key does not start with a letter:', key);
          continue;
        }
        key = key.charAt(0).toUpperCase() + key.slice(1);
        url_params += `refcode${key}=${encodeURIComponent(value)}&`;
      }
    } catch (error) {
      console.warn('Error processing refcode key-value pair:', key, value, error);
      continue;
    }
  }
  return url_params.endsWith('&') ? url_params.slice(0, -1) : url_params;
};

export const getDefaultAbAmountsString = (data_hub_instance = window?.AbortControllerdata_hub) => {
    try {
        const amounts = data_hub_instance?.actblue_amounts;
        if (Array.isArray(amounts) && amounts.every(Number.isInteger)) {
            return `[${amounts.toString()}]`;
        }
    } catch (e) {
        // Ignore errors and return default value
    }
    return '[5,10,25,50,100]';
}

export const normalize_refcodes_from_object = (refcodes, error_function = window?.data_hub?.error_function) => {
  if (!refcodes || typeof refcodes !== 'object') return {};

  const normalizedRefcodes = {};
  for (let [key, value] of Object.entries(refcodes)) {
      try {
          if (typeof key !== 'string' || typeof value !== 'string') {
              error_function?.(`Invalid refcode key-value pair: ${key} = ${value}`);
              continue;
          }

          // Decode potential URL encoding
          key = decodeURIComponent(key);
          value = decodeURIComponent(value);

          if (key.startsWith('refcode')) {
              // Remove any underscore after "refcode_" and enforce CamelCase
              let formattedKey = key.startsWith('refcode_')
                  ? key.slice(8) // Remove "refcode_"
                  : key.slice(7); // Remove "refcode"

              formattedKey = `refcode${formattedKey.charAt(0).toUpperCase()}${formattedKey.slice(1)}`;
              normalizedRefcodes[formattedKey] = value;
          } else {
              // If the key doesn't start with "refcode", enforce it and capitalize the first letter
              if (!key.charAt(0).match(/[a-z]/i)) {
                  error_function?.(`Refcode key does not start with a letter: ${key}`);
                  continue;
              }
              let formattedKey = `refcode${key.charAt(0).toUpperCase()}${key.slice(1)}`;
              normalizedRefcodes[formattedKey] = value;
          }
      } catch (error) {
          error_function?.(`Error normalizing refcode key-value pair: ${key} = ${value}, ${error}`);
          continue;
      }
  }

  return normalizedRefcodes;
};

export const amount_in_cents = (amount = 1000, error_function = window?.data_hub?.error_function) => {
  try {
    if (isNaN(amount)) {
      error_function("Amount is not a number", "Please enter a valid donation amount");
      return null;
    }

    amount = Math.round(Number(amount));

    if (amount < 100) {
      error_function('Amount less than $1, likely an error:', "Please donate $1 or more");
      return null;
    }

    return amount;
  } catch (error) {
    error_function('Error converting amount to cents: ' + error.message, "Please enter a valid donation amount");
    return null;
  }
};

export const create_url_params_for_actblue_from_user_data = (
  user_data = window?.data_hub?.user_data || {},
  error_function = window?.data_hub?.error_function
) => {
  if (!user_data || typeof user_data !== 'object') {
    error_function('User data is missing or invalid', null);
    return '';
  }

  let url_params = '';
  for (const [key, value] of Object.entries(user_data)) {
    if (user_data_to_actblue_key_names[key] && value) {
      const actblue_key = user_data_to_actblue_key_names[key];
      if (!url_params.includes(actblue_key)) {
        url_params += `${actblue_key}=${encodeURIComponent(value)}&`;
      }
    }
  }
  return url_params.endsWith('&') ? url_params.slice(0, -1) : url_params;
};

export const generate_actblue_url = (
  refcodes = window?.data_hub?.page_metadata?.refcodes || {},
  user_data = window?.data_hub?.user_data || {},
  amount = null,
  actblue_page_url = window?.data_hub?.actblue_default_page_url || '',
  error_function = window?.data_hub?.error_function
) => {
  if (!actblue_page_url) {
    error_function('No ActBlue page URL provided', null);
    return '';
  }

  const refcode_params = generate_refcode_url_params_from_refcodes(refcodes);
  const user_data_params = create_url_params_for_actblue_from_user_data(user_data, error_function);

  let full_url = actblue_page_url + '?';
  if (amount && typeof amount === 'number') {
    full_url += `amount=${amount}&`;
  }
  if (refcode_params) full_url += refcode_params + '&';
  if (user_data_params) full_url += user_data_params;

  return full_url.endsWith('&') ? full_url.slice(0, -1) : full_url;
};

export const create_actblue_link_p_element = (
  actblue_page_url = window?.data_hub?.actblue_default_page_url || null,
  error_function = window?.data_hub?.error_function
) => {
  const actblue_link_p = document.createElement('p');
  actblue_link_p.classList.add('flex', 'justify-center', 'items-center', 'h-full', 'text-center', 'py-4');

  if (actblue_page_url) {
    const actblue_link = document.createElement('a');
    const actblue_url = generate_actblue_url(
      window?.data_hub?.page_metadata?.refcodes || {},
      window?.data_hub?.user_data || {},
      null,
      actblue_page_url,
      error_function
    );
    if (!actblue_url) {
      error_function("Missing actblue_url at create_actblue_link_p_element", null);
      actblue_link_p.classList.add('text-red-500', 'font-semibold');
      actblue_link_p.textContent = 'Sorry, unable to make a donation at this time.';
      return { actblue_link_p };
    }
    actblue_link.href = actblue_url;
    actblue_link.textContent = 'Click here to donate via ActBlue.com';
    actblue_link.classList.add('text-blue-500', 'hover:text-blue-700', 'underline', 'text-center');
    actblue_link_p.appendChild(actblue_link);
  } else {
    error_function('No ActBlue page URL provided', null);
    actblue_link_p.classList.add('text-red-500', 'font-semibold');
    actblue_link_p.textContent = 'Sorry, unable to make a donation at this time.';
  }
  return { actblue_link_p };
};

// Triggered when donation goes through
export const actblue_on_complete_function = (contribution, target_container = document) => {

  if (target_container?.dataset) {
    target_container.dataset.donationSuccessful = 'true';
  }
  if (target_container) {
    // Hide "in progress" spinners
    const inProgressElements = target_container.querySelectorAll('.donation-in-progress');
    if (inProgressElements) {
      inProgressElements.forEach(element => {
        element.classList.add('hidden');
      });
    }
    // Show "Thank you" message
    create_append_thank_you_message(contribution, target_container);

    // Hide elements meant to be hidden on success
    const hideOnSuccessElements = target_container.querySelectorAll('.hide-on-actblue-success');
    if (hideOnSuccessElements) {
      hideOnSuccessElements.forEach(element => {
        element.classList.add('hidden');
      });
    }

    // Show elements meant to be shown on success
    const showOnSuccessElements = target_container.querySelectorAll('.show-on-actblue-success');
    if (showOnSuccessElements) {
      showOnSuccessElements.forEach(element => {
        element.classList.remove('hidden');
      });
    }
  }
};

export const actblue_donation_successfull = (actblue_donation_object, target_container) => {
  if (target_container?.dataset) {
    target_container.dataset.donationSuccessful = 'true';
  }
};

// Called after the modal is closed
export const actblue_on_close_function = (
  actblue_donation_object,
  target_container,
  amounts = [5,10,20,50,100],
  refcodes = {},
  donor_data = {},
  actblue_token = null,
  error_function = window?.data_hub?.error_function
) => {
  if (!target_container) return;

  const donationDidSucceed = (target_container.dataset?.donationSuccessful === 'true');

  if (!donationDidSucceed) {
    // No donation was completed
    const inProgressElements = target_container.querySelectorAll('.donation-in-progress');
    if (inProgressElements) {
      inProgressElements.forEach(element => {
        element.classList.add('hidden');
      });
    }
    const thankYouElements = target_container.querySelectorAll('.actblue-success-div-text');
    if (thankYouElements) {
      thankYouElements.forEach(element => {
        element.classList.add('hidden');
      });
    }
    // If no existing .actblue-amount-buttons-container, re-inject
    if (!target_container.querySelector('.actblue-amount-buttons-container')) {
      const actblue_buttons_dom_element = create_html_amount_buttons({
        amounts,
        refcodes,
        donor_data,
        actblue_token,
        target_container,
        actblue_page_url: null,
        error_function
      });
      if (actblue_buttons_dom_element) {
        target_container.appendChild(actblue_buttons_dom_element);
      } else {
        error_function('Error creating actblue buttons', null);
      }
    }
  } else {
    // Donation was successful, user closed the modal
    const thankYouElements = target_container.querySelectorAll('.actblue-success-div-text');
    if (thankYouElements && thankYouElements.length === 0) {
      create_append_thank_you_message(actblue_donation_object, target_container);
    } else if (thankYouElements[0]) {
      thankYouElements[0].classList.remove('hidden');
    }
    const hideOnSuccessElements = target_container.querySelectorAll('.hide-on-actblue-success');
    if (hideOnSuccessElements) {
      hideOnSuccessElements.forEach(element => {
        element.classList.add('hidden');
      });
    }
  }

  if (target_container.dataset) {
    target_container.dataset.donationSuccessful = 'false';
  }
};

export const redirect_to_actblue_url_or_return = (
  refcodes = window?.data_hub?.page_metadata?.refcodes || {},
  donor_data = window?.data_hub?.user_data || {},
  amount = null,
  actblue_page_url = window?.data_hub?.actblue_default_page_url || '',
  error_function = window?.data_hub?.error_function
) => {
  try {
    const actblue_link = generate_actblue_url(refcodes, donor_data, amount, actblue_page_url, error_function);
    if (actblue_link) {
      window.top.location.href = actblue_link;
      return;
    } else {
      return error_function('Error generating ActBlue URL', null);
    }
  } catch (error) {
    return error_function('Error redirecting to ActBlue URL: ' + error, null);
  }
};

export const actblue_has_library = () => {
  return window.actblue && typeof window.actblue.requestContribution === 'function';
};

export const actblue_trigger_donation = async ({
  amount,
  target_container_for_on_complete = null,
  actblue_token = get_cached_actblue_token(),
  preview = false,
  inject_css_file = window?.data_hub?.actblue_css_url || 'https://qtera.org/css/actblue_modal_600_max.css',
  error_function = window?.data_hub?.error_function,
  this_data_hub_instance = window?.data_hub,
} = {}) => {
  if (!actblue_has_library()) {
    error_function('ActBlue library not loaded', null);
    return redirect_to_actblue_url_or_return(
      window?.actblue?.__configuration?.refcodes || {},
      window?.actblue?.__configuration?.donor || {},
      amount,
      error_function
    );
  }

  // ? why was wrapping?
  // await ensureActblueConfigUpdated();
  updateActblueConfigWithDatahub(window?.data_hub, error_function);

  if (amount) {
    amount = amount_in_cents(amount, error_function);
    if (!amount) {
      return;
    }
  } else {
    return error_function('No amount provided', "Please enter a donation amount");
  }

  if (!actblue_token) {
    if (window?.data_hub?.actblue_token) {
      actblue_token = window.data_hub.actblue_token;
    } else if (window?.actblue?.__configuration?.token) {
      actblue_token = window.actblue.__configuration.token;
    } else {
      //check if there is a redirect url in data_hub.client_metadata.actblue_default_page_url
      if (window?.data_hub?.client_metadata?.actblue_default_page_url) {
        error_function('No ActBlue token provided. Redirecting to ActBlue URL.', null);
        return redirect_to_actblue_url_or_return(
          window?.actblue?.__configuration?.refcodes || {},
          window?.actblue?.__configuration?.donor || {},
          amount,
          window?.data_hub?.client_metadata?.actblue_default_page_url,
          error_function
        );
      } else {
        error_function('No ActBlue token provided.', "Sorry, we're unable to process your donation at this time.");
        return;
      }
    }
  }

  // TODO pass unique refcodes, so instead of overwriting passed refcodes from the html element we pass them down
  // into the generators -> which then call here

  const contribution_object = {
    preview: preview || false,
    styleSheetHref: inject_css_file,
    token: actblue_token,
    onComplete: (contribution) => {
      actblue_on_complete_function(contribution, target_container_for_on_complete);
    },
    onContribute: (actblue_donation_object) => actblue_donation_successfull(
      actblue_donation_object,
      target_container_for_on_complete
    ),
    onClose: (actblue_donation_object) => actblue_on_close_function(
      actblue_donation_object,
      target_container_for_on_complete
    ),
    onError: (message) => insert_actblue_on_failure_div(
      message,
      target_container_for_on_complete,
      error_function,
      this_data_hub_instance
    )
  };

  if (typeof amount === 'number') {
    contribution_object.amount = amount;
  }

  if (target_container_for_on_complete) {
    if (target_container_for_on_complete.dataset) {
      target_container_for_on_complete.dataset.donationSuccessful = 'false';
      if (window?.actblue?.__configuration?.refcodes) {
        target_container_for_on_complete.dataset.actblueRefcodes =
          JSON.stringify(window.actblue.__configuration.refcodes);
      }
      if (window?.actblue?.__configuration?.donor) {
        target_container_for_on_complete.dataset.actblueDonorData =
          JSON.stringify(window.actblue.__configuration.donor);
      }
      if (actblue_token) {
        target_container_for_on_complete.dataset.actblueToken = actblue_token;
      }
    } else {
      console.warn('target_container_for_on_complete does not support dataset');
    }
    if (target_container_for_on_complete instanceof HTMLElement) {
      const existingInProgressElements = target_container_for_on_complete.querySelectorAll('.donation-in-progress');
      if (existingInProgressElements && existingInProgressElements.length > 0) {
        existingInProgressElements[0].classList.remove('hidden');
      } else {
        donation_modal_in_progress_spinner(target_container_for_on_complete);
      }
    }
  } else {
    console.warn('actblue_trigger_donation: No target_container_for_on_complete provided. Skipping spinner insertion.');
  }

  window.actblue.requestContribution(contribution_object);
};