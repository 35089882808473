// src/helpers/get_process_user_data.js
import {
    white_list_incoming_person_fields,
    allowed_iterable_h_hash_key_names,
    personFieldBasicTypes,
    white_list_outgoing_person_fields,
    at_least_one_required_form_identifier_fields
} from './static_vars';

import { get_user_data_from_cors_iframe } from '../forms/cors_iframe';

import {
    extract_array_values_from_url_parameter,
    detect_parse_url_param_types
} from './parse_url_params';

import {
    string_array_of_ints_to_array,
    fetch_with_timeout,
    remove_empty_values_from_object
} from './index';

import defaultsDeep from 'lodash.defaultsdeep';

/**
 * Reads the URL parameters and returns a plain object
 * representing user data from the URL.
 * URL values here override any previously stored values.
 * param {URLSearchParams} url_params - The URL parameters to process.
 * param {Object} data_hub_instance - The data hub instance to use.
 * @returns {Object} - An object containing user data from the URL.
 */
export const getURLUserData = async (url_params, data_hub_instance=window?.data_hub) => {
    const result = {};
    if (!(url_params instanceof URLSearchParams)) {
        return result;
    }
    url_params.forEach((value, key) => {
        if (white_list_incoming_person_fields.includes(key)) {
            try {
                // If the key should be an array, process accordingly.
                if (Array.isArray(value) || value.includes(',')) {
                    const arrayValues = extract_array_values_from_url_parameter(value);
                    const parsed = string_array_of_ints_to_array(arrayValues);
                    result[key] = parsed;
                } else {
                    result[key] = detect_parse_url_param_types(value);
                }
            } catch (error) {
                console.warn('Error parsing URL param:', key, value, error);
            }
        } else if (allowed_iterable_h_hash_key_names.includes(key) &&
            typeof value === 'string' &&
            value.length > 10) {
            result[key] = value;
        }
    });
    return result;
};

/**
 * Fetches user data from JWT.
 * Returns a plain object (or {} if nothing was found or on error).
 */
export const getJWTUserData = async (data_hub_instance = window?.data_hub) => {
    if (!data_hub_instance) {
        throw new Error('Data hub reference is not defined.');
    }

    try {
        let these_user_hmac_key_values = {};
        let has_at_least_one_hmac_hash = false;

        // Ensure url_params is a URLSearchParams instance
        if (!data_hub_instance.url_params || !(data_hub_instance.url_params instanceof URLSearchParams)) {
            data_hub_instance.url_params = new URLSearchParams(window.location.search);
        }

        // Process HMAC keys if needed.
        if (data_hub_instance.get_iterable_user_data_via_hmac_key) {
            for (const key of allowed_iterable_h_hash_key_names) {
                const key_minus_h = key.replace('h_iterable_', '');
                if (data_hub_instance.url_params.has(key)) {
                    // Prefer the value from URL; fallback to any existing value
                    if (data_hub_instance.user_data && data_hub_instance.user_data[key_minus_h]) {
                        these_user_hmac_key_values[key] = data_hub_instance.url_params.get(key);
                        these_user_hmac_key_values[key_minus_h] = data_hub_instance.user_data[key_minus_h];
                        has_at_least_one_hmac_hash = true;
                    } else if (data_hub_instance.url_params.has(key_minus_h)) {
                        these_user_hmac_key_values[key] = data_hub_instance.url_params.get(key);
                        these_user_hmac_key_values[key_minus_h] = data_hub_instance.url_params.get(key_minus_h);
                        has_at_least_one_hmac_hash = true;
                    }
                }
            }
        }

        if (!data_hub_instance.forms_domain) {
            data_hub_instance.error_function && data_hub_instance.error_function('No forms domain provided for user JWT fetch.', null);
            return {};
        }

        let user_jwt_url = `${data_hub_instance.forms_domain}/api/get/user_jwt`;
        // Create params string regardless of origin for reuse
        let paramsString = '';
        
        if (has_at_least_one_hmac_hash && Object.keys(these_user_hmac_key_values).length > 0) {
            paramsString = new URLSearchParams(these_user_hmac_key_values).toString();
            user_jwt_url += `?${paramsString}`;

            // Remove hmac keys from browser url
            let new_url = new URL(window.location.href);
            for (const key of allowed_iterable_h_hash_key_names) {
                new_url.searchParams.delete(key);
            }
            if (history && history.replaceState) {
                history.replaceState(null, '', new_url.toString());
            }
        }

        // If forms_domain is cross-origin, hand off to CORS iframe and return empty object.
        if (data_hub_instance.forms_domain !== window.location.origin) {
            get_user_data_from_cors_iframe(data_hub_instance.forms_domain, paramsString, data_hub_instance);
            return {};
        }

        const response = await fetch_with_timeout(user_jwt_url, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            timeout: 3000
        });
        if (!response.ok) {
            data_hub_instance.error_function &&
                data_hub_instance.error_function('User JWT fetch failed: ' + response.status + ' ' + response.statusText, null);
            return {};
        }

        const json = await response.json();

        if (json && typeof json === 'object') {

            // 250311 New object returns { user_data: returned_user_data, jwt_cookie: jwt_cookie }
            // but want backwards compatible. i dont think need to change since user_data is still under .user... ?
            // if there is a jwt_cookie, set on the data_hub_instance
            if (json.jwt_cookie) {
                data_hub_instance.jwt_cookie = json.jwt_cookie;
            }

            let returned_user_data_whitelist = {};
            let json_user_object = json.user || json.user_data || json || {};
            if (json_user_object && typeof json_user_object === 'object') {
                //use white_list_incoming_person_fields to loop through and assign white listed keys
                Object.keys(json_user_object).forEach((key) => {
                    if (white_list_incoming_person_fields.includes(key)) {
                        returned_user_data_whitelist[key] = json_user_object[key];
                    }
                });
            }

            return returned_user_data_whitelist || {};
        } else {
            data_hub_instance.error_function &&
                data_hub_instance.error_function('User JWT response is not an object: ' + typeof json, null);
            return {};
        }
    } catch (error) {
        if (error.name !== 'AbortError') {
            data_hub_instance.error_function &&
                data_hub_instance.error_function('Fetch with timeout ended request early:' + error, null);
        } else {
            data_hub_instance.error_function &&
                data_hub_instance.error_function('User JWT fetch error: ' + error, null);
        }
        return {};
    }
};

export const load_user_data_from_jwt_url_params = async (data_hub_instance = window?.data_hub) => {
    if (!data_hub_instance) {
        throw new Error('Data hub reference is not defined.');
    }

    // Optionally preserve any existing user_data if you want to merge onto it:
    const existing_data = data_hub_instance.user_data || {};

    // 1. Get JWT user data.
    const jwtData = await getJWTUserData(data_hub_instance);

    // 2. Get URL params data.
    const urlData = await getURLUserData(data_hub_instance.url_params, data_hub_instance);

    // 3. Merge data so that URL values override JWT (and any existing data)
    //    (Swap the order: first argument is the primary source.)
    const mergedDataWithJWT = defaultsDeep(jwtData, existing_data);
    const mergedDataWithURL = defaultsDeep(urlData, mergedDataWithJWT);

    data_hub_instance.user_data = mergedDataWithURL;
    window.data_hub_user_data = mergedDataWithURL;

    // 4. Fire off IP info lookup (fire and forget).
    get_user_ip_info_assign_to_metadata(data_hub_instance, data_hub_instance.error_function);

    return mergedDataWithURL;
};

/**
 * Fetches the user's IP info.
 */
export const get_user_ip_info = async (error_function = window?.data_hub?.error_function) => {
    try {
        const response = await fetch_with_timeout("https://ipapi.co/json");
        if (!response.ok) {
            return {};
        }
        return await response.json();
    } catch (error) {
        return {}; // Return empty object instead of undefined
    }
};

/**
 * Assigns the user's IP info to data_hub_instance.page_metadata.
 */
export const get_user_ip_info_assign_to_metadata = async (data_hub_instance = window?.data_hub, error_function = window?.data_hub?.error_function) => {
    try {
        const data = await get_user_ip_info(error_function) || {};
        data_hub_instance.page_metadata = data_hub_instance.page_metadata || {};
        data_hub_instance.page_metadata.ip_data = data;

        // If has country_code assign to user_data
        if (!data_hub_instance.user_data) { data_hub_instance.user_data = {}; }
        if (!window.data_hub_user_data) { window.data_hub_user_data = {}; }
        if (data.country_code && (!data_hub_instance?.user_data?.country_code)) {
            data_hub_instance.user_data.country_code = data.country_code;
            window.data_hub_user_data.country_code =data.country_code;
        }
        if (data.country_name && (!data_hub_instance?.user_data?.country)) {
            data_hub_instance.user_data.country = data.country_name;
            window.data_hub_user_data.country = data.country_name;
        }

    } catch (error) {
        // Ensure we have an object even if the fetch fails
        if (data_hub_instance && data_hub_instance.page_metadata) {
            data_hub_instance.page_metadata.ip_data = data_hub_instance.page_metadata.ip_data || {};
        }
    }
};

/**
 * Filter inputs to look funder < 18 age and return a boolean.
 * @param {Object} user_data
 * @returns {boolean}
 */
export const is_under_18 = (user_data) => {
    if (!user_data) {
        return false;
    }

    const age_keys = ['birth_date', 'birth_year'];
    // must have at least one age key otherwise return
    if (!age_keys.some(key => user_data[key])) {
        return false;
    }

    // if has birth_date, try to parse it
    try {
        if (user_data.birth_date) {
            const birth_date = new Date(user_data.birth_date);
            if (birth_date instanceof Date && !isNaN(birth_date)) {
                const age = new Date().getFullYear() - birth_date.getFullYear();
                return age < 18;
            }
        }
    } catch (error) {
        console.warn("Error parsing birth_date:", error);
    }

    const age = new Date().getFullYear() - (user_data?.birth_year || 0);
    return age < 18;
};

/**
 * Normalizes user data fields to their expected types.
 * @param {Object} userData
 * @returns {Object} normalizedData
 */
export const normalizeUserData = (userData) => {
    const normalizedData = {};

    Object.keys(userData).forEach((key) => {
        if (personFieldBasicTypes[key]) {
            switch (personFieldBasicTypes[key]) {
                case "string":
                    normalizedData[key] = String(userData[key]);
                    break;
                case "int":
                    normalizedData[key] = parseInt(userData[key], 10) || null;
                    break;
                case "float":
                    normalizedData[key] = parseFloat(userData[key]) || null;
                    break;
                case "boolean":
                    normalizedData[key] = userData[key] === "true" || userData[key] === true;
                    break;
                case "int[]":
                    normalizedData[key] = Array.isArray(userData[key])
                        ? userData[key].map(i => parseInt(i, 10) || null).filter(i => i !== null)
                        : [];
                    break;
                default:
                    normalizedData[key] = userData[key]; // Fallback, keep the original
            }
        } else {
            normalizedData[key] = userData[key]; // Keep any extra fields untouched
        }
    });

    return normalizedData;
}

/**
 * Removes any user data keys that are not whitelisted.
 * @param {Object} data_hub_action_object_to_send_to_api
 */
export function removeNonWhitelistedUserDataKeys(user_data) {
    if (!user_data || typeof user_data !== 'object') {
        return {};
    }
    const keysToRemove = Object.keys(user_data).filter(key =>
        !white_list_outgoing_person_fields.includes(key) &&
        !allowed_iterable_h_hash_key_names.includes(key)
    );

    keysToRemove.forEach(key => {
        delete user_data[key];
    });

    return user_data;
}

/**
 * Cleans user data by normalizing it, removing non-whitelisted keys, and empty values.
 * @param {Object} this_new_user_data 
 * @param {Array} array of key names to skip when removing empty values 
 * @returns {Object} cleanedUserData
 */
export const cleanUserData = (this_new_user_data, empty_array_keys_to_skip = empty_array_keys_to_skip) => {
    if (!this_new_user_data) {
        return {};
    }
    this_new_user_data = normalizeUserData(this_new_user_data);

    // Remove any non-whitelisted keys from the user data
    this_new_user_data = removeNonWhitelistedUserDataKeys(this_new_user_data);

    // Remove empty values and arrays from the user data
    this_new_user_data = remove_empty_values_from_object(this_new_user_data, 0, 5, empty_array_keys_to_skip);

    // Ensure only one unique key name for each key
    // TODO do we want to do this? idk. i guess it would be duplicate message type IDs?
    // or maybe like custom field topIssues = ['guns','environment','guns']
    this_new_user_data = removeDuplicateValuesFromArrays(this_new_user_data);

    return this_new_user_data;
}

/**
 * Loops through an object to find arrays n deep. Removes duplicate values from arrays.
 * @param {Object} obj
 * @param {number} depth
 * @param {number} max_depth
 * @returns {Object} obj
 */
export const removeDuplicateValuesFromArrays = (obj, depth = 0, max_depth = 5) => {
    try {
        if (depth > max_depth) {
            return obj;
        }

        Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key])) {
                const arr = obj[key];
                // Skip processing if array is empty or has only one element.
                if (arr.length < 2) {
                    return;
                }

                const seen = new Set();
                const duplicates = new Set();
                const uniqueArr = [];

                // Single pass to collect unique items and record duplicates.
                arr.forEach(item => {
                    if (seen.has(item)) {
                        duplicates.add(item);
                    } else {
                        seen.add(item);
                        uniqueArr.push(item);
                    }
                });

                // Log the actual duplicate values if any were found.
                if (duplicates.size > 0) {
                    console.warn(`Removed duplicate values from array '${key}':`, Array.from(duplicates));
                }
                obj[key] = uniqueArr;
            } else if (obj[key] !== null && typeof obj[key] === 'object') {
                obj[key] = removeDuplicateValuesFromArrays(obj[key], depth + 1, max_depth);
            }
        });

        return obj;
    } catch (e) {
        console.warn('Error removing duplicate values from arrays:', e);
        return obj;
    }
};