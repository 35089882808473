// form_styling.js
// Import Tailwind CSS as a string for Shadow DOM injection
const tailwindCSS = require('../../styles/tailwind.css').default; // Correct import

// Import our custom css
const htmlElementCSS = require('../../styles/html_element.css').default; // Correct import

// Inject Tailwind CSS into the provided container
function inject_tailwind_css(container) {
    if (!container) {
        console.error('No container provided to inject Tailwind CSS');
        return;
    }
    if (!container.querySelector('style[data-tailwind]')) {
        const style = document.createElement('style');
        style.textContent = tailwindCSS;
        style.setAttribute('data-tailwind', '');
        style.type = 'text/css';
        container.appendChild(style);
    }
}

function inject_html_element_css(container) {
    if (!container) {
        console.error('No container provided to inject HTML element CSS');
        return;
    }
    if (!container.querySelector('style[data-html-element]')) {
        const style = document.createElement('style');
        style.textContent = htmlElementCSS;
        style.setAttribute('data-html-element', '');
        style.type = 'text/css';
        container.appendChild(style);
    }
}

function get_tailwind_size_class(size) {
    switch (size) {
        case '1/4':
            return 'w-1/4';
        case '1/2':
            return 'w-1/2';
        case '1/3':
            return 'w-1/3';
        case '2/3':
            return 'w-2/3';
        case '3/4':
            return 'w-3/4';
        case 'full':
            return 'w-full';
        default:
            return 'w-full'; // default if no valid size is found
    }
}

function get_field_width_fraction(size = 'w-full') {
    // size is like 'w-1/2', 'w-full', etc.
    if (size === 'w-full') {
        return 1;
    }
    const match = size.match(/^w-(\d+)\/(\d+)$/);
    if (match) {
        const numerator = parseInt(match[1], 10);
        const denominator = parseInt(match[2], 10);
        if (denominator !== 0) {
            return numerator / denominator;
        }
    }
    return 1; // Default to full width if parsing fails
}

function update_original_height(selected_element) {
    const container = selected_element.querySelector('.data-hub-container');
    if (container) {
        return container.offsetHeight;
    }
}

function run_theme_json_to_base64(theme) {
    if (!theme) {
        console.error('No theme object provided to convert to base64');
        return null;
    }
    return btoa(JSON.stringify(theme));
}

function parse_theme_object_string(context) {
    try {
        const themeString = atob(context.dataset.hubTheme);
        context.passed_theme = JSON.parse(themeString);
    } catch (e) {
        console.error('Error parsing data-hub-theme:', e);
        context.passed_theme = null;
    }
}

function update_brand_colors(context) {
    const colorTypes = ['primary_brand_color', 'contrast_brand_color', 'highlight_brand_color', 'grey_brand_color'];
    colorTypes.forEach(colorType => {
        if (!context[colorType] && context.theme[colorType]?.[600]) {
            context[colorType] = context.theme[colorType][600];
        }
        // i think this is done further down in processing hsl etc. but if do this need to change to make sure [] is {} object not "string"
        // } else if (context[colorType] && !context.theme[colorType]?.[600]) {
        //     // if it's 
        //     context.theme[colorType][600] = context[colorType];
        // }
    });
}

function assign_theming_variables(context) {
    if (context.dataset.hubTheme) {
        parse_theme_object_string(context);
    } else {

        // Check for specific attributes for colors
        context.primary_brand_color = context.getAttribute('data-hub-primary-brand-color');
        context.contrast_brand_color = context.getAttribute('data-hub-contrast-brand-color');
        context.highlight_brand_color = context.getAttribute('data-hub-highlight-brand-color');
        context.grey_brand_color = context.getAttribute('data-hub-grey-brand-color');

        // Update default theme colors if individual attributes are present
        if (context.primary_brand_color) {
            context.default_theme.primary_brand_color = context.primary_brand_color;
        }
        if (context.contrast_brand_color) {
            context.default_theme.contrast_brand_color = context.contrast_brand_color;
        }
        if (context.highlight_brand_color) {
            context.default_theme.highlight_brand_color = context.highlight_brand_color;
        }
        if (context.grey_brand_color) {
            context.default_theme.grey_brand_color = context.grey_brand_color;
        }
    }

    context.theme = context.passed_theme ? { ...context.default_theme, ...context.passed_theme } : context.default_theme;
    update_brand_colors(context);
}

function addBorderClassesToContainer(form_border, data_hub_form_element_container, error_function = window?.data_hub?.error_function) {
    let borderClasses = [];
    let hasAtLeastOneBorderClass = false;
    const defaultBorderClasses = ['shadow-md', 'bg-white', 'rounded-md', 'border', 'border-grey-brand-color-400'];

    if (form_border) {
        if (typeof form_border === 'string') {
            if (form_border.toUpperCase() === 'TRUE') {
                borderClasses = defaultBorderClasses;
                hasAtLeastOneBorderClass = true;
            } else if (form_border.toUpperCase() !== 'FALSE') {
                const splitBorderClasses = form_border.split(',').map(s => s.trim());
                const allowedClassPrefixes = ['border', 'rounded', 'shadow', 'bg', 'm-', 'p-', 'mt-', 'mb-', 'ml-', 'mr-', 'pt-', 'pb-', 'pl-', 'pr-'];
                
                borderClasses = splitBorderClasses.filter(cls => allowedClassPrefixes.some(prefix => cls.startsWith(prefix)));
                hasAtLeastOneBorderClass = borderClasses.length > 0;
            }
        } else {
            error_function('form_border must be a string');
            borderClasses = defaultBorderClasses;
        }
    }

    if (hasAtLeastOneBorderClass) {
        data_hub_form_element_container.classList.add(...borderClasses);
    }
}

function force_tailwind_border_classes() {
    // Force Tailwind border classes to be generated
    const tailwindBorderColors = ['purple', 'emerald', 'rose', 'amber', 'sky', 'indigo', 'fuchsia', 'lime', 'cyan', 'violet', 'orange', 'green', 'red', 'yellow', 'blue', 'teal', 'gray'];

    let borderClassCombinations = [];
    tailwindBorderColors.forEach(color => {
        borderClassCombinations.push(`border-${color}`);
    });

    const tempDiv = document.createElement('div');
    tempDiv.style.display = 'none';
    document.body.appendChild(tempDiv);
    borderClassCombinations.forEach(borderClass => {
        tempDiv.classList.add(borderClass);
    });
    document.body.removeChild(tempDiv);

    return borderClassCombinations;
}

// Export
export {
    force_tailwind_border_classes,
    run_theme_json_to_base64,
    get_field_width_fraction,
    inject_tailwind_css,
    inject_html_element_css,
    get_tailwind_size_class,
    parse_theme_object_string,
    assign_theming_variables,
    update_original_height,
    addBorderClassesToContainer
};
