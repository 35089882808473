// src/forms/sms_optin_intl_tel.js

import intlTelInput from "intl-tel-input/intlTelInputWithUtils";

const telInputCss = require('../styles/tel_input.css').default;

/**
 * Generates a nicely formatted SMS opt-in block that uses intl-tel-input.
 *
 * @param {boolean} allow_prefill
 * @param {Set<string>} set_of_form_inputs_added
 * @param {HTMLFormElement} form
 * @param {Object} data_hub_instance
 * @returns {HTMLDivElement} the SMS opt-in block element
 */
export function generate_sms_optin_intl_tel(
  allow_prefill = true,
  set_of_form_inputs_added,
  form,
  data_hub_instance = window?.data_hub
) {
  if (!form) {
    data_hub_instance.error_function('No form provided to generate_sms_optin_intl_tel');
    return;
  }

  // Remove any existing cell_phone inputs to avoid duplicates.
  if (!form) {
    data_hub_instance.error_function('No form provided to generate_sms_optin_intl_tel');
    return;
  }

  if (set_of_form_inputs_added.has('cell_phone')) {
    form.querySelectorAll('input[name="cell_phone"]').forEach(input => {
      input.parentElement?.remove();
      set_of_form_inputs_added.delete('cell_phone');
    });
  }

  // Create the container.
  const sms_optin_div = document.createElement('div');
  sms_optin_div.classList.add('flex', 'flex-wrap', '-mx-2', 'mb-4', 'mt-4', 'sms_optin_container');

  // Create cell phone input container.
  const cell_phone_div = document.createElement('div');
  cell_phone_div.classList.add('px-2', 'w-2/3', 'add-el-sm-w-full');

  const cell_phone_input = document.createElement('input');
  cell_phone_input.type = 'tel';
  cell_phone_input.classList.add(
    'w-full', 'p-2', 'border', 'border-grey-brand-color-400', 'rounded-md'
  );
  cell_phone_input.placeholder = 'Cell Phone';
  cell_phone_input.name = 'cell_phone';
  cell_phone_input.autocomplete = 'tel';
  if (allow_prefill) {
    cell_phone_input.dataset.allowDataHubPrefill = 'true';
  }
  cell_phone_input.dataset.removeForUnsub = 'true';
  cell_phone_input.id = 'cell_phone';

  cell_phone_div.appendChild(cell_phone_input);

  // Create SMS opt-in checkbox container.
  const sms_optin_checkbox_div = document.createElement('div');
  sms_optin_checkbox_div.classList.add('px-2', 'flex', 'items-center', 'w-1/3', 'add-el-sm-w-full', 'add-el-sm-mt-4');

  const sms_optin_checkbox = document.createElement('input');
  sms_optin_checkbox.type = 'checkbox';
  sms_optin_checkbox.name = 'sms_optin';
  sms_optin_checkbox.checked = true;
  if (allow_prefill) {
    sms_optin_checkbox.dataset.allowDataHubPrefill = 'true';
  }
  sms_optin_checkbox.dataset.removeForUnsub = 'true';
  sms_optin_checkbox.classList.add('w-5', 'h-5', 'sms_optin_checkbox', 'flex');// 'checked:bg-emerald-600', 'border-emerald-600'
  sms_optin_checkbox.id = 'sms_optin';

  const sms_optin_label = document.createElement('label');
  sms_optin_label.innerHTML = `<span>Send me texts!</span>
  <div style="width:38px;">
    <svg
      width="38"
      height="30"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Bubble shape -->
      <path
        fill="#4285f4"
        d="
          M5 5
          h26
          c1.66 0 3 1.34 3 3
          v10
          c0 1.66 -1.34 3 -3 3
          H13
          l-5 5
          v-5
          H5
          c-1.66 0 -3 -1.34 -3 -3
          V8
          c0 -1.66 1.34 -3 3 -3
          z
        "
      />

      <!-- Dot 1 -->
      <circle cx="14" cy="12" r="2" fill="#fff">
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="1.2s"
          repeatCount="indefinite"
          begin="0s"
        />
      </circle>

      <!-- Dot 2 -->
      <circle cx="19" cy="12" r="2" fill="#fff">
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="1.2s"
          repeatCount="indefinite"
          begin="0.2s"
        />
      </circle>

      <!-- Dot 3 -->
      <circle cx="24" cy="12" r="2" fill="#fff">
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="1.2s"
          repeatCount="indefinite"
          begin="0.4s"
        />
      </circle>
    </svg>
  </div>`;

  sms_optin_label.htmlFor = 'sms_optin';
  sms_optin_label.classList.add('text-gray-700', 'text-sm', 'ml-2', 'mt-0', 'w-full', 'flex', 'items-center', 'justify-between');

  sms_optin_checkbox_div.appendChild(sms_optin_checkbox);
  sms_optin_checkbox_div.appendChild(sms_optin_label);

  sms_optin_div.appendChild(cell_phone_div);
  sms_optin_div.appendChild(sms_optin_checkbox_div);

  set_of_form_inputs_added.add('cell_phone');
  set_of_form_inputs_added.add('sms_optin');

  return sms_optin_div;
}

/**
 * Gets the user's country code from data_hub re-check since was run async during page_metadata creation
 * @param {Function} callback
 * @returns {Function} callback
 */
// export function re_get_user_country_code_with_tel_callback(callback) {
//   const countryCode = window?.data_hub?.user_data?.country_code ||
//     window?.data_hub?.page_metadata?.ip_data?.country_code;

//   if (countryCode) {
//     callback(countryCode);
//   } else {
//     console.warn('DEBUG Country code could not be retrieved');
//     callback('US');
//   }
// }

/**
 * Inject the tel-input-intl css into the parent document.
 * @param {HTMLElement} container - The container to inject CSS into.
 * @param {Object} data_hub_instance - The data hub instance for logging errors.
 */
export const inject_tel_intl_input_css = (container, data_hub_instance = window?.data_hub) => {
  if (!container) {
    data_hub_instance.error_function('No container provided to inject inject_tel_intl_input_css CSS');
    // Find the top most div inside document, or fallback to head for styles
    container = document.querySelector('.data-hub-container') || 
                document.querySelector('.data-hub-form-container') || 
                document.head;
    if (!container) {
      data_hub_instance.error_function('No valid container found to inject_tel_intl_input_css CSS');
      return;
    }
  }
  
  // Check if style already exists anywhere in the document to prevent duplicates
  if (!document.querySelector('style[data-intl-tel-input]')) {
    const style = document.createElement('style');
    style.textContent = telInputCss;
    style.setAttribute('data-intl-tel-input', '');
    style.type = 'text/css';
    container.appendChild(style);
  }
}

/**
 * Bind all telephone inputs in a container with intl-tel-input
 * Track user interaction to prevent unwanted value updates
 * @param {HTMLElement} data_hub_most_parent_container - The container to search for tel inputs
 * @param {Object} data_hub_instance - The data hub instance
 * @returns {Array} - Array of intl-tel-input instances
 */
export const bind_all_tel_inputs_intl = async (
  data_hub_most_parent_container,
  data_hub_instance = window?.data_hub
) => {
  if (!data_hub_most_parent_container) {
    data_hub_instance.error_function('No container provided to bind_all_tel_inputs_intl');
    return [];
  }

  const country_code =
    data_hub_instance?.user_data?.country_code ||
    data_hub_instance?.page_metadata?.ip_data?.country_code ||
    window?.data_hub_user_data?.country_code ||
    'US';

  const tel_inputs = data_hub_most_parent_container.querySelectorAll('input[type="tel"]');
  if (!tel_inputs || tel_inputs.length === 0) {
    return [];
  }

  // Inject the tel-input-intl css into the parent document.
  inject_tel_intl_input_css(data_hub_most_parent_container, data_hub_instance);

  if (typeof intlTelInput !== 'function') {
    data_hub_instance.error_function('intlTelInput is not a function' + typeof intlTelInput);
    return [];
  }

  const new_drop_down_container = document.createElement('div');
  new_drop_down_container.classList.add('iti__country-list--independent');
  data_hub_most_parent_container.appendChild(new_drop_down_container);

  intlTelInput.defaults.dropdownContainer = new_drop_down_container;

  // Use Promise.all to wait for all initialization promises.
  const iti_inputs = await Promise.all(
    Array.from(tel_inputs).map(async (input) => {
      // Optionally check if already initialized:
      if (input.dataset.intlTelInputInit) {
        return input.itiInstance; // if previously attached
      }

      input.dataset.intlTelInputInit = true;
      input.dataset.userInteracted = "false";
      input.dataset.focused = "false";

      const itiInstance = intlTelInput(input, {
        initialCountry: country_code || "US",
        countryOrder: ["us", "gb", "ca", "au"],
        placeholderNumberType: 'MOBILE',
        showFlags: true,
        allowDropdown: true,
        countrySearch: true,
        formatAsYouType: true,
        formatOnDisplay: true,
        separateDialCode: true,
        separateDialCode: false,
      });

      // Optionally adjust styles
      input.style.paddingLeft = '45px';

      // Add event listeners to track user interaction
      input.addEventListener('focus', () => {
        input.dataset.focused = "true";
      });

      input.addEventListener('blur', () => {
        input.dataset.focused = "false";
      });

      input.addEventListener('input', () => {
        input.dataset.userInteracted = "true";
        input.dataset.lastInteraction = Date.now();
      });

      // Store the instance on the input element for later access.
      input.itiInstance = itiInstance;

      return itiInstance;
    })
  );

  return iti_inputs;
};

/** 
 * Process form input submission values. Look for tel inputs and use intl-tel verification functions.
 * @param {HTMLFormElement} form
 * @param {Array} array of iti_intl inputs
 * @returns {Array[Object]} Returns a promise that resolves when processing is complete.
 */
export async function process_verify_tel_input_values(form_elements, error_function = window?.data_hub?.error_function) {
  if (!form_elements || form_elements.length === 0) {
    return error_function('No form provided to process_verify_tel_input_values');
  }

  let processed_phone_numbers = [];

  const errorMap = {
    0: "Invalid number",
    1: "Invalid country code",
    2: "Phone number too short",
    3: "Phone number too long",
    4: "Invalid number"
  };

  form_elements.forEach(form_element => {
    // Check for tel inputs and that the iti instance exists on the element.
    if (form_element.type === 'tel' && form_element.itiInstance) {
      const itiInstance = form_element.itiInstance;

      if (!itiInstance) {
        error_function('No intl-tel-input instance found on input:', form_element);
        return;
      }

      const phoneNumber = itiInstance.getNumber();
      const isRequired = form_element.required || form_element.getAttribute('aria-required') === 'true';
      const isEmpty = !phoneNumber || phoneNumber.replace(/[+\s-]/g, '').length === 0;

      let this_phone_number_response = {
        domElement: form_element,
        phoneNumber: phoneNumber,
        isValid: itiInstance.isValidNumber(),
        error: null,
        errorMessage: null,
        country_code: null,
        country: null,
        isEmpty: isEmpty,
        isRequired: isRequired
      };

      // If field is not required and empty/minimal, consider it valid
      if (!isRequired && isEmpty) {
        this_phone_number_response.isValid = true;
      } else {

        const countryData = itiInstance.getSelectedCountryData();
        if (countryData && countryData.iso2 && typeof countryData.iso2 === 'string') {
          this_phone_number_response.country_code = countryData.iso2.toUpperCase();
        }
        if (countryData && countryData.name && typeof countryData.name === 'string') {
          this_phone_number_response.country = countryData.name;
        }

        if (!this_phone_number_response.isValid) {
          const validationErrorCode = itiInstance.getValidationError() || 0;
          const errorMessage = errorMap[validationErrorCode] || "Invalid number";
          this_phone_number_response.errorMessage = errorMessage;
          this_phone_number_response.error = validationErrorCode;
        }

      }

      processed_phone_numbers.push(this_phone_number_response);
    }
  });

  return processed_phone_numbers;
}

export const set_listener_for_new_user_data = async () => {
  window.addEventListener("data-hub-new-user-data", (e) => {
      listen_for_new_user_data_and_rebind_tel_inputs(e.detail);
  });
};

/**
 * Listen for the event data-hub-new-user-data, find our tel inputs, and update country_code if it exists in user_data
 * Respects user interaction - won't update fields the user is actively using
 */
export const listen_for_new_user_data_and_rebind_tel_inputs = (
  event_detail
) => {
  if (!event_detail) {
    return error_function && error_function('No event provided to listen_for_new_user_data_and_rebind_tel_inputs');
  }

  const data_hub_instance = event_detail?.data_hub_instance;
  // Check if event_detail.user_data is null or {} empty
  const user_data = event_detail?.user_data && Object.keys(event_detail.user_data).length > 0 
    ? event_detail.user_data 
    : data_hub_instance?.user_data || window?.data_hub_user_data;

  if (!user_data) {
    return;
  }
  const error_function = data_hub_instance?.error_function || window?.data_hub?.error_function || (() => {console.error('No error function provided')});

  if (!data_hub_instance) {
    return error_function && error_function('No data hub instance provided to listen_for_new_user_data_and_rebind_tel_inputs');
  }

  try {

    const userCountryCode = user_data.country_code;
    
    const updateTimeout = data_hub_instance?.prefill_timeout || 2000;
    const currentTime = Date.now();

    if (!data_hub_instance?.iti_inputs || !data_hub_instance.iti_inputs.length) {
      return;
    }

    data_hub_instance.iti_inputs.forEach(itiInstance => {
      if (!itiInstance || !itiInstance.telInput) {
        return;
      }

      const inputElement = itiInstance.telInput;
      const userInteracted = inputElement.dataset.userInteracted === "true";
      const isCurrentlyFocused = inputElement.dataset.focused === "true";
      const lastInteraction = parseInt(inputElement.dataset.lastInteraction || 0);
      const timeElapsed = currentTime - lastInteraction;

      // Skip if user is actively interacting with this input
      if (userInteracted || isCurrentlyFocused || (lastInteraction && timeElapsed < updateTimeout)) {
        return;
      }

      const parsedNumber = itiInstance.getNumber(); // Current number in E.164 format
      const inputName = inputElement.name || inputElement.id;

      if (inputName && user_data[inputName]) {
        if (parsedNumber !== user_data[inputName]) {
          itiInstance.setNumber(user_data[inputName]);
        }
      }

      // Update the selected country if it differs from the userData country code
      if (userCountryCode && itiInstance?.getSelectedCountryData()?.iso2 && 
          itiInstance.getSelectedCountryData().iso2 !== userCountryCode.toLowerCase()) {
        itiInstance.setCountry(userCountryCode.toLowerCase());
      }
    });

  } catch (e) {
    error_function && error_function('Error listening for new user data:' + e);
  }
};

/**
 * Reset user interaction flags for telephone inputs, typically used after form submission/reset
 * @param {HTMLElement} container - The container with tel inputs to reset
 */
export const reset_tel_input_interaction_flags = (container) => {
  if (!container) return;

  const telInputs = container.querySelectorAll('input[type="tel"]');
  telInputs.forEach(input => {
    input.dataset.userInteracted = "false";
    input.dataset.focused = "false";
    delete input.dataset.lastInteraction;
  });
};

/**
 * Callback for prefill. If has intl-tel-input instance, look for inputs that have matching key->name values 
 * and call setNumber and setCountry.
 *
 * @param {Object} user_data - The user data object containing phone numbers and country codes.
 * @param {HTMLBaseElement} container - The container to search for tel inputs.
 * @param {Function} error_function - The function to handle errors (defaults to window.data_hub.error_function).
 */
export const prefill_refill_tel_inputs = (
  user_data,
  container,
  error_function = window?.data_hub?.error_function,
  data_hub_instance = window?.data_hub
) => {
  try {
    if (!container) {
      return error_function("No container provided to prefill_refill_tel_inputs");
    }

    const telInputs = container.querySelectorAll('input[type="tel"]');

    if (!telInputs || telInputs.length === 0) {
      return;
    }

    telInputs.forEach(inputElement => {
      const inputName = inputElement.name;
      if (!inputName) {
        return;
      }

      // Don't update if user has already interacted with this input
      const updateTimeout = data_hub_instance?.prefill_timeout || 2000; // 2 seconds timeout
      const currentTime = Date.now();
      const userInteracted = inputElement.dataset.userInteracted === "true";
      const isCurrentlyFocused = inputElement.dataset.focused === "true";
      const lastInteraction = parseInt(inputElement.dataset.lastInteraction || 0);
      const timeElapsed = currentTime - lastInteraction;

      if (userInteracted || isCurrentlyFocused || timeElapsed < updateTimeout) {
        return;
      }

      // Check if intl-tel-input is initialized
      if (!inputElement.dataset.intlTelInputInit || !inputElement.itiInstance) {
        if (user_data[inputName]) {
          inputElement.value = user_data[inputName];
        }
        return;
      }

      const itiInstance = inputElement.itiInstance;
      // If user data has a value for this input, set the formatted number
      if (user_data[inputName]) {
        itiInstance.setNumber(user_data[inputName]);
      }

      // Update country code if provided and valid
      if (user_data.country_code && typeof user_data.country_code === 'string') {
        let country_code = user_data.country_code.trim();
        if (country_code === '' || country_code.toLowerCase() === 'null' || country_code.toLowerCase() === 'undefined') {
          return;
        }
        const currentCountry = itiInstance?.getSelectedCountryData()?.iso2;
        const newCountryCode = country_code.toLowerCase();
        if (currentCountry !== newCountryCode) {
          itiInstance.setCountry(newCountryCode);
        } 
      }
    });
  } catch (e) {
    error_function(`Error in prefill_refill_tel_inputs: ${e.message}`);
  }
};