// src/helpers/form_validation.js
import { user_data_form_field_placeholders, at_least_one_required_form_identifier_fields } from "./static_vars";

/**
 * Changes the element's border color to red if it's required and empty.
 * @param {HTMLElement} element - The element to validate.
 * @param {boolean} markRed - Whether to mark the element red or not.
 */
export function changeElementErrorStatus(element, markRed) {
    if (markRed) {
        // Add error styling.
        element.classList.add('border-red-500');

        // Only add the input event listener if it hasn't been added yet.
        if (!element.dataset.errorListenerAdded) {
            element.addEventListener('input', () => {
                if (element.value.trim()) {
                    element.classList.remove('border-red-500');
                }
            });
            element.dataset.errorListenerAdded = "true";
        }
    } else {
        // Remove only error styling.
        element.classList.remove('border-red-500');
        // If has error listener, remove it.
        // TODO is this correct? we dont want to have race conditions or looping over itself
        if (element.dataset.errorListenerAdded) {
            const errorListener = () => {
                if (element.value.trim()) {
                    element.classList.remove('border-red-500');
                }
            };
            element.removeEventListener('input', errorListener);
            delete element.dataset.errorListenerAdded;
        }
    }
}

/**
 * Shows red border and error message for missing required fields.
 *
 * @param {NodeList} form_elements - The form elements to validate.
 * @param {Array} requiredFields - An array of possibly required fields names.
 * @returns {Array} An array of missing required fields names.
 */
export const showRedBorderRequiredFieldsMissingValues = (form_elements, requiredFields = at_least_one_required_form_identifier_fields) => {
    let missingRequiredFields = [];
    let hasAtLeastOneRequiredField = false;

    Array.from(form_elements).forEach(element => {

        // Check if is in the minimal required user_data field array and has value
        if (requiredFields.includes(element.id || element.name) && element.value.trim()) {
            hasAtLeastOneRequiredField = true;
        }

        // First run if it's input.required and has no value change it to red
        const isRequiredAndEmpty = element.required && !element.value.trim();
        if (isRequiredAndEmpty) { missingRequiredFields.push(element.id || element.name); }
        changeElementErrorStatus(element, isRequiredAndEmpty);

    });

    if (!hasAtLeastOneRequiredField) {
        // Missing at least 1 required user data identifier
        Array.from(form_elements).forEach(element => {
            const elementNameOrId = element.id || element.name;
            if (requiredFields.includes(elementNameOrId)) {
                // change the actual element to be required so they cant submit again without value
                element.required = true;
                missingRequiredFields.push(elementNameOrId);
                changeElementErrorStatus(element, true);
            }
        });
    }

    // Only return non duplicates missingRequiredFields
    return Array.from(new Set(missingRequiredFields));
};


/**
 * Generates a comma separated string of the missing required fields.
 * @param {Array} array of missing required fields names.
 * @returns {string} A comma separated string of the missing required fields.
 */
export const generateMissingRequiredFieldsString = (missingRequiredFields) => {
    let missingRequiredFieldsString = null;

    if (missingRequiredFields.length > 0) {
        const friendlyNames = missingRequiredFields.map(key => user_data_form_field_placeholders[key] || key);

        if (friendlyNames.length === 1) {
            missingRequiredFieldsString = friendlyNames[0];
        } else if (friendlyNames.length === 2) {
            missingRequiredFieldsString = `${friendlyNames[0]} and ${friendlyNames[1]}`;
        } else {
            missingRequiredFieldsString = friendlyNames.slice(0, -1).join(', ') + ', and ' + friendlyNames[friendlyNames.length - 1];
        }
    }

    return missingRequiredFieldsString;
}

/**
 * Verifies that the action array is valid.
 * @param {Array} action_array - Array of action objects to validate.
 * @param {Function} error_function - Error handling function to call on validation failure.
 * @returns {boolean} True if valid, otherwise calls error_function.
 */
export function verify_action_array(action_array, error_function) {
    if (!Array.isArray(action_array)) {
        return error_function('Action array is not an array');
    }
    // Each object must contain exactly two keys: verb and data.
    for (const action of action_array) {
        if (typeof action !== 'object') {
            return error_function('Action array contains non-object');
        }
        if (Object.keys(action).length !== 2) {
            return error_function(`Action object does not have 2 keys: ${Object.keys(action)}`);
        }
        if (!action.verb || !action.data) {
            return error_function(`Action object does not have verb and data keys: ${Object.keys(action)}`);
        }
        if (!['i_api', 'data_hub_events', 'iterable_api'].includes(action.verb)) {
            return error_function(`Action object verb key is not i_api, data_hub_events, or iterable_api: ${action.verb}`);
        }
    }
    return true;
}