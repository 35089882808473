// forms/html_element_helpers/user_data_validation.js

import { 
    validateTwilioVerifyNumber, 
    validateEmailDomElements
} from "../../forms/html_element_helpers/validation_ui_elements";

import isEmail from 'validator/lib/isEmail';

/**
 * Validates the user_data object to determine if at least one required field has a non-empty value.
 *
 * @param {Object} user_data - The user_data object (expected to be flat – required keys are first order).
 * @param {Function} error_function - Optional error function to log errors.
 * @returns {Object} result - An object with:
 *    - valid: {boolean} true if at least one required field has a valid (non-empty) value.
 *    - missing: {Array} an array of required key names that are missing or empty.
 */
export const userDataRequiresAtLeastOneIdField = (
    user_data,
    requiredKeys = at_least_one_required_form_identifier_fields,
    error_function = window?.data_hub?.error_function
) => {
    if (!requiredKeys || requiredKeys.length === 0) {
        requiredKeys = at_least_one_required_form_identifier_fields;
    }
    const missingRequiredFields = [];
    let hasAtLeastOneValue = false;

    if (!user_data || typeof user_data !== 'object') {
        if (error_function) {
            error_function('userDataRequiresAtLeastOneIdField: user_data is not an object.', null);
        }
        return { valid: false, missing: [] };
    }

    requiredKeys.forEach(key => {
        const value = user_data[key];
        let isValid = false;

        // Check only the required keys
        if (value !== undefined && value !== null) {
            if (typeof value === 'string') {
                if (value.trim() !== '') {
                    isValid = true;
                }
                // They're all strings but i guess future proof:
            } else if (Array.isArray(value)) {
                if (value.length > 0) {
                    isValid = true;
                }
            } else if (typeof value === 'object') {
                if (Object.keys(value).length > 0) {
                    isValid = true;
                }
            } else if (typeof value === 'number') {
                isValid = true;
            } else if (typeof value === 'boolean') {
                isValid = true;
            }
        }

        if (isValid) {
            hasAtLeastOneValue = true;
        } else {
            missingRequiredFields.push(key);
        }
    });

    return { valid: hasAtLeastOneValue, missing: missingRequiredFields };
};


/**
 * Validates the user_data object to determine if all required fields have a non-empty value.
 *
 * @param {Object} new_user_data - The new returned with person_format user_data object
 * @param {Object} original_user_data - The original user provided (or cached) user_data object
 * @param {Object} third_party_results - The array of results objects from third party validation services
 * @param {Object} data_hub_instance - the data hub reference object.
 * @param {Document} form_container - The document or shadowRoot to create elements with.
 * @returns {Array<HTMLElement>} Array of validation UI elements
 */
export const validateAgainstNewUserData = async (
    new_user_data, original_user_data, third_party_results = [], data_hub_instance = window?.data_hub, form_container = document
) => {
    try {
        if (!new_user_data || typeof new_user_data !== 'object') {
            data_hub_instance?.error_function('Error validating against new user data: new_user_data is not an object.');
            return [];
        }

        if (!original_user_data || typeof original_user_data !== 'object') {
            data_hub_instance?.error_function('Error validating against new user data: original_user_data is not an object.');
            return [];
        }

        let validationDomElements = [];

        if (!third_party_results || !Array.isArray(third_party_results) || third_party_results.length === 0) {
            return [];
        }

        // Loop through third party results
        third_party_results.forEach(result => {
            if (result?.third_party) {
                // Phone number validation
                if (result.third_party === 'twilio_verify_number') {
                    const returnedDomElement = validateTwilioVerifyNumber(new_user_data, original_user_data, result, data_hub_instance, form_container);
                    if (returnedDomElement && returnedDomElement !== null && typeof returnedDomElement !== 'string') {
                        validationDomElements.push(returnedDomElement);
                    }
                } 
                // Email validation
                else if (result.third_party === 'email_verify') {
                    const returnedDomElement = validateEmailDomElements(new_user_data, original_user_data, result, data_hub_instance, form_container);
                    if (returnedDomElement && returnedDomElement !== null && typeof returnedDomElement !== 'string') {
                        validationDomElements.push(returnedDomElement);
                    }
                }
            }
        });

        // Ensure we're using the correct document context
        const doc = form_container.ownerDocument || form_container;

        // Add fade-out animation CSS if it doesn't exist yet
        if (!doc.getElementById('validation-fade-out-style')) {
            const style = doc.createElement('style');
            style.id = 'validation-fade-out-style';
            style.textContent = `
                .phone-error-container, .email-error-container {
                    transition: opacity 0.3s ease-out;
                }
                .fade-out {
                    opacity: 0;
                }
            `;
            doc.head.appendChild(style);
        }

        return validationDomElements;
    } catch (error) {
        data_hub_instance?.error_function('Error validating against new user data: ' + error);
        return [];
    }
};

/**
 * Validate a user's email address with improved checks
 * @param {string} email - The email address to validate
 * @returns {{ valid: boolean, error_message: string | null }}
 */
export const validateEmail = (email) => {
    try {
        // Ensure email is provided and not empty
        if (!email || !email.trim()) {
            // Do not return false for empties here. We want to allow non email forms. Checks for required before this function
            return { valid: true, error_message: null };
        }

        // Trim whitespace
        email = email.trim();

        // Minimum plausible length (e.g., a@b.co)
        if (email.length < 6) {
            return { valid: false, error_message: 'Email is too short' };
        }

        // Use validator's built-in email validation first
        if (!isEmail(email)) {
            return { valid: false, error_message: 'Email is invalid' };
        }

        // Custom regex for additional structure validation
        // - Exactly one @ symbol
        // - Domain has at least one dot
        // - No consecutive dots in domain
        // - TLD at least 2 characters
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

        if (!emailRegex.test(email)) {
            return { valid: false, error_message: 'Email is incorrectly formatted' };
        }

        // Check email ends with a letter (avoid ending with punctuation/numbers)
        if (!/[a-zA-Z]$/.test(email)) {
            return { valid: false, error_message: 'Email must end with a letter' };
        }

        // Validate @ position (not at start or end)
        const atPos = email.indexOf('@');
        if (atPos <= 0 || atPos >= email.length - 3) { // At least two chars after @
            return { valid: false, error_message: 'Email is incorrectly formatted' };
        }

        return { valid: true, error_message: null };
    } catch (e) {
        return { valid: false, error_message: 'Error validating email' };
    }
};