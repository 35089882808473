/**
 * Retrieves metadata form fields from the given container.
 * @param {HTMLElement|NodeList} form_container - Container element(s) for the form.
 * @returns {Object} An object of metadata key/value pairs.
 */
export function get_metadata_form_fields(form_container, data_hub_instance = window?.data_hub) {
    if (!form_container) {
        form_container = data_hub_instance?.form_containers 
                        || document.querySelectorAll(data_hub_instance?.form_container_selector
                        || '.data-hub-form-container');
    }
    
    // Ensure data_hub_instance exists and has the form_metadata_form_field_selector property
    if (!data_hub_instance || !data_hub_instance.form_metadata_form_field_selector) {
        return {};
    }
    
    let metadata = {};
    
    // Handle NodeList (result of querySelectorAll)
    if (form_container.forEach || form_container instanceof NodeList) {
        // Iterate through each container in the NodeList
        form_container.forEach(container => {
            const container_fields = container.querySelectorAll(data_hub_instance.form_metadata_form_field_selector);
            if (container_fields && container_fields.length > 0) {
                container_fields.forEach(field => {
                    const key = field.id || field.name;
                    if (key) {
                        metadata[key] = field.value;
                    }
                });
            }
        });
    } else {
        // Handle single container element
        const metadata_fields = form_container.querySelectorAll(data_hub_instance.form_metadata_form_field_selector);
        if (metadata_fields && metadata_fields.length > 0) {
            metadata_fields.forEach(field => {
                const key = field.id || field.name;
                if (key) {
                    metadata[key] = field.value;
                }
            });
        }
    }
    
    return metadata;
}