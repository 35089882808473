// src/forms/data_hub_forms.js
import merge from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import {
    fetch_with_timeout,
    parse_boolean_falsy_types,
} from '../helpers';

import {
    get_metadata_form_fields
} from '../helpers/form_process_functions';

import {
    white_list_incoming_person_fields,
    allowed_iterable_h_hash_key_names,
    form_input_types_string,
    empty_array_keys_to_skip,
    at_least_one_required_form_identifier_fields
} from '../helpers/static_vars';
import { 
    showRedBorderRequiredFieldsMissingValues,
    generateMissingRequiredFieldsString,
    changeElementErrorStatus,
    verify_action_array
} from '../helpers/form_validation';
import {
    add_form_input_loading_state,
    remove_form_input_loading_state,
    add_form_loading_overlay,
    remove_form_loading_overlay,
    add_submit_button_loading_state,
    remove_submit_button_loading_state,
    prefill_form,
    re_prefill_all_forms,
    return_error_clear_form_loading_state,
    hide_on_submit
} from '../forms/shared_form_ux_functions';
import { create_person_format_modifications_ux_elements } from "../forms/html_element_helpers/validation_ui_elements";
import { is_under_18, cleanUserData } from '../helpers/get_process_user_data';
import { userDataRequiresAtLeastOneIdField, validateEmail } from '../forms/html_element_helpers/user_data_validation';
import { process_verify_tel_input_values } from '../tel/telephone_sms_inputs';
import { send_jwt_cookie_to_cors_iframe } from './cors_iframe';

// INDIVIDUAL FORMS
export class data_hub_form {
    /**
     * Constructs a new data_hub_form instance.
     * @param {Object} config - Configuration object.
     * @param {Object} [config.data_hub_instance] - Instance of data_hub (or defaults to window.data_hub).
     * @param {number} [config.prefill_timeout] - Timeout for pre-filling the form.
     * @param {number} [config.post_person_timeout] - Timeout for the form POST request.
     * @param {string} [config.prefill_input_selector] - Selector for form fields to prefill.
     * @param {Function} [callback_function_after_init] - Callback to run after initialization.
     */
    constructor(config = {}, callback_function_after_init = () => { }) {
        // CONFIG
        this.data_hub = config?.data_hub_instance || window.data_hub;
        this.prefill_timeout = config?.prefill_timeout || this.data_hub.prefill_timeout || 1300;
        this.post_person_timeout = config?.post_person_timeout || 10000;
        this.prefill_input_selector = config?.prefill_input_selector || this.data_hub.prefill_input_selector || '[data-allow-data-hub-prefill="true"]';
        this.loading_overlay_selector = config?.loading_overlay_selector || '.data-hub-loading-overlay';

        // Shared or global data_hub functions
        this.pre_render_function = config?.pre_render_function || (() => { });
        this.pre_submit_function = config?.pre_submit_function || (() => { });
        this.error_function = config?.error_function || this.data_hub?.error_function ||
            ((internal_error_message, external_error_message) => { console.error(internal_error_message, external_error_message); });
        this.return_function = config?.return_function || (() => { }); //((document, results, self) => { console.log(results); });
        this.unload_function = config?.unload_function || this.data_hub?.unload_function ||
            (() => { });

        if (!this.data_hub) {
            return this.error_function('No data_hub found', 'Please contact support');
        }

        this.init_data(callback_function_after_init);
    }

    /**
     * Initializes data and calls the callback after initialization.
     * @param {Function} callback_function_after_init - Callback to invoke after data initialization.
     */
    async init_data(callback_function_after_init) {
        if (typeof callback_function_after_init === 'function') {
            callback_function_after_init();
        }
    }

    /**
     * Initializes the form by binding event listeners, pre-filling data, and setting up metadata.
     * @param {Object} settings - Configuration settings for the form.
     */
    init(settings = {}) {
        // Select the standard form elements to attach click events
        this.form_container_selector = settings.form_container_selector || '.data-hub-container';
        this.form_containers = settings.form_container
            ? (settings.form_container instanceof HTMLElement ? [settings.form_container] : settings.form_container)
            : document.querySelectorAll(this.form_container_selector);

        this.submit_button_selector = settings.submit_button_selector || '.data-hub-submit-form';
        this.submit_buttons = settings.submit_button
            ? (settings.submit_button instanceof HTMLElement ? [settings.submit_button] : settings.submit_button)
            : Array.from(document.querySelectorAll(this.submit_button_selector));

        this.loading_overlay_selector = settings.loading_overlay_selector || '.data-hub-loading-overlay';

        this.reset_button_selector = settings.reset_button_selector || '.data-hub-reset-form';
        this.hide_on_submit_selector = settings.hide_on_submit_selector || '.hide-on-return-function';
        this.form_metadata_form_field_selector = settings.metadata_form_field_selector || '[data-hub-form-metadata-field="true"]';

        // Form data
        this.events = settings.events || [];
        this.returns = settings.returns || {};
        this.form_metadata = settings.form_metadata || {};
        this.custom_data = settings.custom_data || {};

        // Settings
        this.return_submit_function_immediately = settings.return_submit_function_immediately || false;

        // Functions
        this.pre_render_function = settings.pre_render_function || this.pre_render_function || (() => { });
        this.pre_submit_function = settings.pre_submit_function || this.pre_submit_function || (() => { });
        this.error_function = settings.error_function || this.data_hub.error_function ||
            ((internal_error_message, external_error_message) => { console.error(internal_error_message, external_error_message); });
        this.return_function = settings.return_function || this.return_function || (() => { });
        this.unload_function = settings.unload_function || this.unload_function || (() => { });

        // Pre Fills
        this.has_at_least_one_prefill = false;
        this.allow_prefill = settings.allow_prefill !== undefined ? settings.allow_prefill : true;

        // Check for existing form containers
        if (!this.form_containers || this.form_containers.length === 0) {
            return this.error_function('No form containers found', 'Please contact support');
        }

        // Ensure form_containers is always an array
        if (!Array.isArray(this.form_containers)) {
            this.form_containers = Array.from(this.form_containers);
        }

        // Bind submit and reset events for each form based on passed-in selectors
        this.form_containers.forEach(form_container => {
            this.pre_render_function(form_container, this);

            let bound_at_least_one_submit = false;
            const forms = form_container.querySelectorAll('form');
            forms.forEach(form => {
                // Default submit events, to capture clicks even if mismatched from passed selector
                if (form.querySelector('input[type="submit"], button[type="submit"], input[type="image"]')) {
                    bound_at_least_one_submit = true;
                }

                form.addEventListener('submit', (event) => {
                    event.preventDefault();
                    this.submit_form_data(event, form_container);
                });
            });

            // Handling potential double-binding issue
            const submit_buttons = this.submit_buttons || form_container.querySelectorAll(this.submit_button_selector);
            submit_buttons.forEach(submit_button => {
                if (submit_button && !submit_button.dataset.bound) {
                    submit_button.dataset.bound = 'true';  // Flag to indicate binding
                    bound_at_least_one_submit = true;
                    submit_button.onclick = (event) => {
                        event.preventDefault();
                        this.submit_form_data(event, form_container);
                    };
                }
            });

            // If no bound submits, return error
            if (!bound_at_least_one_submit) {
                return this.error_function('No submit buttons found', 'Please contact support');
            }

            // Prefill form fields if applicable
            if (this.allow_prefill || this.data_hub.prefill_all_forms) {
                if (this.data_hub.user_data_loaded_promise) {
                    this.data_hub.user_data_loaded_promise.then(() => {
                        prefill_form(
                            settings.form_container || document,
                            this.data_hub?.user_data || {},
                            this.prefill_input_selector,
                            this.reset_button_selector
                        );
                    }).catch((error) => {
                        this.error_function(`Error loading user data: ${error}`, null);
                    });
                } else {
                    prefill_form(settings.form_container || document, {}, this.prefill_input_selector);
                }
            } else {
                prefill_form(settings.form_container || document, {}, this.prefill_input_selector);
            }
        });

        // TODO setup and test in future if (this.unload_function) {
    }

    /**
     * Handles form submission: processes user input, updates the central user_data, validates required fields,
     * merges metadata, and communicates with the API.
     *
     * IMPORTANT: Ensure that user_data is updated with the latest form values. For example, if the global data_hub.user_data.first_name
     * was "old name" and the input field now contains "dillon", this value should be updated accordingly.
     *
     * @param {Event} event - The form submission event.
     * @param {HTMLElement} form_container - The container element for the form.
     * @param {Object} [returns] - An object describing the expected HTTP endpoint returns. The default is:
     * @param {string} [returns.user_data] - A string template for user data, e.g., "{{user_data}}".
     * @param {string} [returns.metadata] - A string template for metadata, e.g., "{{metadata}}".
     * @param {string} [returns.third_party_results] - A string template for third-party results, e.g., "{{third_party_results}}".
     * @param {string} [returns.event_uuid] - A string template for the event UUID, e.g., "{{event_uuid}}".
     * @param {string} [returns.jwt_cookie] - A string template for the JWT cookie, e.g., "{{jwt_cookie}}".
     * @returns {Promise<*>} The result of the return_function, or an error if submission fails.
     */
    async submit_form_data(
        event,
        form_container, 
        returns = {
            user_data:"{{user_data}}", metadata:"{{metadata}}", 
            third_party_results: "{{third_party_results}}", event_uuid:"{{event_uuid}}", jwt_cookie: "{{jwt_cookie}}"
        }
    ) {
        let response_data = {};
        let already_called_return_function = false;
    
        try {
            event.preventDefault();
    
            if (!form_container) {
                return this.error_function('No form container found', 'Please contact support');
            }
    
            add_submit_button_loading_state(form_container, this.submit_button_selector);
            add_form_input_loading_state(form_container, form_input_types_string, this.data_hub);
            add_form_loading_overlay(form_container, this.loading_overlay_selector, this.data_hub);
    
            let form_elements = form_container.querySelectorAll(form_input_types_string);
            if (!form_elements || !form_elements.length) {
                let good_to_submit = false;
                // Check that user_data has a key name from at_least_one_required_form_identifier_fields
                if (this.data_hub?.user_data) {
                    const has_one_required_user_data_field = at_least_one_required_form_identifier_fields.some(field => {
                        return this.data_hub.user_data[field];
                    });
                    if (has_one_required_user_data_field) {
                        // Create a local array of form inputs using required fields from user_data
                        form_elements = at_least_one_required_form_identifier_fields.map(field => {
                            const input = document.createElement('input');
                            input.name = field;
                            input.value = this.data_hub.user_data[field] || '';
                            return input;
                        });
                        good_to_submit = true;
                    }
                }
                if (!good_to_submit) {
                    return return_error_clear_form_loading_state(
                        form_container,
                        'No form elements found',
                        'Please enter an email address or cell phone into any of the inputs on this page.',
                        this.data_hub
                    );
                }
            }
    
            let this_new_user_data = {};
            let form_inputs_marked_required = [];
    
            Array.from(form_elements).forEach(element => {
                const key = element.id || element.name;
                if (key && white_list_incoming_person_fields.includes(key)) {
                    if (['checkbox', 'radio'].includes(element.type)) {
                        this_new_user_data[key] = parse_boolean_falsy_types(element.checked);
                    } else {
                        this_new_user_data[key] = element.value.trim();
                    }
    
                    if (element.required) {
                        form_inputs_marked_required.push(element.name || element.id);
                    }
                }
            });
    
            const mergedRequiredNames = [...new Set([...form_inputs_marked_required, ...at_least_one_required_form_identifier_fields])];
    
            const { valid, missing } = userDataRequiresAtLeastOneIdField(this_new_user_data, mergedRequiredNames, this.error_function);
            if (!valid) {
                const formFieldNamesWithMissingValues = showRedBorderRequiredFieldsMissingValues(form_elements, mergedRequiredNames);
                const readableMissingFieldsString = generateMissingRequiredFieldsString(formFieldNamesWithMissingValues);
                return return_error_clear_form_loading_state(
                    form_container,
                    `Missing required fields: ${readableMissingFieldsString}`,
                    `Please complete the following fields: ${readableMissingFieldsString || missing.join(', ')}`,
                    this.data_hub
                );
            }
    
            let telInputsWithBadValues = [];
            let errorMessages = [];
            const processedTelInputs = await process_verify_tel_input_values(form_elements, this.error_function);

            if (processedTelInputs && Array.isArray(processedTelInputs) && processedTelInputs.length > 0) {
                processedTelInputs.forEach(singleTelInputResponseObject => {

                    // For some reason intl tel will return 'null' or 'undefined' as strings so do not write those...
                    if (
                        singleTelInputResponseObject.country_code &&
                        singleTelInputResponseObject.country_code.toLowerCase !== 'null' &&
                        singleTelInputResponseObject.country_code.toLowerCase !== 'undefined' &&
                        this_new_user_data.country_code !== singleTelInputResponseObject.country_code
                    ) {
                        this_new_user_data.country_code = singleTelInputResponseObject.country_code;
                    }
                    if (
                        singleTelInputResponseObject.country &&
                        singleTelInputResponseObject.country.toLowerCase !== 'null' &&
                        singleTelInputResponseObject.country.toLowerCase !== 'undefined' &&
                        this_new_user_data.country !== singleTelInputResponseObject.country
                    ) {
                        this_new_user_data.country = singleTelInputResponseObject.country;
                    }

                    // Only track validation errors for required fields or non-empty optional fields
                    if (!singleTelInputResponseObject.isValid && 
                        (singleTelInputResponseObject.isRequired || !singleTelInputResponseObject.isEmpty)) {
                        telInputsWithBadValues.push(singleTelInputResponseObject.domElement.id || singleTelInputResponseObject.domElement.name);
                        changeElementErrorStatus(singleTelInputResponseObject.domElement, true);
                        if (singleTelInputResponseObject.errorMessage) {
                            errorMessages.push(singleTelInputResponseObject.errorMessage);
                        }
                    } else {
                        changeElementErrorStatus(singleTelInputResponseObject.domElement, false);
                    }
                });

                if (telInputsWithBadValues.length > 0) {
                    const readableBadTelFieldsString = generateMissingRequiredFieldsString(telInputsWithBadValues);
                    const concatenatedErrorMessages = errorMessages.join(', ');

                    return return_error_clear_form_loading_state(
                        form_container,
                        `Invalid phone numbers: ${readableBadTelFieldsString}`,
                        `Please fix these phone numbers: ${readableBadTelFieldsString}.<br>${concatenatedErrorMessages}`,
                        this.data_hub
                    );
                }
            }

            // Do a very basic email check
            // Will NOT return false if empty. Returns true. We should already be checking for that above in required
            const {valid: emailValid, error_message: invalidEmailMessage} = validateEmail(this_new_user_data.email);
            if (!emailValid) {
                return return_error_clear_form_loading_state(
                    form_container, 
                    'Invalid email address', 
                    invalidEmailMessage || 'Please enter a valid email address',
                    this.data_hub
                );
            }
    
            if (window?.data_hub_user_data) {
                this.data_hub.user_data = merge({}, this.data_hub.user_data, window.data_hub_user_data);
            } else {
                window.data_hub_user_data = this.data_hub?.user_data || {};
            }
    
            if (!this.data_hub?.user_data?.cell_phone && this.data_hub?.user_data?.phoneNumber) {
                this.data_hub.user_data.cell_phone = this.data_hub.user_data.phoneNumber;
                window.data_hub_user_data.cell_phone = this.data_hub.user_data.phoneNumber;
            }

            // Check if sms_optin is true and at least one phone number field is missing
            if (this_new_user_data?.sms_optin === true) {
                const hasPhoneNumber = at_least_one_required_form_identifier_fields.some(field => {
                    return this_new_user_data[field];
                });

                if (!hasPhoneNumber) {
                    return return_error_clear_form_loading_state(
                        form_container,
                        'Missing phone number',
                        'Please enter a phone number to receive text messages. Or uncheck the box to opt out.',
                        this.data_hub
                    );
                }
            }
    
            const merged_metadata = merge(
                {},
                get_metadata_form_fields(form_container, this.data_hub),
                this.form_metadata,
                this.data_hub?.page_metadata
            );
    
            let data_hub_action_object_to_send_to_api = {
                events: this.events,
                returns: returns,
                custom_data: this.custom_data,
                user_data: {},
                metadata: merged_metadata,
            };
    
            for (const [key, value] of this.data_hub.url_params) {
                if (allowed_iterable_h_hash_key_names.includes(key)) {
                    data_hub_action_object_to_send_to_api.user_data[key] = value;
                }
            }
    
            // Append additional metadata if present.
            if (this.data_hub?.iterableCampaignId) {
                data_hub_action_object_to_send_to_api.metadata.iterableCampaignId = this.data_hub.iterableCampaignId;
            }
            if (this.data_hub?.iterableTemplateId) {
                data_hub_action_object_to_send_to_api.metadata.iterableTemplateId = this.data_hub.iterableTemplateId;
            }
            if (!this.events || this.events.length === 0) {
                return return_error_clear_form_loading_state(
                    form_container,
                    'No events to submit',
                    "Please contact an administrator",
                    this.data_hub
                );
            }
    
            const is_valid_action_array = verify_action_array(data_hub_action_object_to_send_to_api.events, this.error_function);
            if (is_valid_action_array !== true) {
                return return_error_clear_form_loading_state(
                    form_container,
                    'Invalid action array',
                    `Please contact an administrator`,
                    this.data_hub
                );
            }
    
            this_new_user_data = merge(
                {},
                this.data_hub?.user_data,
                window?.data_hub_user_data,
                this_new_user_data
            );

            this_new_user_data = cleanUserData(this_new_user_data, empty_array_keys_to_skip);
            data_hub_action_object_to_send_to_api.user_data = this_new_user_data;
    
            this.pre_submit_function(form_container, data_hub_action_object_to_send_to_api, this.data_hub);
    
            this_new_user_data = cleanUserData(this_new_user_data, empty_array_keys_to_skip);
            const { valid: valid2, missing: missing2 } = userDataRequiresAtLeastOneIdField(this_new_user_data, mergedRequiredNames, this.error_function);
            if (!valid2) {

                const formFieldNamesWithMissingValues = showRedBorderRequiredFieldsMissingValues(form_elements, mergedRequiredNames);
                const readableMissingFieldsString = generateMissingRequiredFieldsString(formFieldNamesWithMissingValues);

                return return_error_clear_form_loading_state(
                    form_container,
                    'Missing required fields' + (readableMissingFieldsString ? `: ${readableMissingFieldsString}` : ''),
                    `Please complete the following fields: ${readableMissingFieldsString || missing2.join(', ')}`,
                    this.data_hub
                );
            }
    
            // Merge back to user_data now? It will on the returned so this is kind of pointless. BUT maybe for errors?
            data_hub_action_object_to_send_to_api.user_data = this_new_user_data;
            this.data_hub.user_data = this_new_user_data;
            window.data_hub_user_data = this_new_user_data;

            if (is_under_18(data_hub_action_object_to_send_to_api?.user_data || this.data_hub?.user_data)) {
                return_error_clear_form_loading_state(
                    form_container,
                    'User is under 18',
                    'You must be 18 or older to submit this form.',
                    this.data_hub
                );
                this.data_hub.user_data = {};
                window.data_hub_user_data = {};
                return;
            }
    
            if (!this.data_hub.forms_domain) {
                return return_error_clear_form_loading_state(
                    form_container,
                    'No forms domain found',
                    'Please contact an administrator',
                    this.data_hub
                );
            }
    
            // Keep the URL param approach as a fallback
            let user_data_api_url = `${this.data_hub.forms_domain}/api/post/user_data`;
            
            // If using the "submit function immediately" flow, do it now.
            if (this.return_submit_function_immediately) {
                hide_on_submit(this.hide_on_submit_selector, form_container);
                this.return_function(form_container, data_hub_action_object_to_send_to_api, this.data_hub);
                already_called_return_function = true;
            }

            // use cloneDeep to avoid mutating the original user_data object
            const original_user_data = cloneDeep(data_hub_action_object_to_send_to_api?.user_data || {});

            try {
                // Create headers object with required content type
                const headers = { 
                    'Content-Type': 'application/json'
                };
                
                // If the JWT cookie exists, add it as an Authorization Bearer token
                // and add it to the request body if needed
                if (this.data_hub.jwt_cookie) {
                    //headers['Authorization'] = `Bearer ${this.data_hub.jwt_cookie}`;
                    data_hub_action_object_to_send_to_api.jwt = this.data_hub.jwt_cookie;
                }
                
                // Make the fetch request
                // 250327 trying with credentials included. BUT TODO i dont think we need? we arent passing a bearer header?
                    // OPENAI: would this make it more likely for cookies to be blocked?
                const response = await fetch_with_timeout(user_data_api_url, {
                    method: 'POST',
                    headers: headers,
                    credentials: 'include',
                    body: JSON.stringify(data_hub_action_object_to_send_to_api),
                    timeout: this.post_person_timeout || 5000
                });
    
                if (!response.ok) {
                    return return_error_clear_form_loading_state(
                        form_container,
                        `Error submitting form: ${response.statusText}`,
                        'Please try again later',
                        this.data_hub
                    );
                }
    
                response_data = await response.json();

                // Check user data against the prefetch, show UX to confirm new email that fixed any typos and
                await create_person_format_modifications_ux_elements(response_data, original_user_data, form_container, this.data_hub);

                // Make an event with the action body
                const new_action_event_message_detail_data = {
                    action: data_hub_action_object_to_send_to_api,
                    response: response_data,
                    data_hub_instance: this.data_hub,
                    target: this.data_hub?.target || document
                };

                window.dispatchEvent(new CustomEvent('data-hub-new-action-event', {
                    detail: new_action_event_message_detail_data,
                    bubbles: true,
                    composed: true
                }));

                // Call data-hub-new-user-data event
                const new_user_data_event_detail_data = {
                    user_data: response_data?.returns?.user_data || {},
                    prefill_input_selector: this.data_hub?.prefill_input_selector || null,
                    data_hub_instance: this.data_hub,
                    target: this.data_hub?.target || document
                };
    
                window.dispatchEvent(new CustomEvent('data-hub-new-user-data', {
                    detail: new_user_data_event_detail_data,
                    bubbles: true,
                    composed: true
                }));

                // Update data_hub.jwt_cookie
                if (response_data?.returns?.jwt_cookie) {
                    this.data_hub.jwt_cookie = response_data.returns.jwt_cookie;
                }

                // Send data to CORS iframe if on different form domain
                send_jwt_cookie_to_cors_iframe(
                    this.data_hub?.forms_domain || null,
                    response_data?.returns?.jwt_cookie || null,// TODO this needs to be added to the returns in action-two
                    response_data?.returns?.event_uuid || null,
                    this.data_hub
                );

                // Merge the new user_data from the API.
                if (response_data?.returns?.user_data) {
                    this.data_hub.user_data = merge(
                        {},
                        window.data_hub_user_data,
                        this.data_hub.user_data,
                        data_hub_action_object_to_send_to_api.user_data,
                        response_data.returns.user_data
                    );
                    window.data_hub_user_data = this.data_hub.user_data;
                    re_prefill_all_forms(this.data_hub.user_data, this.prefill_input_selector);
                }
            } catch (error) {
                return return_error_clear_form_loading_state(
                    form_container,
                    `Submission error: ${error.message}`,
                    'Please try again later',
                    this.data_hub
                );
            } finally {
                remove_form_input_loading_state(form_container);
                remove_form_loading_overlay(form_container, this.loading_overlay_selector, this.data_hub);
                remove_submit_button_loading_state(form_container, this.submit_button_selector);
                hide_on_submit(this.hide_on_submit_selector, form_container);
                if (!already_called_return_function) {
                    return this.return_function(form_container, response_data, this.data_hub);
                }
            }
        } catch (error) {
            return return_error_clear_form_loading_state(
                form_container,
                'Error submitting form data: ' + (error.message || error),
                'Please try again later',
                this.data_hub
            );        
        }
    }
}