// src/forms/subscriptions_preferences_form.js

import { fetch_with_timeout, get_boolean_query_param, parse_int_or_return_null } from '../helpers';

export class subscriptions_preferences_form {
    constructor(config = {}, data_hub_instance) {
        this.is_unsubscribe_page = config.is_unsubscribe_page || get_boolean_query_param('unsubscribe') || false;
        this.iterable_message_types = [];
        this.data_hub = data_hub_instance; // Ref the data_hub instance for local
    }

    static update_subscription_box_classes(box, is_checked) {
        if (is_checked) {
            box.classList.add('checked', 'shadow-lg', 'subscription_active', 'border-green-700');
            box.classList.remove('shadow-sm', 'border-grey-brand-color-200');
        } else {
            box.classList.add('shadow-sm', 'border-grey-brand-color-200');
            box.classList.remove('checked', 'shadow-lg', 'subscription_active', 'border-green-700');
        }
    }

    /**
     * Gets iterable message types array of objects. First checks for pre-cached client_metadata.
     * If not found, fetches from the server.
     * @returns {Promise<void>}
     */
    async get_iterable_message_types() {

        // First check this.data_hub.client_metadata.iterable_message_types
        if (this.data_hub.client_metadata && this.data_hub.client_metadata.iterable_message_types) {
            if (Array.isArray(this.data_hub.client_metadata.iterable_message_types) && this.data_hub.client_metadata.iterable_message_types.length > 0) {
                this.iterable_message_types = this.data_hub.client_metadata.iterable_message_types;
                return;
            }
        }

        if (!this.data_hub.forms_domain) {
            this.data_hub?.error_function('No forms domain found');
            return;
        }
        try {
            const channels_api_url = `${this.data_hub.forms_domain}/api/get/iterable_message_types?cache_bust=${Date.now()}`;
            const response = await fetch_with_timeout(channels_api_url, {
                timeout: 20000
            });

            if (!response.ok) {
                this.data_hub?.error_function('Error fetching get_iterable_message_types');
                return;
            }

            // Validate response format
            const data = await response.json();
            if (!data || typeof data !== 'object') {
                this.data_hub?.error_function('Invalid response from get_iterable_message_types');
                return;
            }
            
            this.iterable_message_types = data;
        } catch (error) {
            this.data_hub?.error_function('Cant get iterable message types');
        }
    }

    /**
     * Gets the form container from the default selector
     * @returns {HTMLElement} - The form container element.
     */
    get_form_container_from_default_selector() {
        return document.querySelector('.data-hub-container');
    }

    /**
     * Changes the checkbox checked based on user_data message type IDs
     * @param {HTMLElement} form_container - The form container element.
     * @returns {void}
     */
    update_subscription_checkboxes_state_from_user_data(
        form_container = this.get_form_container_from_default_selector(),
        data_hub = this.data_hub || window?.data_hub
    ) {

        if (!this.data_hub.user_data) return;

        // Correctly un checks the checkbox if is unsubscribedMessageTypeIds BUT does not change 'subscribed' to 'unsubscribed' red
        // It also shows 'Subscribed' in grey but i think i fixed thats the default on he form html. also removed pre-fill data=

        if (this.data_hub.user_data.subscribedMessageTypeIds) {

            this.data_hub.user_data.subscribedMessageTypeIds.forEach(id => {

                const subscription_box = form_container.querySelector(`.subscription-box[data-message-type-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = true;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, true);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        data_hub?.error_function('no checkbox in this subscriptions box');
                    }
                    
                }
            });
        }

        if (this.data_hub.user_data.unsubscribedMessageTypeIds) {

            this.data_hub.user_data.unsubscribedMessageTypeIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-message-type-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = false;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, false);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        data_hub?.error_function('no checkbox in this subscriptions box');
                    }
                }
            });
        }

        if (this.data_hub.user_data.unsubscribedChannelIds) {
            this.data_hub.user_data.unsubscribedChannelIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-channel-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = false;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, false);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        data_hub?.error_function('no checkbox in this subscriptions box');
                    }
                }
            });
        }

        if (this.data_hub.user_data.emailListIds) {
            this.data_hub.user_data.emailListIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-email-list-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = true;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, true);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        data_hub?.error_function('no checkbox in this subscriptions box');
                    }
                }
            });
        }

        if (this.data_hub.user_data.userListIds) {
            this.data_hub.user_data.userListIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-user-list-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = true;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, true);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        data_hub?.error_function('no checkbox in this subscriptions box');
                    }
                }
            });
        }
    }

    /**
     * Checks or unchecks all subscription checkboxes based on the provided status.
     * @param {*} form_container 
     * @param {*} check_status to changed subscribed or unsubscribed checked
     */
    check_or_uncheck_all_subscription_checkboxes(form_container = this.get_form_container_from_default_selector(), check_status = true) {
        form_container.querySelectorAll('.subscription-box .checkbox').forEach(checkbox => {
            const subscription_box = checkbox.closest('.subscription-box');
            checkbox.checked = check_status;
            if (subscription_box) {
                subscriptions_preferences_form.update_subscription_box_classes(subscription_box, checkbox.checked);
                this.update_checkbox_label(checkbox); // Update label on initialization
                this.update_user_data_iterable_subscription_arrays(checkbox); // Update user data
            }
        });
    }

    /**
     * Updates checkboxes based on user subscriptions preferences data.
     * Supports message types, email lists, user lists, and channels.
     * @param {HTMLElement} checkbox - The checkbox element to update.
     * @returns {void}
     */
    update_user_data_iterable_subscription_arrays(checkbox) {

        let message_type_id = checkbox.dataset.referencesMessageTypeId || null;
        let email_list_id = checkbox.dataset.emailListId || null;
        let user_list_id = checkbox.dataset.userListId || null;
        let channel_id = checkbox.dataset.channelId || null;

        if (message_type_id) { message_type_id = parse_int_or_return_null(message_type_id); }
        if (email_list_id) { email_list_id = parse_int_or_return_null(email_list_id); }
        if (user_list_id) { user_list_id = parse_int_or_return_null(user_list_id); }
        if (channel_id) { channel_id = parse_int_or_return_null(channel_id); }

        if (!this.data_hub.user_data.subscribedMessageTypeIds) {
            this.data_hub.user_data.subscribedMessageTypeIds = [];
        }

        if (!this.data_hub.user_data.unsubscribedMessageTypeIds) {
            this.data_hub.user_data.unsubscribedMessageTypeIds = [];
        }

        if (!this.data_hub.user_data.emailListIds) {
            this.data_hub.user_data.emailListIds = [];
        }

        if (!this.data_hub.user_data.userListIds) {
            this.data_hub.user_data.userListIds = [];
        }

        if (!this.data_hub.user_data.unsubscribedChannelIds) {
            this.data_hub.user_data.unsubscribedChannelIds = [];
        }

        if (!this.data_hub.user_data.subscribedChannelIds) {
            this.data_hub.user_data.subscribedChannelIds = [];
        }

        if (checkbox.checked) {
            if (message_type_id !== null) {
                this.data_hub.user_data.unsubscribedMessageTypeIds = this.data_hub.user_data.unsubscribedMessageTypeIds.filter(id => id !== message_type_id);
                if (!this.data_hub.user_data.subscribedMessageTypeIds.includes(message_type_id)) {
                    this.data_hub.user_data.subscribedMessageTypeIds.push(message_type_id);
                }
            }

            if (email_list_id !== null) {
                if (!this.data_hub.user_data.emailListIds.includes(email_list_id)) {
                    this.data_hub.user_data.emailListIds.push(email_list_id);
                }
            }

            if (user_list_id !== null) {
                if (!this.data_hub.user_data.userListIds.includes(user_list_id)) {
                    this.data_hub.user_data.userListIds.push(user_list_id);
                }
            }

            if (channel_id !== null) {
                this.data_hub.user_data.unsubscribedChannelIds = this.data_hub.user_data.unsubscribedChannelIds.filter(id => id !== channel_id);
                if (!this.data_hub.user_data.subscribedChannelIds.includes(channel_id)) {
                    this.data_hub.user_data.subscribedChannelIds.push(channel_id);
                }
            }
        } else {
            // Only message type and channel types have specific unsub arrays. Email and user lists are only subscribed or not
            if (message_type_id !== null) {
                this.data_hub.user_data.subscribedMessageTypeIds = this.data_hub.user_data.subscribedMessageTypeIds.filter(id => id !== message_type_id);
                if (!this.data_hub.user_data.unsubscribedMessageTypeIds.includes(message_type_id)) {
                    this.data_hub.user_data.unsubscribedMessageTypeIds.push(message_type_id);
                }
            }

            if (channel_id !== null) {
                this.data_hub.user_data.subscribedChannelIds = this.data_hub.user_data.subscribedChannelIds.filter(id => id !== channel_id);
                if (!this.data_hub.user_data.unsubscribedChannelIds.includes(channel_id)) {
                    this.data_hub.user_data.unsubscribedChannelIds.push(channel_id);
                }
            }

            // If it exists in emailListIds array remove it
            if (email_list_id !== null) {
                this.data_hub.user_data.emailListIds = this.data_hub.user_data.emailListIds.filter(id => id !== email_list_id);
            }

            // If it exists in userListIds array remove it
            if (user_list_id !== null) {
                this.data_hub.user_data.userListIds = this.data_hub.user_data.userListIds.filter(id => id !== user_list_id);
            }
        }

    }

    toggle_checkbox(event) {
        event.preventDefault();
        event.stopPropagation();
    
        const target = event.target;

        // if the target is the preview button type skip
        if (target.classList.contains('preview-email-button')) {
            // get the data-email-preview-url attribute value
            const previewUrl = target.getAttribute('data-email-preview-url');

            if (previewUrl) {
                this.open_email_preview_modal(previewUrl);
            } else {
                this.data_hub?.error_function('No preview url found');
                return;
            }

        } else {

            const subscriptionBox = target.closest('.subscription-box');
        
            if (!subscriptionBox) {
                this.data_hub?.error_function('No subscription box found');
                return;
            }
        
            if (target.type === 'checkbox') {

                // Update user data and checkbox label if clicked directly
                this.update_user_data_iterable_subscription_arrays(target);
                subscriptions_preferences_form.update_subscription_box_classes(subscriptionBox, target.checked);
                this.update_checkbox_label(target);
        
                const the_desired_checked_state = target.checked;

                // Get a new reference to the checkbox
                const reselect_checkbox = subscriptionBox.querySelector('.checkbox');
                if (reselect_checkbox) {
                    this.manually_change_checked_from_scratch(reselect_checkbox, the_desired_checked_state);
                } else {
                    this.data_hub?.error_function('No reselect_checkbox found in subscriptionBox');
                }
        
            } else {
                const checkbox = subscriptionBox.querySelector('.checkbox');
                if (checkbox) {

                    // Toggle the checkbox state
                    checkbox.checked = !checkbox.checked;
                    this.update_user_data_iterable_subscription_arrays(checkbox);
                    subscriptions_preferences_form.update_subscription_box_classes(subscriptionBox, checkbox.checked);
                    this.update_checkbox_label(checkbox);
        
                } else {
                    this.data_hub?.error_function('No checkbox found in subscriptionBox');
                }
            }
        }
    }

    /**
     * Hack to avoid double clicks and weird rendering issues
     * @param {HGa} reselect_checkbox 
     * @param {boolean} change_to_checked 
     */
    manually_change_checked_from_scratch(reselect_checkbox, change_to_checked) {

        const event = new Event('change');
        reselect_checkbox.dispatchEvent(event);

        requestAnimationFrame(() => {
            reselect_checkbox.checked = change_to_checked;
        });

    }

    /**
     * Updates the checkbox label based on its checked state.
     * @param {HTMLElement} checkbox - The checkbox element to update.
     * @returns {void}
     */
    update_checkbox_label(checkbox) {

        const message_type_id = checkbox.dataset.referencesMessageTypeId;
        if (message_type_id) {

            const label = checkbox.closest('.subscription-box').querySelector(`[data-checkbox-label-for-message-type-id="${message_type_id}"]`);
            if (label) {
                label.textContent = checkbox.checked ? 'Subscribed' : 'Unsubscribed';
                if (checkbox.checked) {
                    label.classList.remove('text-red-600');
                    label.classList.add('text-green-600');
                } else {
                    label.classList.add('text-red-600');
                    label.classList.remove('text-green-600');
                }
            } else {
                this.data_hub?.error_function('No label found for message type id');
            }
        }
    }

    /**
     * Appends the user's email to the form as a hidden input field.
     * @param {HTMLElement} form_container - The form container element.
     * @param {Object} data_hub - The data hub instance.
     */
    append_email_to_form(form_container = this.get_form_container_from_default_selector(), data_hub = this.data_hub || window?.data_hub) {
        if (!data_hub.user_data) {
            data_hub?.error_function('No user data found');
            return;
        }

        if (!data_hub.user_data.email) {
            data_hub?.error_function('No email found');
            return;
        }

        if (!form_container) {
            data_hub?.error_function('No form container found');
            return;
        }

        const form = form_container.querySelector('form');

        const email_input = document.createElement('input');
        email_input.type = 'hidden';
        email_input.name = 'email';
        email_input.value = data_hub.user_data.email;
        if (form) {
            form.prepend(email_input);
        } else {
            form_container.prepend(email_input);
        }
    }

    /**
     * Opens the email preview modal with the provided URL.
     * @param {string} previewUrl - The URL to load in the iframe.
     * @returns {void}
     */
    open_email_preview_modal(previewUrl) {
        const modal = document.getElementById('email-preview-modal');
        const iframe = document.getElementById('email-preview-iframe');
        iframe.src = previewUrl;
        modal.classList.remove('hidden');
    }

    /**
     * Closes the email preview modal and resets the iframe source.
     * @param {Event|null} event - The event that triggered the close action.
     * @returns {void}
     */
    close_email_preview_modal(event=null) {

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const modal = document.getElementById('email-preview-modal');
        const iframe = document.getElementById('email-preview-iframe');
        iframe.src = '';
        modal.classList.add('hidden');
    }

    /**
     * Changes the page rendering to show the unsubscribe page.
     * @param {HTMLElement} form_container - The form container element.
     * @param {Object} data_hub_form_instance - The data hub instance for the form.
     * @returns {void}
     */
    change_page_render_to_unsubscribe(
        form_container = this.get_form_container_from_default_selector(),
        data_hub_form_instance
    ) {

        if (!form_container) { return }

        if (this.is_unsubscribe_page) {

            const unsubscribe_all_element = form_container.querySelector('.unsubscribe-all');
            if (unsubscribe_all_element) {
                unsubscribe_all_element.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.check_or_uncheck_all_subscription_checkboxes(form_container, false);
                    if (data_hub_form_instance) {
                        data_hub_form_instance?.submit_form_data(event, form_container);
                    }
                });
            }

            form_container.classList.add('is-unsubscribe');
            form_container.querySelectorAll('.data-hub-hide-unsubscribe-page').forEach(element => {
                element.classList.add('hidden');
            });
            form_container.querySelectorAll('.data-hub-show-unsubscribe-page').forEach(element => {
                element.classList.remove('hidden');
            });

        }
    }

    /**
     * Positions the arrow pointer based on the selected subscription box.
     * So if it's a subscribe page, it will show 'subscribe' on the first message type
     * and if it's an unsubscribe page, it will show 'unsubscribe' on the first message type.
     * @param {HTMLElement} form_container - The form container element.
     * @returns {void}
     */
    position_arrow_pointer(form_container = this.get_form_container_from_default_selector()) {

        if (!form_container) { return }

        const arrow_pointer_selected = form_container.querySelector('.arrow-pointer');
        
        // Add null check to prevent errors if arrow element doesn't exist
        if (!arrow_pointer_selected) {
            this.data_hub?.error_function('Arrow pointer element not found');
            return;
        }
        
        let target_element;

        if (this.is_unsubscribe_page) {
            // Unsubscribe page logic
            target_element = form_container.querySelector('.subscription-box .checkbox:checked');
            if (!target_element) {
                // If no subscriptions are checked, default to the first message type
                target_element = form_container.querySelector('.subscription-box');
            }
            arrow_pointer_selected.querySelector('.first-message-type-to-unsubscribe').classList.remove('hidden');
            arrow_pointer_selected.querySelector('.all-message-type-unsubscribed').classList.add('hidden');
        } else {
            // Subscribe page logic
            target_element = form_container.querySelector('.subscription-box .checkbox:not(:checked)');
            if (!target_element) {
                // If all are checked or new user, default to the first message type
                target_element = form_container.querySelector('.subscription-box');
                // Show all-message-type-subscribed
                arrow_pointer_selected.querySelector('.first-message-type-to-subscribe').classList.add('hidden');
                arrow_pointer_selected.querySelector('.all-message-type-subscribed').classList.remove('hidden');
            } else {
                arrow_pointer_selected.querySelector('.first-message-type-to-subscribe').classList.remove('hidden');
                arrow_pointer_selected.querySelector('.all-message-type-subscribed').classList.add('hidden');
            }
            
        }

        if (target_element) {
            const targetOffset = target_element.getBoundingClientRect().top + window.scrollY;
            const arrowHeight = arrow_pointer_selected.offsetHeight;
            let original_height_offset = targetOffset - arrowHeight / 2;
            if (this.is_unsubscribe_page) {
                original_height_offset = original_height_offset + 50; // Add 50px to offset for the header
            } else {
                original_height_offset = original_height_offset + 30;
            }
            arrow_pointer_selected.style.top = `${original_height_offset}px`;
        }

        arrow_pointer_selected.classList.remove('hidden');
        arrow_pointer_selected.classList.add('animate-fade-down');
    }

}