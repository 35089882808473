// cors_iframe.js
import { white_list_incoming_person_fields } from '../helpers/static_vars';

/**
 * Send a jwt_cookie to the CORS iframe.
 * @param {String} forms_domain for action-two backend
 * @param {String} jwt_cookie returns from new user data post
 * @param {String} event_uuid of the user data post event
 * @param {Object} data_hub_instance 
 * @returns 
 */
export const send_jwt_cookie_to_cors_iframe = async (forms_domain, jwt_cookie = null, event_uuid = null, data_hub_instance = window?.data_hub) => {
    const error_function = data_hub_instance?.error_function || console.error;
    if (!forms_domain) {
        return error_function('No form domain provided for send_user_data_to_cors_iframe', null);
    }
    if (!jwt_cookie || typeof jwt_cookie !== 'string') {
        if (!data_hub_instance?.jwt_cookie || typeof data_hub_instance.jwt_cookie !== 'string') {
            return error_function('No valid jwt_cookie provided', null);
        } else {
            jwt_cookie = data_hub_instance.jwt_cookie;
        }
    }

    const cache_buster = Math.random().toString(36).substring(7);

    try {

        if (forms_domain !== window.location.origin) {
            let iframe = document.getElementById('dataHubIframe');
            if (!iframe) {
                iframe = document.createElement('iframe');
                iframe.id = 'dataHubIframe';
                iframe.style.display = 'none';
                iframe.sandbox = "allow-scripts allow-same-origin allow-forms";
                const parentDomain = window.location.origin;
                iframe.src = `${forms_domain}/cors_iframe?parent_domain=${encodeURIComponent(parentDomain)}&no_cache=true&cache_buster=${cache_buster}`;
                document.body.appendChild(iframe);
                iframe.onload = function () {
                    iframe.contentWindow.postMessage({
                        type: 'DATA_HUB_SET_JWT_COOKIE',
                        payload: {
                            jwt_cookie: jwt_cookie,
                            event_uuid: event_uuid
                        }
                    }, forms_domain);
                };
            } else {
                iframe.contentWindow.postMessage({
                    type: 'DATA_HUB_SET_JWT_COOKIE',
                    payload: {
                        jwt_cookie: jwt_cookie,
                        event_uuid: event_uuid
                    }
                }, forms_domain);
            }
        }
    } catch (error) {
        error_function('Error sending user data to CORS iframe: ' + error);
    }
};

export const send_user_data_to_cors_iframe = async (forms_domain, user_data, data_hub_instance = window?.data_hub) => {
    const error_function = data_hub_instance?.error_function || console.error;
    if (!forms_domain) {
        return error_function('No form domain provided for send_user_data_to_cors_iframe', null);
    }
    if (!user_data || typeof user_data !== 'object' || Object.keys(user_data).length === 0) {
        return error_function('No valid user_data provided', null);
    }

    const cache_buster = Math.random().toString(36).substring(7);

    try {

        if (forms_domain !== window.location.origin) {
            let iframe = document.getElementById('dataHubIframe');
            if (!iframe) {
                iframe = document.createElement('iframe');
                iframe.id = 'dataHubIframe';
                iframe.style.display = 'none';
                iframe.sandbox = "allow-scripts allow-same-origin allow-forms";
                const parentDomain = window.location.origin;
                iframe.src = `${forms_domain}/cors_iframe?parent_domain=${encodeURIComponent(parentDomain)}&no_cache=true&cache_buster=${cache_buster}`;
                document.body.appendChild(iframe);
                iframe.onload = function () {
                    iframe.contentWindow.postMessage({
                        type: 'DATA_HUB_SET_USER_DATA',
                        payload: user_data
                    }, forms_domain);
                };
            } else {
                iframe.contentWindow.postMessage({
                    type: 'DATA_HUB_SET_USER_DATA',
                    payload: user_data
                }, forms_domain);
            }
        }
    } catch (error) {
        error_function('Error sending user data to CORS iframe: ' + error);
    }
};

export const get_user_data_from_cors_iframe = (forms_domain, url_params = '', data_hub_instance) => {
    const error_function = data_hub_instance?.error_function || console.error;
    try {
        if (forms_domain !== window.location.origin) {
            const cache_buster = Math.random().toString(36).substring(7);
            const body_object_with_url_params = { 
                url_params: url_params 
            };
            let iframe = document.getElementById('dataHubIframe');
            if (!iframe) {
                iframe = document.createElement('iframe');
                iframe.id = 'dataHubIframe';
                iframe.style.display = 'none';
                iframe.sandbox = "allow-scripts allow-same-origin allow-forms allow-storage-access-by-user-activation";
                const parentDomain = window.location.origin;
                iframe.src = `${forms_domain}/cors_iframe?parent_domain=${encodeURIComponent(parentDomain)}&no_cache=true&cache_buster=${cache_buster}`;
                document.body.appendChild(iframe);
                iframe.onload = function () {
                    iframe.contentWindow.postMessage({
                        type: 'DATA_HUB_GET_USER_DATA',
                        payload: body_object_with_url_params
                    }, forms_domain);
                };
            } else {
                iframe.contentWindow.postMessage({
                    type: 'DATA_HUB_GET_USER_DATA',
                    payload: body_object_with_url_params
                }, forms_domain);
            }
        }
    } catch (error) {
        error_function('Error getting user data from CORS iframe: ' + error);
    }
};

/**
 * Attach a single message listener on window that handles all DATA_HUB_* messages.
 * This listener is attached immediately so that even if forms are added later,
 * incoming messages are processed.
 */
export function addDataHubMessageListener(data_hub_instance = window?.data_hub) {
    window.addEventListener(
        'message',
        (event) => {
            if (!event.data || !event.data.type || !event.data.type.startsWith('DATA_HUB_')) {
                return;
            }

            // Use the forms_domain from DataHub if available, or fallback to window.location.origin.
            const forms_domain = data_hub_instance?.forms_domain 
                                 || (window?.data_hub && window?.data_hub?.forms_domain)
                                 || window.location.origin;

            // Only process events from the expected origin.
            if (event.origin !== forms_domain) {
                return;
            }

            // Process the different DATA_HUB message types.
            switch (event?.data?.type) {
                // Pass in jwt with event_uuid to verify pre signed with 5 second csfr expiry cookie
                case 'DATA_HUB_SET_JWT_COOKIE_SUCCESS':
                    if (event?.data?.payload?.jwt_cookie) {
                        data_hub_instance.jwt_cookie = event.data.payload.jwt_cookie;
                    }
                    break;
                case 'DATA_HUB_SET_JWT_COOKIE_ERROR':
                    data_hub_instance?.error_function('Error setting jwt_cookie in CORS iframe: ' + event?.data) 
                    || console.error('Error setting jwt_cookie in CORS iframe: ' + event?.data);
                    break;

                // Older pass in raw user_data
                case 'DATA_HUB_COOKIE_SET_SUCCESS':
                    // if there is jwt_cookie add to data_hub
                    if (event?.data?.payload?.jwt_cookie && data_hub_instance) {
                        data_hub_instance.jwt_cookie = event.data.payload.jwt_cookie;
                    }
                    break;
                case 'DATA_HUB_COOKIE_SET_ERROR':
                    data_hub_instance?.error_function('Error setting user_data cookie in CORS iframe: ' + event?.data) 
                    || console.error('Error setting user_data cookie in CORS iframe: ' + event?.data);
                    break;

                // GET cached user data via jwt_cookie
                case 'DATA_HUB_COOKIE_GET_SUCCESS': {
                    // Filter the payload so that only keys in our whitelist remain.
                    const payload = event.data.payload;

                    let white_listed_user_data = {};
                    const payload_user_data = payload.user_data || payload.user || payload || {};

                    // build white listed user data with white_list_incoming_person_fields
                    Object.keys(payload_user_data).forEach((key) => {
                        if (white_list_incoming_person_fields.includes(key)) {
                            white_listed_user_data[key] = payload_user_data[key];
                        }
                    });

                    // if jwt_cookie add to data_hub
                    if (payload.jwt_cookie) {
                        data_hub_instance.jwt_cookie = payload.jwt_cookie;
                    }

                    if (window.data_hub && typeof window.data_hub.got_cors_user_data_cache === 'function') {
                        window.data_hub.got_cors_user_data_cache(white_listed_user_data);
                    }

                    break;
                }
                case 'DATA_HUB_COOKIE_GET_ERROR':
                    data_hub_instance?.error_function('Error getting user_data cookie from CORS iframe: ' + event?.data) 
                    || console.error('Error getting user_data cookie from CORS iframe: ' + event?.data);
                    break;
                default:
                    // Unknown message type—do nothing.
                    break;
            }
        },
        false
    );
}