// helpers/iterable_helpers.js
import {
    endpoints_narrow_allow_list,
    endpoints_that_require_user_or_email
} from './static_vars';

import {parse_int_or_return_null} from './index';

import { cookie_utils } from './cookies';

// Function to get Iterable IDs from cookies
export const get_iterable_cookie_values = () => {
    return {
        iterableCampaignId: cookie_utils.get_cookie('iterableCampaignId') || null,
        iterableTemplateId: cookie_utils.get_cookie('iterableTemplateId') || null
    };
};

// Function to parse Iterable IDs from URL parameters
export const get_iterable_url_params = (url_params) => {
    const iterable_params = { iterableCampaignId: null, iterableTemplateId: null };

    if (!url_params) return iterable_params;

    for (const [key, value] of url_params) {
        if (key === 'iterableCampaignId' && !iterable_params.iterableCampaignId) {
            iterable_params.iterableCampaignId = is_valid_iterable_refcode_parse_to_int(value);
        } else if (key === 'iterableTemplateId' && !iterable_params.iterableTemplateId) {
            iterable_params.iterableTemplateId = is_valid_iterable_refcode_parse_to_int(value);
        } else if (key.startsWith('iterable_')) {
            const parsed_values = parse_iterable_refcode(key);
            iterable_params.iterableCampaignId ||= parsed_values.iterableCampaignId;
            iterable_params.iterableTemplateId ||= parsed_values.iterableTemplateId;
        } else if (key === '_c_' && !iterable_params.iterableCampaignId) {
            // Handle legacy format
            iterable_params.iterableCampaignId = is_valid_iterable_refcode_parse_to_int(value);
        } else if (key === '_t_' && !iterable_params.iterableTemplateId) {
            // Handle legacy format
            iterable_params.iterableTemplateId = is_valid_iterable_refcode_parse_to_int(value);
        }
    }

    return iterable_params;
};

// Helper function to validate and parse Iterable IDs
export const is_valid_iterable_refcode_parse_to_int = (refcode) => {
    try {
        refcode = parse_int_or_return_null(refcode);
        if (refcode < 5000000) {
            // removed for timing issues dont spam console
            //console.error('Invalid Iterable refcode (too low):', refcode);
            return null;
        }
        return refcode;
    } catch (error) {
        console.error('Error parsing Iterable refcode:', error);
        return null;
    }
};

// Function to parse Iterable refcode from a single key
export const parse_iterable_refcode = (refcode) => {
    let iterableCampaignId = null;
    let iterableTemplateId = null;

    if ((refcode.includes('_c_') || refcode.includes('_t_'))) {
        const parts = refcode.split('_');
        parts.forEach((part, index) => {
            if (part === 'c' && index + 1 < parts.length) {
                iterableCampaignId = parts[index + 1];
            }
            if (part === 't' && index + 1 < parts.length) {
                iterableTemplateId = parts[index + 1];
            }
        });
    }

    return {
        iterableCampaignId: is_valid_iterable_refcode_parse_to_int(iterableCampaignId),
        iterableTemplateId: is_valid_iterable_refcode_parse_to_int(iterableTemplateId),
    };
};

export const cookieStringToInt = (cookieString = null) => {
    try {
        if (typeof cookieString === 'string') {
            cookieString = parseInt(cookieString, 10);
            if (isNaN(cookieString)) {
                // Handle the case where the string could not be converted to a number
                console.error('Invalid cookie value, could not convert to integer');
            }
        }
        return cookieString || null;
    } catch (error) {
        console.error('Error converting cookie string to integer:', error);
        return null;
    }
}

// Main function to get Iterable IDs based on priority
// 250130 TODO update existing function to fix cookies, pass the cookies functions
export const get_iterable_ids = (config, url_params) => {
    let final_iterable_ids = {
        iterableCampaignId: null,
        iterableTemplateId: null,
    };

    // Priority 1: Config object
    final_iterable_ids.iterableCampaignId = config?.iterableCampaignId || null;
    final_iterable_ids.iterableTemplateId = config?.iterableTemplateId || null;

    // Priority 2: URL parameters (only if not already set by config)
    const urlParams = url_params instanceof URLSearchParams ? url_params : new URLSearchParams(url_params);
    if (!final_iterable_ids.iterableCampaignId) {
        final_iterable_ids.iterableCampaignId = urlParams.get('iterableCampaignId');
    }
    if (!final_iterable_ids.iterableTemplateId) {
        final_iterable_ids.iterableTemplateId = urlParams.get('iterableTemplateId');
    }

    // Priority 3: Cookies (only if not set by higher priorities)
    const cookies = get_iterable_cookie_values();
    if (!final_iterable_ids.iterableCampaignId) {
        // 250130 maybe cookie is a string value? set back to int?
        final_iterable_ids.iterableCampaignId = cookieStringToInt(cookies.iterableCampaignId);
    }
    if (!final_iterable_ids.iterableTemplateId) {
        final_iterable_ids.iterableTemplateId = cookieStringToInt(cookies.iterableTemplateId);
    }

    // Cleanup: Ensure values are properly parsed
    final_iterable_ids.iterableCampaignId = is_valid_iterable_refcode_parse_to_int(final_iterable_ids.iterableCampaignId);
    final_iterable_ids.iterableTemplateId = is_valid_iterable_refcode_parse_to_int(final_iterable_ids.iterableTemplateId);

    return final_iterable_ids;
};

// Function to set Iterable IDs in temporary cookies
export const set_iterable_campaign_template_temporary_cookies = (iterableCampaignId, iterableTemplateId) => {
    if (iterableCampaignId) {
        cookie_utils.set_cookie('iterableCampaignId', iterableCampaignId, 24 * 60 * 60); // 24 hours
    }
    if (iterableTemplateId) {
        cookie_utils.set_cookie('iterableTemplateId', iterableTemplateId, 24 * 60 * 60); // 24 hours
    }
};


export const validate_iterable_action_object = (actionObject) => {
    try {
        if (!actionObject || typeof actionObject !== 'object') {
            return false;
        }

        const { verb, data } = actionObject;

        if (!verb || !data || typeof data !== 'object') {
            return false;
        }

        const { api_method, api_endpoint, i_api_json } = data;

        if (!api_method || !api_endpoint || !i_api_json || typeof i_api_json !== 'object') {
            return false;
        }

        // i_api_json must have at least one key:value
        if (Object.keys(i_api_json).length === 0) {
            return false;
        }

        // Check if api_endpoint is in endpoints_narrow_allow_list
        if (!endpoints_narrow_allow_list.includes(api_endpoint)) {
            console.error(`api_endpoint ${api_endpoint} is not allowed`);
            return false;
        }

        // If api_endpoint is in endpoints_that_require_user_or_email, i_api_json must have "email"
        if (endpoints_that_require_user_or_email.includes(api_endpoint)) {
            if (!i_api_json.email) {
                console.error(`api_endpoint ${api_endpoint} requires "email" in i_api_json`);
                return false;
            }
        }

        return true;
    } catch (error) {
        console.error('Error validating action object:', error);
        return false;
    }
}
