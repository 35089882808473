import { 
    string_array_of_ints_to_array,
    is_likely_matching_boolean_falsy_string,
    parse_boolean_falsy_types
} from '../helpers/index';

import { 
    white_list_incoming_person_fields,
    allowed_iterable_h_hash_key_names,
    array_like_url_params 
} from './static_vars';

/**
 * Parses url parameter into common types we use
 * @param {} the_value - The value to parse.
 * @returns {string|int|float|boolean|null} - The parsed value.
*/
export function detect_parse_url_param_types(the_value) {
    if (is_likely_matching_boolean_falsy_string(the_value)) {
        return parse_boolean_falsy_types(the_value);
    } else if (!isNaN(the_value) && the_value.trim() !== '') {
        const numValue = the_value.includes('.') ? parseFloat(the_value) : parseInt(the_value, 10);
        return isNaN(numValue) ? the_value : numValue;
    } else if (typeof the_value === 'string') {
        return the_value.trim();
    }
    return null;
}

/**
 * Extracts and converts URL parameters into a data_hub_instance object.
    * @param {error_function} error_function - The error function to call if an error occurs.
    * @returns {URLSearchParams} - The URLSearchParams object.
 */
export const get_most_parent_url_params = (error_function = window?.data_hub?.error_function) => {
    let currentWindow = window;
    const urlParams = new URLSearchParams(currentWindow.location.search);

    try {
        // Keeps looping up the windows, overwriting existing URL parameters with parent ones.
        while (currentWindow !== currentWindow.parent) {
            currentWindow = currentWindow.parent;
            const parentUrlParams = new URLSearchParams(currentWindow.location.search);
            parentUrlParams.forEach((value, key) => {
                if (!urlParams.has(key)) {
                    urlParams.set(key, value);
                }
            });
        }
    } catch (error) {
        if (error instanceof DOMException && error.name === 'SecurityError') {
            console.warn('Cross-origin access blocked. Cannot read URL parameters from the parent frame.');
        } else if (error_function) {
            error_function("Error getting parent URL params: " + error);
        }
    }
    return urlParams;
};

/**
 * Extracts array values from a URL parameter which can be in various formats.
 * @param {string|Array|null} paramValue - The parameter value to extract.
 * @param {boolean} [single_values_to_array=true] - If true, single values are wrapped in an array.
 * @returns {Array<string>} - An array of trimmed string values.
 */
export const extract_array_values_from_url_parameter = (paramValue = null, single_values_to_array = true) => {
    if (!paramValue) {
        console.warn('extract_array_values_from_url_parameter: No parameter value provided.');
        return [];
    }

    if (Array.isArray(paramValue)) {
        // allow any types simply return 
        return paramValue;
        //return paramValue.map(val => String(val).trim());
    }

    if (typeof paramValue === 'string') {
        let processedString = paramValue.trim();

        // Remove surrounding brackets if present
        if (processedString.startsWith('[') && processedString.endsWith(']')) {
            processedString = processedString.slice(1, -1).trim();
        }

        // Split by commas if present
        if (processedString.includes(',')) {
            return processedString.split(',').map(val => val.trim()).filter(val => val !== '');
        } else if (processedString !== '') {
            if (single_values_to_array === true) {
                return [processedString];
            }
        }

    }

    console.error('extract_array_values_from_url_parameter: Unsupported parameter type:', paramValue, typeof paramValue);
    return [];
};

/**
 * Cleans and formats ActBlue refcodes from URL parameters.
 * Ensures camelCase format and removes unwanted characters.
 *
 * @param {Object} url_params - URLSearchParams object
 * @returns {Object} - Cleaned refcodes as an object
 */
export const extract_actblue_refcodes = (url_params) => {
    let refcodes = {};

    for (const [keyRaw, valueRaw] of url_params) {
        if (!keyRaw || !valueRaw) continue;

        try {
            let key = decodeURIComponent(keyRaw).trim();
            let value = decodeURIComponent(valueRaw).trim();

            if (key.startsWith('refcode')) {
                let formattedKey = key.replace(/^refcode[_-]?/i, '');
                formattedKey = formattedKey.replace(/[^a-zA-Z0-9_-]/g, '');
                formattedKey = formattedKey.replace(/[-_]([a-z0-9])/gi, (_, p1) => p1.toUpperCase());

                if (formattedKey.length === 0) continue;

                formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
                refcodes[`refcode${formattedKey}`] = value;
            }
        } catch (e) {
            console.warn(`Error processing refcode: ${keyRaw}`, e);
        }
    }

    return refcodes;
};

/**
 * Converts refcodes object into URL query parameters.
 *
 * @param {Object} refcodes - Refcodes object
 * @returns {String} - Query string for ActBlue URLs
 */
export const generate_refcode_url_params = (refcodes = {}) => {
    if (!refcodes || typeof refcodes !== 'object') return '';

    const queryString = Object.entries(refcodes)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    // Remove any trailing '&' if present
    return queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;
};

/**
 * Extracts and converts metadata url parameters. adds to data_hub_instance.page_metadata
 * @param {URLSearchParams} url_params 
 * @param {Object} data_hub_instance 
 * @returns {void}
 */
export const get_metadata_url_params = (url_params = get_most_parent_url_params(), data_hub_instance = window?.data_hub) => {
    if (!data_hub_instance) return;

    if (typeof data_hub_instance.page_metadata !== 'object') {
        data_hub_instance.page_metadata = {};
    }

    for (const [keyRaw, valueRaw] of url_params) {
        try {
            if (!keyRaw || !valueRaw) continue;
            const key = decodeURIComponent(keyRaw).trim();
            const value = decodeURIComponent(valueRaw).trim();

            if (typeof key !== 'string') continue;

            if (key.toLowerCase().startsWith('metadata_b64')) {
                try {
                    const parsed = JSON.parse(atob(value));
                    Object.entries(parsed).forEach(([innerKey, innerValue]) => {
                        const cleanKey = innerKey.replace(/[^a-zA-Z0-9_-]/g, '')
                            .replace(/[-_]([a-z0-9])/gi, (_, p1) => p1.toUpperCase())
                            .replace(/^./, c => c.toLowerCase());
                        data_hub_instance.page_metadata[cleanKey] = innerValue;
                    });
                } catch (e) {
                    data_hub_instance.error_function('Metadata_b64 parse error', e);
                }
            } else if (/^metadata[_\-]?/i.test(key)) {
                let cleanKey = key.replace(/^metadata[_\-]?/i, '')
                    .replace(/[^a-zA-Z0-9_-]/g, '')
                    .replace(/[-_]([a-z0-9])/gi, (_, p1) => p1.toUpperCase())
                    .replace(/^./, c => c.toLowerCase());

                    if (!data_hub_instance.page_metadata[cleanKey]) {
                        data_hub_instance.page_metadata[cleanKey] = detect_parse_url_param_types(value);
                    } else {
                        console.warn('Duplicate metadata key already assigned:', cleanKey, value);
                    }
            }
        } catch (error) {
            console.warn('Error parsing metadata param:', keyRaw, error);
        }
    }
};

/**
 * Extracts and converts URL parameters into a data_hub_instance object.
 * @param {URLSearchParams} url_params - URLSearchParams object
 * @param {Object} data_hub_instance - The instance of the data hub.
 */
export const get_user_url_params = async (url_params = get_most_parent_url_params(), data_hub_instance = window?.data_hub) => {
    if (!data_hub_instance?.user_data || !url_params || !(url_params instanceof URLSearchParams)) return;

    url_params.forEach((value, key) => {
        if (white_list_incoming_person_fields.includes(key)) {
            try {
                // Check if the key is in array_like_url_params
                if (array_like_url_params.includes(key)) {
                    // Process the value using extract_array_values_from_url_parameter and string_array_of_ints_to_array
                    const arrayValues = extract_array_values_from_url_parameter(value);
                    const parsed = string_array_of_ints_to_array(arrayValues);
                    data_hub_instance.user_data[key] = parsed;
                } else {
                    // Process the value using detect_parse_url_param_types
                    const parsed = detect_parse_url_param_types(value);
                    data_hub_instance.user_data[key] = parsed;
                }
            } catch (error) {
                console.warn('Error parsing URL param:', key, value, error);            
            }
        } else if (allowed_iterable_h_hash_key_names.includes(key) && typeof value === 'string' && value.length > 10) {
            // Directly assign the value for allowed iterable keys
            data_hub_instance.user_data[key] = value;
        }
    });
};